const terms = {
  homepage: {
    latest: 'Derniers articles',
    trending: 'Tendances',
    favorites: 'Favoris',
    all: 'Tout les articles',
    last_updated: 'Dernière modification par ',
  },

  login: {
    auto_login: 'Tentative de connexion automatique...',
    name_error: 'Le nom ne doit contenir que des alphabets',
    username_error:
      "Le nom d'utilisateur ne doit pas contenir des symboles particuliers et des espaces",
    email_error: 'Adresse e-mail non valide',
    pass_lowercase:
      'Le mot de passe doit contenir au moins 1 caractère alphabétique minuscule',
    pass_uppercase:
      'Le mot de passe doit contenir au moins 1 caractère alphabétique majuscule',
    pass_number: 'Le mot de passe doit contenir au moins 1  numéro',
    pass_symbol: 'Le mot de passe doit contenir au moins 1 symbole',
    pass_length: 'Le mot de passe doit contenir au moins 8 caractères',
    pass_check_error: 'Les mots de passe ne correspondent pas',
    pass_check_error_two: 'Les mots de passe ne correspondent pas',
    privacy_accept: 'Acceptez la politique de confidentialité pour continuer',
    register_success: 'Inscrit! Vérifiez vos mails pour activer votre compte.',
    submitting: 'Soumission...',
    logging: 'Connexion...',
    forgot_success:
      'Succès! Vérifiez vos e-mails pour trouver le lien de réinitialisation.',
    register_h: "S'inscrire",
    name_input: 'Entrez votre nom...',
    username_input: "Entrez un nom d'utilisateur...",
    email_input: 'Entrez votre e-mail...',
    password_input: 'Entrez un mot de passe...',
    password_check_input: 'Entrez à nouveau le mot de passe...',
    policy_read: "J'ai lu et accepté la ",
    privacy: 'politique de confidentialité',
    register_button: 'Créer',
    account_already: 'Déjà un compte? ',
    login_now: 'Connectez-vous maintenant!',
    login_h: 'Connexion',
    multi_auth: "Entrez votre nom d'utilisateur ou votre e-mail...",
    login_pass: 'Entrez votre mot de passe...',
    password_forget: 'Mot de passe oublié?',
    login_button: 'Allons-y!',
    new_konnect: 'Nouveau sur Konnect Blog? ',
    create_account: 'Créez un compte sur Konnect maintenant!',
    forgot_h: 'Mot de passe oublié',
    login_back: 'Retourner à Connexion',
    forgot_button: 'Envoyer le lien de réinitialisation',
    login_demo: "Essayer Konnect en tant qu'un utilisateur demo!",
    login_sso: 'Se Connecter via le SSO',
  },

  auto_login: {
    login_success: 'Connexion automatique réussie',
    login_failed: 'Échec de la connexion automatique',
    login_message: 'Essayer de vous connecter automatiquement...',
  },

  navbar: {
    search: "Chercher quelque chose ou quelqu'un...",
    profile_go: 'Aller sur votre Profil',
    create: 'Créer',
    cancel: 'Annuler',
    new_post: 'Créer un Nouveau Poste',
    new_group: 'Créer un Nouveau Groupe',
    notifications: 'Notifications',
    settings_go: 'Aller aux paramètres',
    admin_go: "Aller sur l'interface Admin",
    dark_mode: 'Passer en mode sombre',
    light_mode: 'Passer en mode lumière',
    menu: 'Menu',
    navigation: 'Navigation',
    profile: 'Profil',
    chats: 'Conversations',
    groups: 'Groupes',
    people: 'Personnes',
    games: 'Jeux',
    settings: 'Paramètres',
    post_new: 'Nouveau Poste',
    group_new: 'Nouveau Groupe',
    misc: 'Divers',
    theme_success: 'Le thème a été mis à jour avec succès!',
    new_status: 'Créer un Nouveau Statût',
    status_new: 'Nouveau Statût',
    saved_posts: 'Postes Sauvegardés',
  },

  feed: {
    stories: 'Statûts',
    story: 'Afficher le Statût',
    online: 'En ligne',
    content: 'Contenu',
    chats: 'Conversations',
    groups: 'Groupes',
    people: 'Personnes',
    games: 'Jeux',
    pymk: 'Personnes que vous connaissez peut-être',
    people_add: 'Envoyer une reqûete de connexion',
    remove_list: 'Retirer depuis cette liste',
    react: 'Réagir',
    comment: 'Commenter',
    save: 'Sauvegarder',
    share: 'Partager',
    enter_comment: 'Écrire un commentaire...',
    reply: 'Répondre',
    show_more: 'Afficher plus...',
    no_comments: 'Pas de commentaires sur ce poste encore...',
    comments_not_allowed: 'Les commentaires sont désactivés.',
    view_post: 'Afficher le Poste',
    edit_post: 'Modifier le Poste',
    delete_post: 'Supprimer le Poste',
    save_post: 'Sauvegarder le Poste',
    unsave_post: 'Retirer le Poste des sauvegardes',
    save_success: 'Poste sauvegardé avec succès',
    unsave_success: 'Poste retiré depuis la sauvegarde avec succès',
    username: "Nom d'utilisateur",
    name: 'Nom',
    no_posts:
      "Pas de postes dans votre fil... Essayez de suivre plus d'utilisateurs",
    sure_delete: 'Êtes vous sûr de vouloir supprimer ce poste?',
    yes: 'Oui',
    no: 'Non',
    deleting: 'En train de supprimer...',
    deleted_success: 'Le poste a été supprimé avec succès!',
    reacts: 'Réactions',
    replying: 'En train de répondre à',
    edit_comment: 'Modifier le commentaire',
    update_comment: 'Écriver un nouveau commentaire...',
    delete_comment: 'Supprimer le commentaire',
    delete_comment_sure: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
    back_original: 'Afficher le poste original',
    post_share_success: 'Le poste a été partagé avec succès',
    share_post_sure: 'Êtes-vous sûr de vouloir partager ce poste?',
    post_not_found: "Ce poste n'a pas été trouvé.",
    return_feed: 'Retourner sur votre Fil',
    loading: 'En cours de chargement...',
    no_stories: 'Pas de statûts postés récemment...',
    success: 'Opération terminée avec succès!',
    create_story: 'Créer un Statût',
    saved_posts: 'Postes Sauvegardés',
    collections: 'Collections',
    story_new: 'Nouveau Statût',
    you: 'Vous',
    and: 'et',
    others: 'autres',
    reacted_to: 'avez réagi à ce',
    this_post: 'poste',
    this_comment: 'commentaire',
    next: 'Prochaine Page',
    previous: 'Page Précédente',
    seen_all: 'Vous avez vu tout les posts dans votre fil!',
    choose_collection: 'Choisissez une collection',
    no_collections: "Aucune collection n'a encore été créée...",
    no_collections_saved: 'Pas encore sauvegardé dans aucune collection...',
    edited: 'Modifié',
    uploading: 'En cours de téléchargement...',
    image_success: 'Image téléchargé avec succès',
  },

  create_post: {
    uploading: 'En cours de téléchargement...',
    image_success: 'Image téléchargé avec succès',
    content_short: 'Pas assez de contenu',
    post_create_success: 'Poste crée avec succès',
    post_edit_success: 'Poste édité avec succès',
    create_post: 'Créer un Nouveau Poste',
    edit_post: 'Éditer un Poste',
    posting_as: 'En train de poster en tant que',
    submitting: 'Soumission...',
    editor: 'Éditeur',
    preview: 'Aperçu',
    edit: 'Éditer',
    create: 'Créer',
    enable_comments: 'Activer les Commentaires',
    visibility: 'Visibilité',
    choose_visibility: 'Définissez une Visibilité',
    in: 'dans',
    title_placeholder: 'Entrez un Titre...',
    subtitle_placeholder: 'Entrez un Sous-titre...',
    slug_placeholder: "Enter une 'Slug'...",
    preview_img: "Choisissez une image d'aperçu",
    optional: 'optionnel',
    carousel: 'Sélectionnez des images pour le Carrousel',
    upload: 'Télécharger',
    chosen: 'choisis',
    tags: 'Entrez des balises (séparées par des virgules)',
    remove: 'Retirer',
    content: 'Contenu',
    submit: 'Soumettre',
    cancel: 'Annuler',
    loading: 'En cours de chargement...',
    post_not_found: 'Poste non trouvé :(',
    return_feed: 'Retourner sur votre Fil',
  },

  settings: {
    settings: 'Paramètres',
    preferences: 'Préférences',
    language: 'Langage',
    english: 'Anglais',
    french: 'Français',
    creole: 'Créole',
    theme: 'Thème',
    dark: 'Sombre',
    light: 'Clair',
    account: 'Compte',
    name: 'Nom',
    username: "Nom d'utilisateur",
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    verified: 'Vérifié',
    unverified: 'Non-vérifié',
    submit: 'Soumettre',
    cancel: 'Annuler',
    change: 'Changer',
    account_type: 'Type de Compte',
    public: 'Publique',
    restricted: 'Restraint',
    private: 'Privée',
    ghost: 'Fantôme',
    name_error: 'Le nom ne doit contenir que des alphabets',
    username_error:
      "Le nom d'utilisateur ne doit pas contenir des symboles particuliers et des espaces",
    email_error: 'Adresse e-mail non valide',
    pass_lowercase:
      'Le mot de passe doit contenir au moins 1 caractère alphabétique minuscule',
    pass_uppercase:
      'Le mot de passe doit contenir au moins 1 caractère alphabétique majuscule',
    pass_number: 'Le mot de passe doit contenir au moins 1  numéro',
    pass_symbol: 'Le mot de passe doit contenir au moins 1 symbole',
    pass_length: 'Le mot de passe doit contenir au moins 8 caractères',
    pass_check_error: 'Les mots de passe ne correspondent pas',
    pass_check_error_two: 'Les mots de passe ne correspondent pas',
    name_input: 'Entrez votre nom...',
    username_input: "Entrez un nom d'utilisateur...",
    email_input: 'Entrez votre e-mail...',
    password_input: 'Entrez un mot de passe...',
    password_check_input: 'Entrez à nouveau le mot de passe...',
    current_password_input: 'Entrez votre mot de passe actuel',
    name_success: 'Votre nom a été mis à jour avec succès!',
    username_success: "Votre nom d'utilisateur a été mis à jour avec succès!",
    email_success: 'Votre addresse mail a été mis à jour avec succès!',
    password_success: 'Votre mot de passe a été mis à jour avec succès!',
    language_success: 'La langue a été mise à jour avec succès!',
    theme_success: 'Le thème a été mise à jour avec succès!',
    account_type_success: 'Votre type de compte a été mis à jour avec succès!',
    submitting: 'Soumission...',
    reset_password: 'Réinitialiser votre mot de passe',
    password_reset_success:
      'Le lien pour réinitialiser votre mot de passe vous a été envoyé!',
    sure_reset: 'Êtes-vous sûr de vouloir réinitialiser votre mot de passe?',
    yes: 'Oui',
    no: 'Non',
    deactivate_success: 'Votre compte a été désactivé avec succès!',
    logout_success: 'Vous vous êtes déconnecté avec succès!',
    deactivate: 'Désactiver votre compte',
    logout: 'Se déconnecter',
    contributors: 'Contributeurs',
    access_groups: "Groupes d'Accès",
    access_group_name: 'Entrez un nom...',
    access_group_members: 'Choisissez des membres',
    create: 'Créer',
    update: 'Mettre à jour',
    create_access_success: "Groupe d'Accès crée avec succès",
    update_access_success: "Groupe d'Accès mis à jour avec succès",
    create_access: "Créer un Groupe d'Accès",
    edit_access: "Mettre à jour le Groupe d'Accès",
    sure_logout: 'Êtes-vous sûr de vouloir vous déconnecter?',
    unauthorized_action: 'Erreur: Action non autorisée',
  },

  stories: {
    viewers: 'Visiteurs',
    delete_status_sure: 'Êtes vous sûr de vouloir supprimer ce statût?',
    delete_status_success: 'Le statût a été supprimé avec succès!',
    yes: 'Oui',
    no: 'Non',
  },

  profile: {
    story: 'Afficher le Statut',
    no_posts: "Cet utilisateur n'a pas encore poster...",
    posts: 'Postes',
    loading: 'En cours de chargement...',
    user_not_found: 'Utilisateur non trouvé...',
    return_feed: 'Retourner sur votre Fil',
    edit_bio: 'Mettre à jour votre bio',
    enter_bio: 'Entrez une bio',
    submit: 'Soumettre',
    cancel: 'Annuler',
    bio_update_success: 'Bio mis à jour avec succès!',
    change_profile_pic: 'Mettre à jour votre photo de profil',
    uploading: 'En cours de téléchargement...',
    image_success: 'Image téléchargé avec succès',
    ppic_success: 'Photo de profil mis à jour avec succès!',
    cover_image_success: 'Couverture mis à jour avec succès!',
    file_too_big: 'Fichier trop gros (> 10 MB)',
    update_ppic: 'Mettre à jour votre photo de profil',
    update_avatar: 'Mettre à jour votre Avatar',
    update_cover_image: 'Mettre à jour votre couverture',
    submitting: 'Soumission...',
    create_story: 'Créer un Statût',
    story_create_success: 'Le statût a été posté avec succès!',
    editor: 'Editeur',
    create: 'Créer',
    preview: 'Aperçu',
    posting_as: 'En train de poster en tant que',
    no_followers: "Cette personne n'a pas encore d'abonnés",
    no_following: "Cette personne n'a pas encore d'abonnements",
    cannot_view_followers: 'Peut pas afficher les abonnés de cet utilisateur',
    cannot_view_following: 'Peut pas afficher abonements de cet utilisateur',
    follows_you: 'Cet utilisateur vous suit',
    follow: 'Suivre',
    unfollow: 'Arrêter de Suivre',
    send_follow_request: 'Envoyer une Reqûete de Connexion',
    unsend_follow_request: 'Supprimer la Reqûete de Connexion',
    block: 'Bloquer',
    unblock: 'Débloquer',
    success: 'Opération terminée avec succès!',
    followers: 'Abonnés',
    following: 'Abonnements',
    blocked: 'Bloqués',
    verified: 'Ce compte a été vérifié.',
    search_history: 'Historique des Recherches',
    no_search: "Rien n'a encore été recherché.",
    story_archive: 'Archive de Statûts',
    manage_highlights: 'Gerer vos Highlights',
    no_highlights: 'Pas de highlights encore.',
    create_highlight: 'Créer un Highlight',
    edit_highlight: 'Modifier le Highlight',
    create_highlight_success: 'Highlight crée avec succès',
    edit_highlight_success: 'Highlight mis à jour avec succès',
    delete_highlight_success: 'Highlight supprimé avec succès',
    enter_highlight_name: 'Entrez un nom...',
    highlight_icon: 'Choisissez un icon (optionel)',
    choose_stories: 'Choisissez des statûts à ajouter',
    visibility: 'Visibilité',
    choose_visibility: 'Définissez une Visibilité',
    avatar_update_success: 'Votre Avatar a été mis à jour avec succès!',
    last_updated: 'Dernière modification par ',
  },

  badge: {
    contributor: 'Contributeur',
    contributor_desc:
      'Donné à tout le monde qui contribue au dévéloppement de Konnect.',
    early: 'Adopteur Précoce',
    early_desc: 'Donné aux premiers membres de Konnect.',
  },

  notifications: {
    notifications: 'Notifications',
    follow_requests: "Demande d'ajout",
    invites: 'Invites',
    received: 'Reçus',
    sent: 'Envoyés',
    accept: 'Accepter',
    decline: 'Décliner',
    cancel: 'Annuler',
    request_accept: "Demande d'Ajout Acceptée",
    request_decline: "Demande d'Ajout Declinée",
    request_cancel: "Demande d'Ajout Annulée",
    mark_read: 'Marquer comme lu',
    mark_unread: 'Marquer comme non lu',
    chat: 'Conversations',
    group: 'Groupes',
    invite_accept: 'Invitation du Groupe Acceptée',
    invite_reject: 'Invitation du Groupe Rejetée',
  },

  search: {
    people: 'Personnes',
    groups: 'Groupes',
    rooms: 'Conversations',
    posts: 'Postes',
    placeholder: "Chercher quelque chose ou quelqu'un...",
    searching: 'Recherche en cours...',
    last_updated: 'Dernière modification par ',
  },

  people: {
    people: 'Personnes',
    follow: 'Suivre',
    unfollow: 'Arrêter de Suivre',
    send_follow_request: 'Envoyer une Reqûete de Connexion',
    unsend_follow_request: 'Supprimer la Reqûete de Connexion',
    success: 'Opération terminée avec succès!',
    followers: 'Abonnés',
    following: 'Abonnements',
  },

  groups: {
    groups: 'Groupes',
    create_group: 'Créer un Groupe',
    name: 'Nom du Group',
    name_placeholder: 'Entrez un nom...',
    description: 'Description du Groupe',
    description_placeholder: 'Entrez une description...',
    privacy: 'Choisissez un Paramètre de Confidentialité',
    public: 'Publique',
    private: 'Privée',
    select_members: 'Choisissez des Membres',
    create: 'Créer',
    update: 'Mettre à jour',
    create_group_success: 'Groupe crée avec succès',
    update_group_success: 'Groupe mis à jour avec succès',
    submit: 'Soumettre',
    submitting: 'Soumission...',
    file_too_big: 'Fichier trop gros (> 10 MB)',
    uploading: 'En cours de téléchargement...',
    image_success: 'Image téléchargé avec succès',
    group_icon: 'Choisissez un icône de groupe (optionnel)',
    group_cover: 'Choisissez une couverture de groupe (optionnel)',
    loading: 'Chargement en cours...',
    group_not_found: 'Groupe Introuvable...',
    no_groups_found: 'Aucun Groupe Trouvé...',
    return_feed: 'Retournez à votre Fil',
    follow_this_group: 'Vous êtes un membre de ce groupe.',
    members: 'Membres',
    edit_name: 'Modifier le nom',
    edit_description: 'Modifier la description',
    change_logo: "Changer l'icône du groupe",
    change_cover: 'Changer la couverture du groupe',
    change_privacy: 'Mettre à jour la Confidentialité du Groupe',
    invite_people: 'Inviter des gens',
    accept_request: 'Accepter la Requête',
    decline_request: 'Décliner la Requête',
    leave: 'Quitter',
    join: 'Rejoindre',
    no_posts: 'Pas encore de postes dans ce groupe...',
    posts: 'Postes',
    no_members: 'Pas encore de membres dans ce Groupe.',
    remove: 'Retirer depuis ce Groupe',
    ban: 'Banir depuis ce Groupe',
    promote: "Promouvoir en tant qu'Administrateur",
    invite: 'Inviter',
    name_update_success: 'Le Nom du Groupe a été mis à jour avec succès!',
    description_update_success:
      'La Description du Groupe a été mis à jour avec succès!',
    icon_update_success: "L'icône du Groupe a été mis à jour avec succès!",
    cover_update_success:
      'La couverture du Groupe a été mis à jour avec succès!',
    privacy_update_success:
      'La Confidentialité du Groupe a été mis à jour avec succès!',
    invite_accept: 'Invitation du Groupe Acceptée',
    invite_reject: 'Invitation du Groupe Rejetée',
    joined_group: 'Le Groupe a été rejoint avec succès!',
    left_group: 'Le Group a été quitté',
    invite_update_success: 'Individus Invités avec Succès!',
    member_promote:
      "Le Membre a été avec succès promu au poste d'Administrateur!",
    member_ban: 'Le Membre a été Banni avec succès!',
    member_remove: 'Le Membre a été expulsé avec succès!',
    mute: 'Ignorer les notifications de Groupe',
    unmute: 'Rétablir les Notifications de Groupe',
    muted_success: 'Notifications de groupe désactivées avec succès!',
    unmuted_success: 'Notifications de groupe rétablies avec succès!',
    create_post: 'Créer un Nouveau Poste',
    sure_leave: 'Êtes-vous sûr de vouloir quitter ce Groupe?',
    yes: 'Oui',
    no: 'Non',
    group_name_placeholder: 'Entrez un nom...',
    group_description_placeholder: 'Entrez une description...',
  },

  saved_posts: {
    saved_posts: 'Collections',
    no_saved_posts: 'Pas de Postes encore sauvegardé',
    create_collection: 'Créer une collection',
    edit_collection: 'Mettre à jour la collection',
    delete_collection: 'Êtes-vous sûr de vouloir supprimer cette collection?',
    collection_name: 'Nom',
    collection_name_placeholder: 'Entrez un nom...',
    submit: 'Soumettre',
    default_collection: 'Défaut',
    submitting: 'Soumission...',
    create_collection_success: 'Collection créée avec succès!',
    update_collection_success: 'Collection mise à jour avec succès !!',
    delete_collection_success: 'Collection supprimée avec succès !!',
    no_collections: "Aucune collection n'a encore été créée...",
    posts: 'postes',
    yes: 'Oui',
    no: 'Non',
    back_collections: 'Retour aux Collections',
  },

  games: {
    games: 'Jeux',
    ttt_next: 'Prochain Jouer',
    ttt_winner: 'Gagnant',
    restart: 'Recommencer',
  },

  rooms: {
    rooms: 'Conversations',
    create_room: 'Créer une Conversation',
    name: 'Nom de la conversation',
    name_placeholder: 'Entrez un nom...',
    description: 'Description de la Conversation',
    description_placeholder: 'Entrez une description...',
    privacy: 'Choisissez un Paramètre de Confidentialité',
    public: 'Publique',
    private: 'Privée',
    select_members: 'Choisissez des Membres',
    create: 'Créer',
    update: 'Mettre à jour',
    create_room_success: 'Conversation crée avec succès',
    update_room_success: 'Conversation mise à jour avec succès',
    submit: 'Soumettre',
    submitting: 'Soumission...',
    file_too_big: 'Fichier trop gros (> 10 MB)',
    uploading: 'En cours de téléchargement...',
    image_success: 'Image téléchargé avec succès',
    room_icon: 'Choisissez un icône (optionnel)',
    loading: 'Chargement en cours...',
    room_not_found: 'Conversation Introuvable...',
    no_rooms_found: 'Aucune Conversation Trouvée...',
    return_feed: 'Retournez à votre Fil',
    member_room: 'Vous êtes un membre de cette conversation.',
    members: 'Membres',
    edit_name: 'Modifier le nom',
    edit_description: 'Modifier la description',
    change_logo: "Changer l'icône",
    change_privacy: 'Mettre à jour la Confidentialité du Groupe',
    invite_people: 'Inviter des gens',
    accept_request: 'Accepter la Requête',
    decline_request: 'Décliner la Requête',
    leave: 'Quitter',
    join: 'Rejoindre',
    no_messages: 'Pas encore de messages dans cette conversation...',
    no_messages_thread: 'Pas encore de messages dans ce fil...',
    messages: 'Messages',
    no_members: 'Pas encore de memmbres dans cette Conversation.',
    remove: 'Retirer depuis cette Conversation',
    ban: 'Banir depuis cette Conversation',
    promote: "Promouvoir en tant qu'Administrateur",
    invite: 'Inviter',
    name_update_success:
      'Le Nom de la Conversation a été mis à jour avec succès!',
    description_update_success:
      'La Description de la Conversation a été mis à jour avec succès!',
    icon_update_success:
      "L'icône de la Conversation a été mis à jour avec succès!",
    privacy_update_success:
      'La Confidentialité de la Conversation a été mis à jour avec succès!',
    invite_accept: 'Invitation de la Conversation Acceptée',
    invite_reject: 'Invitation de la Conversation Rejetée',
    joined_group: 'La Conversation a été rejoint avec succès!',
    left_group: 'La Conversation a été quitté',
    invite_update_success: 'Individus Invités avec Succès!',
    member_promote:
      "Le Membre a été avec succès promu au poste d'Administrateur!",
    member_ban: 'Le Membre a été Banni avec succès!',
    member_remove: 'Le Membre a été expulsé avec succès!',
    mute: 'Ignorer les notifications de la Conversation',
    unmute: 'Rétablir les Notifications de la Conversation',
    muted_success: 'Notifications de la conversation désactivées avec succès!',
    unmuted_success: 'Notifications de la conversation rétablies avec succès!',
    enter_message: 'Écrivez un Message...',
    messages_loading: 'En train de charger les Messages...',
    edit_message: 'Modifier le Message',
    delete_message: 'Supprimer le Message',
    reply_message: 'Répondre au Message',
    views: 'Vues',
    edit_placeholder: 'Écrivez un Message mis à jour...',
    edited: 'modifié',
    sure_delete: 'Êtes vous sûr de vouloir supprimer ce message?',
    yes: 'Oui',
    no: 'Non',
    deleted: 'Supprimé',
    replying_to: 'En train de répondre à',
    thread: 'Fil',
    open_thread: 'Ouvrir le Fil',
    sure_leave: 'Êtes-vous sûr de vouloir quitter cette Conversation?',
    room_name_placeholder: 'Entrez un nom...',
    room_description_placeholder: 'Entrez une description...',
    load_more: 'Charger Plus de Messages...',
    is_typing: "est en train d'écrire...",
    has_new_messages: 'Contient de nouveaux messages',
  },

  avatar: {
    maker_title: "Créateur d'avatar",
    generate: 'Générer',
    top: 'Haut',
    hat_color: 'Couleur du chapeau',
    hair_color: 'Couleur de cheveux',
    accessory: 'Accessoire',
    accessory_color: "Couleur de l'Accessoire",
    facial_hair: 'Poils',
    facial_hair_color: 'Couleur des Poils',
    clothes: 'Vêtements',
    clothes_color: 'Couleur des Vêtements',
    eyes: 'Yeux',
    eyebrow: 'Sourcil',
    mouth: 'Bouche',
    skin: 'Peau',
    none: 'Rien',
    random: 'Aléatoire',
    reset: 'Réinitialiser',
    save: 'Sauvegarder',
  },

  admin: {
    settings: 'Paramètres',
    preferences: 'Préférences',
    choose_language: 'Choissisez une Langue',
    language: 'Langage',
    english: 'Anglais',
    french: 'Français',
    creole: 'Créole',
    language_success: 'La langue a été mise à jour avec succès!',
    last_updated: 'Dernière modification, ',
    posts: 'Vos postes',
    create_post: 'Créer un Nouveau Poste',
    edit_post: 'Modifier Poste',
    delete_post: 'Supprimer Poste',
    sure_delete: 'Êtes vous sûr de vouloir supprimer ce poste?',
    yes: 'Oui',
    no: 'Non',
    deleting: 'En train de supprimer...',
    deleted_success: 'Le poste a été supprimé avec succès!',
    publish: 'Publier Poste',
    unpublish: 'Dépublier Poste',
    publish_success: 'Poste Publié avec Succès!',
    unpublish_success: 'Poste Dépublié avec Succès!',
  },

  view: {
    enter_comment: 'Écrire un commentaire...',
    edit_comment: 'Modifier le commentaire',
    reply_comment: 'Répondre au commentaire',
    update_comment: 'Écriver un nouveau commentaire...',
    delete_comment: 'Supprimer le commentaire',
    sure_delete: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
    submit: 'Soumettre',
    posted_on: 'Posté à',
    last_modified: 'Dernière Modification à',
    edited: '(modifié)',
    yes: 'Oui',
    no: 'Non',
    submitting: 'Soumission...',
    deleting: 'En train de supprimer...',
    comment_add_success: 'Commentaire Posté avec Succès!',
    comment_update_success: 'Commentaire mis à jour avec Succès!',
    comment_delete_success: 'Commentaire Supprimé avec Succès!',
    loading: 'En cours de chargement...',
    post_not_found: 'Poste non trouvé :(',
    return_feed: 'Retourner sur votre Fil',
    comments: 'Commentaires',
    show_comments: 'Afficher les Commentaires',
    hide_comments: 'Cacher les Commentaires',
    no_comments: 'Pas encore de Commentaires...',
    views: 'Vues',
    likes: 'Likes',
    like: 'Like',
    unlike: 'Deliker',
    remove_from: 'Retirer depuis',
    add_to: 'Ajouter à',
    favorites: 'vos Favoris',
    reply: 'Répondre',
    edit: 'Modifier',
    delete: 'Supprimer',
    share: 'Partager',
    link_copied: 'Le lien a été copié!',
  },
};

export default terms;
