import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useAlert } from 'react-alert';

import { usePostStore } from '../stores/usePostStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useProfileStore } from '../stores/useProfileStore';
import { useLinkStore } from '../stores/useLinkStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';
import { useVerifiedStore } from '../stores/useVerifiedStore';

import useLanguage from '../wrappers/LanguageWrapper';
import { convert } from '../utils/timestamp';

import { LocalContext } from '../wrappers/LocalContext';

import tmpAvatar from '../assets/images/avatar.png';

import upload from '../utils/upload';
import { automaticLogin } from '../utils/fetcher';

import Navbar from '../components/Navbar';
import IncludeEditBio from './includes/IncludeEditBio';
import PaginationList from '../wrappers/PaginationList';

export default function Profile() {
  const { posts } = usePostStore((state) => state);
  const {
    profile,
    setProfilePic,
    setBannerImg,
    setBio,
    addFollowing,
    removeFollowing,
  } = useUserProfileStore((state) => state);
  const { profiles, setProfiles } = useProfileStore((state) => state);
  const { addLink } = useLinkStore((state) => state);
  const { theme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);
  const { verified } = useVerifiedStore((state) => state);

  const [, setIsLoading] = useState(true);
  const [didOnce, setDidOnce] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [bio, setUserBio] = useState('');
  const [editingBio, setEditingBio] = useState('');
  const [profilePic, setUserProfilePic] = useState('');
  const [bannerImg, setUserBannerImg] = useState('');

  const limit = 6;
  const terms = useLanguage(language).profile;
  const alert = useAlert();
  const navigate = useNavigate();
  const { API_URL } = useContext(LocalContext);

  const { profileID } = useParams();

  const sortDate = (posts) => {
    let updatedPosts = [...posts];
    return updatedPosts.sort(
      (a, b) => new Date(b.updated_on) - new Date(a.updated_on)
    );
  };

  const filteredPosts =
    posts && profileID
      ? sortDate(posts.filter((p) => p.uid === profileID))
      : [];

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setEditingBio(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => setIsLoading(false), 4000);

    const fetchData = async () => {
      if (!profile || !profile.profileID) {
        await automaticLogin(API_URL, '', '');
      }
    };

    fetchData();

    if (!profileID) {
      if (profile && profile.profileID) {
        navigate(`/u/${profile.profileID}`);
      } else {
        alert.error(terms.user_not_found);
        navigate('/');
      }
    }

    setEditingBio(false);
    setCurrentUser(null);
    setIsLoading(true);
    setDidOnce(false);
    setCurrentPage(0);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, [profileID]);

  if (!didOnce) {
    if (profileID) {
      let foundUser = profiles.find((p) => p.profileID === profileID);

      setDidOnce(true);

      const setUser = () => {
        setCurrentUser(foundUser);

        setUserBio(foundUser.bio);
        setUserProfilePic(foundUser.profile_pic);
        setUserBannerImg(foundUser.banner_img);
      };

      if (!foundUser || !foundUser.profileID) {
        axios
          .post(
            `${API_URL}/blog/profile/fetch/one`,
            { uid: profile.profileID, profileID },
            {
              headers: { Authorization: `Bearer ${profile.jwt}` },
            }
          )
          .then((response) => {
            if (response.data.error === 0) {
              foundUser = response.data.profile;

              let currentProfiles = [...profiles];
              let currentProfileIDs = currentProfiles.map((p) => p.profileID);

              if (!currentProfileIDs.includes(foundUser.profileID)) {
                currentProfiles.push(foundUser);
              }

              setProfiles(currentProfiles);

              setUser();
            } else {
              alert.error(terms.user_not_found);
              navigate('/');
            }
          });
      } else {
        setUser();
      }
    }
  }

  const updateImage = (banner) => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      let file = input.files[0];
      let formData = new FormData();

      formData.append('file', file);

      if (file.size > 10 * 1024 * 1024) {
        alert.error(terms.file_too_big);
        return;
      }

      alert.info(terms.uploading);
      const uploadResult = await upload(
        API_URL,
        formData,
        profile.uid,
        profile.jwt,
        addLink
      );

      if (!uploadResult[0]) {
        if (uploadResult[1]) {
          alert.error(uploadResult[1]);
        }
      } else {
        if (!banner) {
          setProfilePic(uploadResult[1]);
        } else {
          setBannerImg(uploadResult[1]);
        }

        axios
          .post(
            `${API_URL}/blog/profile/update`,
            {
              uid: profile.uid,
              profile_pic: banner ? profilePic : uploadResult[1],
              banner_img: banner ? uploadResult[1] : bannerImg,
            },
            {
              headers: { Authorization: `Bearer ${profile.jwt}` },
            }
          )
          .then((res) => {
            if (res.data.error === 0) {
              if (!banner) {
                alert.success(terms.ppic_success);
              } else {
                alert.success(terms.cover_image_success);
              }
            } else {
              console.log(res.data);
            }
          });

        setCurrentUser((prev) => {
          let updatedUser = { ...prev };

          if (!banner) {
            updatedUser.profile_pic = uploadResult[1];
          } else {
            updatedUser.banner_img = uploadResult[1];
          }

          return updatedUser;
        });

        if (!banner) {
          setUserProfilePic(uploadResult[1]);
        } else {
          setUserBannerImg(uploadResult[1]);
        }
      }
    };
  };

  const submitUpdateBio = () => {
    alert.info(terms.submitting);

    axios
      .post(
        `${API_URL}/blog/profile/update`,
        {
          uid: profile.uid,
          bio: bio,
        },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          alert.success(terms.bio_update_success);
        } else {
          console.log(res.data);
        }
      });

    setEditingBio(false);

    setCurrentUser((prev) => {
      let updatedUser = { ...prev };
      updatedUser.bio = bio;
      return updatedUser;
    });

    setBio(bio);
  };

  const submitFollow = () => {
    if (!profile || !profile.profileID) {
      return;
    }

    let newStatus = !profile.following.includes(profileID);

    alert.info(terms.submitting);

    axios
      .post(
        `${API_URL}/blog/profile/follow`,
        {
          uid: profile.uid,
          profileID: profileID,
          follow: newStatus,
        },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          alert.success(terms.success);
        } else {
          console.log(res.data);
        }
      });

    if (newStatus) {
      addFollowing(profileID);
    } else {
      removeFollowing(profileID);
    }

    setCurrentUser((prev) => {
      let updatedUser = { ...prev };

      if (newStatus) {
        updatedUser.followers_length = updatedUser.followers_length + 1;
      } else {
        updatedUser.followers_length = updatedUser.followers_length - 1;
      }

      return updatedUser;
    });

    setBio(bio);
  };

  return (
    <div
      className={`w-full ${
        theme === 'light' ? 'bg-main-100' : 'bg-main-900'
      } ease-in-out duration-400 lg:pb-0 pb-20`}
    >
      <Navbar />
      <div className="px-4 p-2 pt-20 lg:pt-24">
        <div
          className="w-full flex flex-col items-center rounded-lg mt-2 p-2 border-2 border-main-300 bg-gradient-to-r from-main-300 to-main-200"
          style={{
            background: `url("${bannerImg}") center / cover no-repeat #4a5568`,
          }}
        >
          <div className="w-full h-full flex justify-center items-center sm:mt-0 mt-2">
            <img
              src={profilePic && profilePic.length > 3 ? profilePic : tmpAvatar}
              alt="p.pic"
              className={`h-28 w-28 sm:h-36 sm:w-36 lg:h-40 lg:w-40 border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
              } bg-opacity-50 ease-in-out duration-400`}
            />
          </div>

          <div
            className={`w-full sm:w-2/3 flex justify-center mt-4 font-bold text-lg sm:text-xl lg:text-2xl text-main-300 tracking-wide ${
              theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
            } bg-opacity-75 font-spartan rounded-lg p-2 ease-in-out duration-400 ${
              currentUser && currentUser.name ? '' : 'blink'
            } flex lg:flex-row flex-col items-center justify-center`}
          >
            <div className="flex items-center">
              {currentUser && currentUser.name ? currentUser.name : ''}{' '}
              {verified.includes(profileID) && (
                <span
                  className="text-main-200 lg:ml-2 ml-1 font-spartan uppercase ri-checkbox-circle-line"
                  title={terms.verified}
                ></span>
              )}
            </div>

            <span className="text-main-200 lg:ml-2 mt-1 font-spartan uppercase">
              (
              {currentUser && currentUser.username
                ? currentUser.username
                : terms.loading}
              )
            </span>
          </div>

          <div className="w-full sm:w-2/3 flex justify-center items-center mt-2">
            <div
              className={`w-full flex justify-center py-2 ${
                theme === 'light'
                  ? 'bg-gray-300 text-gray-800'
                  : 'bg-gray-700 text-gray-200'
              } sm:text-lg text-xs rounded-lg tracking-wide font-sans ease-in-out duration-400 bg-opacity-75`}
            >
              {bio}
            </div>
            {profile &&
              profile.profileID &&
              profile.profileID === profileID && (
                <button
                  title={terms.edit_bio}
                  className={`sm:w-12 sm:h-12 w-10 h-10 ml-2 p-2 sm:text-2xl text-base rounded-full ri-pencil-line ${
                    theme === 'light'
                      ? 'bg-gray-300 hover:bg-gray-100 focus:bg-gray-100 text-main-400'
                      : 'bg-gray-700 hover:bg-gray-900 focus:bg-gray-900 text-main-200'
                  } flex justify-center items-center ease-in-out duration-400`}
                  onClick={() => setEditingBio(true)}
                />
              )}
          </div>

          <div className="flex justify-center my-2 w-full lg:w-2/5">
            <div
              className={`flex flex-col items-center p-1 lg:p-2 w-1/2 lg:w-1/2 justify-center font-gilroy tracking-wide ${
                theme === 'light'
                  ? 'text-gray-700 bg-gray-300'
                  : 'text-gray-300 bg-gray-800'
              } text-opacity-75 border-2 border-transparent rounded-lg ease-in-out duration-400`}
              theme={theme}
            >
              <div
                className={`font-normal text-xs lg:text-sm font-spartan ${
                  theme === 'light' ? 'text-gray-800' : 'text-gray-200'
                }`}
              >
                {terms.followers}
              </div>
              <div
                className={`font-normal text-xxs lg:text-xs font-spartan mt-1 ${
                  theme === 'light' ? 'text-gray-800' : 'text-gray-200'
                }`}
              >
                {currentUser && currentUser.followers_length
                  ? currentUser.followers_length
                  : 0}
              </div>
            </div>

            <div
              className={`flex flex-col items-center p-1 lg:p-2 w-1/2 lg:w-1/2 justify-center font-gilroy tracking-wide ${
                theme === 'light'
                  ? 'text-gray-700 bg-gray-300'
                  : 'text-gray-300 bg-gray-800'
              } text-opacity-75 border-2 border-transparent rounded-lg ease-in-out duration-400 ml-2 lg:ml-4`}
              theme={theme}
            >
              <div
                className={`font-normal text-xs lg:text-sm font-spartan ${
                  theme === 'light' ? 'text-gray-800' : 'text-gray-200'
                }`}
              >
                {terms.following}
              </div>
              <div
                className={`font-normal text-xxs lg:text-xs font-spartan mt-1 ${
                  theme === 'light' ? 'text-gray-800' : 'text-gray-200'
                }`}
              >
                {currentUser && currentUser.following_length
                  ? currentUser.following_length
                  : 0}
              </div>
            </div>
          </div>

          {profile && profile.profileID && profile.profileID === profileID && (
            <div className="flex justify-center mb-2 w-full lg:w-2/5">
              <button
                className={`flex flex-col items-center p-1 lg:p-2 w-1/2 lg:w-1/2 justify-center font-gilroy tracking-wide hover:border-main-300 focus:border-main-300 ${
                  theme === 'light'
                    ? 'text-gray-700 bg-gray-300'
                    : 'text-gray-300 bg-gray-800'
                } text-opacity-75 rounded-lg ease-in-out duration-400 border-2 border-transparent`}
                onClick={() => updateImage(false)}
                theme={theme}
              >
                <div
                  className={`font-normal text-xs lg:text-sm font-spartan ${
                    theme === 'light' ? 'text-gray-800' : 'text-gray-200'
                  } py-2`}
                >
                  {terms.update_ppic}
                </div>
              </button>

              <button
                className={`flex flex-col items-center p-1 lg:p-2 w-1/2 lg:w-1/2 justify-center font-gilroy tracking-wide hover:border-main-300 focus:border-main-300 ${
                  theme === 'light'
                    ? 'text-gray-700 bg-gray-300'
                    : 'text-gray-300 bg-gray-800'
                } text-opacity-75 rounded-lg ease-in-out duration-400 border-2 border-transparent ml-2 lg:ml-4`}
                onClick={() => updateImage(true)}
                theme={theme}
              >
                <div
                  className={`font-normal text-xs lg:text-sm font-spartan ${
                    theme === 'light' ? 'text-gray-800' : 'text-gray-200'
                  } py-2`}
                >
                  {terms.update_cover_image}
                </div>
              </button>
            </div>
          )}

          {profile &&
            profile.following &&
            profile.profileID !== profileID &&
            currentUser &&
            currentUser.uid && (
              <div className="flex justify-center mb-2 w-full">
                <button
                  className={`flex flex-col items-center p-1 lg:p-2 w-full lg:w-2/5 justify-center font-gilroy tracking-wide hover:border-main-300 focus:border-main-300 ${
                    theme === 'light'
                      ? 'text-gray-700 bg-gray-300'
                      : 'text-gray-300 bg-gray-800'
                  } text-opacity-75 rounded-lg ease-in-out duration-400 border-2 border-transparent`}
                  onClick={() => submitFollow()}
                  theme={theme}
                >
                  <div
                    className={`font-normal text-xs lg:text-sm font-spartan py-2 ${
                      profile && profile.following.includes(profileID)
                        ? 'text-main-600'
                        : 'text-main-400'
                    }`}
                  >
                    {profile && profile.following.includes(profileID)
                      ? terms.unfollow
                      : terms.follow}
                  </div>
                </button>
              </div>
            )}
        </div>
      </div>

      <div className="w-full px-4">
        <div
          className={`${
            theme === 'light' ? 'text-main-400' : 'text-main-200'
          } w-full text-xl lg:text-2xl font-semibold font-spartan text-left mt-2`}
        >
          {terms.posts} ({filteredPosts && filteredPosts.length})
        </div>

        {filteredPosts && filteredPosts.length > 0 && (
          <div
            className={`w-full ${
              theme === 'light' ? 'bg-gray-300' : 'bg-gray-700'
            } bg-opacity-50 rounded-lg mt-2`}
          >
            <PaginationList
              limit={limit}
              amount={filteredPosts.length}
              setter={setCurrentPage}
              additional="mb-2"
              theme={theme}
              language={language}
            />
          </div>
        )}

        <div className="w-full my-4 flex flex-col lg:grid grid-cols-2 lg:justify-start gap-2 lg:gap-4 lg:pb-0">
          {filteredPosts &&
            filteredPosts
              .slice(currentPage * limit, currentPage * limit + limit)
              .map((post, i) => {
                const author = profiles.find((p) => p.uid === post.uid);

                return (
                  <div
                    className={`transition-all flex-shrink-0 flex-grow-0 w-full ease-in-out duration-400
              hover:border-main-300 bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500 focus:from-green-400 focus:to-blue-50 border-4 ${
                theme === 'light' ? 'border-gray-800' : 'border-gray-200'
              } rounded-lg sm:h-60 h-32 p-2 flex flex-col justify-end`}
                    key={post.blogID}
                    style={
                      post.preview_img && post.preview_img.length > 8
                        ? {
                            color: '#4a5568',
                            background: `url(${post.preview_img}) center / cover no-repeat #4a5568`,
                          }
                        : {}
                    }
                  >
                    <button
                      className="w-full outline-none"
                      onClick={() => navigate(`/v/${post.blogID}`)}
                    >
                      <div
                        className={`text-base lg:text-lg tracking-wide text-left text-main-200 font-spartan font-semibold bg-gray-900 bg-opacity-75 rounded py-1 px-2`}
                      >
                        {post.title}
                      </div>

                      <div className="text-sm lg:text-base tracking-wide font-normal mt-1 text-main-300 bg-opacity-75 bg-gray-900 rounded px-2 text-left">
                        {post.subtitle}
                      </div>
                    </button>

                    <div className="text-xxs lg:text-xs tracking-wide font-normal mt-1 text-gray-200 bg-gray-900 opacity-75 rounded px-2 w-full text-left font-sans">
                      {terms.last_updated}
                      <button
                        className="text-yellow-300 hover:underline focus:underline"
                        onClick={() => navigate(`/u/${author.uid}`)}
                      >
                        {author && author.username
                          ? author.username.length > 20
                            ? author.username.substring(0, 20)
                            : author.username
                          : ''}
                      </button>
                      {', '}
                      <span className="text-green-300">
                        {convert(post.updated_on, language, false)}
                      </span>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>

      <IncludeEditBio
        theme={theme}
        terms={terms}
        editingBio={editingBio}
        setEditingBio={setEditingBio}
        bio={bio}
        setBio={setUserBio}
        submitUpdateBio={submitUpdateBio}
      />
    </div>
  );
}
