import create from 'zustand';
import { combine } from 'zustand/middleware';

export const useNotificationStore = create(
  combine(
    {
      notifications: [],
    },

    (set) => ({
      setNotifications: (value) => set({ notifications: value.reverse() }),

      updateNotification: (id, new_read) =>
        set((state) => {
          let updatedNotifications = [...state.notifications];
          updatedNotifications = updatedNotifications.map((n) => {
            let updatedNotification = { ...n };
            if (n.notificationID === id) {
              updatedNotification.read = new_read;
            }
            return updatedNotification;
          });
          return { notifications: updatedNotifications };
        }),
    })
  )
);
