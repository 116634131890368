import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

import { LocalContext } from '../wrappers/LocalContext';

import { useNotificationStore } from '../stores/useNotificationStore';

import { findProfile } from '../utils/finder';
import { convert } from '../utils/timestamp';

const toggleNotificationState = (
  notificationID,
  status,
  updateNotification,
  API_URL,
  profile
) => {
  updateNotification(notificationID, status);

  axios
    .post(
      `${API_URL}/blog/notification/read`,
      {
        uid: profile.uid,
        notificationsID: [notificationID],
        is_read: status,
      },
      {
        headers: { Authorization: `Bearer ${profile.jwt}` },
      }
    )
    .then(async (res) => {
      if (res.data.error !== 0) {
        console.log(res.data);
      }
    });
};

const processNotification = (notification, profiles, language) => {
  let type = notification.type;
  let processedText = notification.content.split(' ');
  let foundProfile = null;

  let username = '';

  for (let i = 0; i < processedText.length; i++) {
    if (processedText[i].substring(0, 4) === 'uid.') {
      foundProfile = findProfile(processedText[i].split('.')[1], profiles);
      username =
        foundProfile.username[0] === '?'
          ? notification.senderUsername
          : foundProfile.username;
    }
  }

  let redirect = '/';
  let logo_type = 'notification';
  let content = processedText.join(' ');

  if (type === 'follow') {
    redirect = `/u/${foundProfile.uid}`;
    logo_type = 'user-received';
    if (content.includes('started following you')) {
      if (language === 'mu') {
        content = `${username} ine koumans follow you`;
      } else if (language === 'fr') {
        content = `${username} est maintenant en train de vous suivre`;
      } else {
        content = `${username} is now following you`;
      }
    }
  } else if (type === 'comment') {
    redirect = `/${notification.redirect}`;
    logo_type = 'message-2';
    if (language === 'mu') {
      content = `${username} ine comment lor ou post`;
    } else if (language === 'fr') {
      content = `${username} a commenté sur votre poste`;
    } else {
      content = `${username} commented on your post`;
    }
  } else if (type === 'comment_reply') {
    redirect = `/${notification.redirect}`;
    logo_type = 'discuss';
    if (language === 'mu') {
      content = `${username} ine reply ou comment`;
    } else if (language === 'fr') {
      content = `${username} a répondu à votre commentaire`;
    } else {
      content = `${username} has replied to your comment`;
    }
  } else if (type === 'comment_like') {
    redirect = `/${notification.redirect}`;
    logo_type = 'chat-heart';
    if (language === 'mu') {
      content = `${username} ine like ou comment`;
    } else if (language === 'fr') {
      content = `${username} a réagi à votre commentaire`;
    } else {
      content = `${username} has liked your comment`;
    }
  } else if (type === 'like') {
    redirect = `/${notification.redirect}`;
    logo_type = 'user-heart';
    if (language === 'mu') {
      content = `${username} ine like ou post`;
    } else if (language === 'fr') {
      content = `${username} a réagi à votre poste`;
    } else {
      content = `${username} has liked your post`;
    }
  }

  return {
    logo_type: logo_type,
    redirect: redirect,
    content: content,
    created_on: notification.created_on,
  };
};

export default function Notification({
  notification,
  theme,
  terms,
  profile,
  profiles,
  language,
}) {
  const navigate = useNavigate();

  const { updateNotification } = useNotificationStore((state) => state);

  const { API_URL, setShowNotifications } = useContext(LocalContext);

  const processed = processNotification(notification, profiles, language);

  return (
    <div
      className={`w-full flex items-center rounded-lg ${
        theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
      } ease-in-out duration-400 pr-2 mb-2`}
      key={`notif-${notification.notificationID}`}
    >
      <button
        className={`justify-center w-full p-2 font-gilroy tracking-wide text-main-300 text-opacity-75 flex items-center ${
          theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
        } border-2 border-transparent rounded-lg outline-none hover:border-main-300 duration-400 ease-in-out`}
        onClick={() => {
          if (!notification.read) {
            toggleNotificationState(
              notification.notificationID,
              !notification.read,
              updateNotification,
              API_URL,
              profile
            );
          }

          navigate(processed.redirect);

          setShowNotifications(false);
        }}
      >
        <div className="w-full flex items-center">
          <div
            className={`w-10 h-9 lg:w-8 lg:h-8 border-2 border-main-300 p-1 flex items-center justify-center rounded-full ri-${processed.logo_type}-line`}
          />

          <div className="w-full flex-col">
            <div className="text-xxs lg:text-xs text-left sm:text-xs ml-4 font-normal font-spartan flex items-center text-main-300">
              {processed.content}
            </div>

            <div
              className={`${
                theme === 'light' ? 'text-main-400' : 'text-main-200'
              } text-left sm:text-xxs ml-4 opacity-50 w-full text-xxs lg:text-xs font-normal font-spartan flex items-center`}
            >
              {convert(processed.created_on, language, false)}
            </div>
          </div>
        </div>
      </button>

      <button
        title={notification.read ? terms.mark_unread : terms.mark_read}
        className={`justify-center w-8 h-8 flex p-2 items-center text-sm rounded-full ri-mail-${
          notification.read ? 'open' : 'check'
        }-line ml-2 font-gilroy tracking-wide ${
          notification.read
            ? 'text-main-600 hover:border-main-600'
            : 'text-main-200 hover:border-main-200'
        } text-opacity-75 flex items-center ${
          theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
        } border-2 border-transparent outline-none ease-in-out duration-400`}
        onClick={() =>
          toggleNotificationState(
            notification.notificationID,
            !notification.read,
            updateNotification,
            API_URL,
            profile
          )
        }
      ></button>
    </div>
  );
}
