import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import axios from 'axios';

import { usePostStore } from '../stores/usePostStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';

import useLanguage from '../wrappers/LanguageWrapper';
import { convert } from '../utils/timestamp';

import { LocalContext } from '../wrappers/LocalContext';

import { automaticLogin } from '../utils/fetcher';

import Navbar from '../components/Navbar';
import PaginationList from '../wrappers/PaginationList';
import IncludeDeletePost from './includes/IncludeDeleteTodo';

export default function Admin() {
  const { posts, deletePost, publishPost } = usePostStore((state) => state);
  const { profile } = useUserProfileStore((state) => state);
  const { theme } = useThemeStore((state) => state);
  const { language, setLanguage } = useLanguageStore((state) => state);

  const { API_URL } = useContext(LocalContext);
  const terms = useLanguage(language).admin;
  const navigate = useNavigate();
  const alert = useAlert();

  const limit = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [deletingPost, setDeletingPost] = useState('');

  useEffect(() => {
    setCurrentPage(0);
    setDeletingPost('');

    // if (!profile || !profile.uid) {
    //   navigate('/login');
    // }
    // eslint-disable-next-line
  }, []);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setDeletingPost('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
    // eslint-disable-next-line
  }, []);

  const sortDate = (posts) => {
    let updatedPosts = [...posts];
    return updatedPosts.sort(
      (a, b) => new Date(b.updated_on) - new Date(a.updated_on)
    );
  };

  const filteredPosts = posts
    ? !profile || !profile.uid
      ? sortDate([])
      : sortDate(posts.filter((p) => p.uid === profile.uid))
    : [];

  useEffect(() => {
    const fetchData = async () => {
      if (!profile || !profile.profileID) {
        await automaticLogin(API_URL, '', '');
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (lang) => {
    if (lang !== language) {
      axios
        .post(
          `${API_URL}/profile/update`,
          { uid: profile.uid, language: lang },
          {
            headers: { Authorization: `Bearer ${profile.jwt}` },
          }
        )
        .then((res) => {
          if (res.data.error === 0) {
            setLanguage(lang);
            alert.success(terms.language_success);

            localStorage.setItem(
              '_userData',
              JSON.stringify({
                uid: profile.uid,
                jwt: profile.jwt,
                theme: theme,
                lang: lang,
              })
            );
          } else {
            console.log(res.data);
          }
        });
    }
  };

  const submitPublishPost = (blogID, newStatus) => {
    axios
      .post(
        `${API_URL}/blog/post/publish`,
        { uid: profile.uid, blogID: blogID, publish: newStatus },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          publishPost(blogID, newStatus);
          alert.success(
            newStatus ? terms.publish_success : terms.unpublish_success
          );
        } else {
          console.log(res.data);
        }
      });
  };

  const submitDeletePost = (blogID) => {
    setDeletingPost('');
    alert.info(terms.deleting);

    axios
      .post(
        `${API_URL}/blog/post/delete`,
        { uid: profile.uid, blogID: blogID },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          deletePost(blogID);
          alert.success(terms.deleted_success);
        } else {
          console.log(res.data);
        }
      });
  };

  const makeLangButton = (name, code, noLeft, noRight) => (
    <button
      className={`font-gilroy tracking-wide ${
        language === code
          ? theme === 'light'
            ? 'text-main-400'
            : 'text-main-200'
          : 'text-main-300'
      } text-opacity-75 flex items-center justify-center ${
        theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
      }
border-2 border-transparent rounded-lg ${noLeft && 'rounded-l-none'} ${
        noRight && 'rounded-r-none'
      } py-2 w-full
outline-none hover:border-main-300 ease-in-out duration-400`}
      onClick={() => changeLanguage(code)}
    >
      {name}
    </button>
  );

  return (
    <div
      className={`w-full ${
        theme === 'light' ? 'bg-main-100' : 'bg-main-900'
      } ease-in-out duration-400 lg:pb-0 pb-20 flex justify-center`}
    >
      <Navbar />

      <div className="w-full lg:w-4/5 px-4 p-2 pt-20 lg:pt-24">
        <div className="flex justify-between mt-2">
          <div
            className={`${
              theme === 'light' ? 'text-main-400' : 'text-main-200'
            } w-full text-xl lg:text-2xl font-semibold font-spartan text-left`}
          >
            {terms.posts} ({filteredPosts && filteredPosts.length})
          </div>

          <button
            title={terms.create_post}
            onClick={() => {
              navigate(`/p/create`);
            }}
            className={`text-center lg:text-sm text-xs border-2 border-transparent flex justify-center items-center rounded lg:w-1/3 py-2 w-full ${
              theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
            } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ease-in-out duration-400 outline-none`}
          >
            <div className="mr-2">{terms.create_post}</div>
            <div className={`h-full flex items-center ri-menu-add-line`}></div>
          </button>
        </div>

        {filteredPosts && filteredPosts.length > 0 && (
          <div
            className={`w-full ${
              theme === 'light' ? 'bg-gray-300' : 'bg-gray-700'
            } bg-opacity-50 rounded-lg mt-2`}
          >
            <PaginationList
              limit={limit}
              amount={filteredPosts.length}
              setter={setCurrentPage}
              additional="mb-2"
              theme={theme}
              language={language}
            />
          </div>
        )}

        <div
          className={`mb-2 w-full flex flex-col pb-2 px-2 rounded-lg ${
            theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
          } bg-opacity-75 ease-in-out duration-400`}
        >
          {filteredPosts &&
            filteredPosts
              .slice(currentPage * limit, currentPage * limit + limit)
              .map((post, i) => {
                return (
                  <div
                    className={`w-full ease-in-out duration-400 mt-2 rounded-lg p-2 flex flex-col justify-end border-4 ${
                      post.published ? 'border-main-200' : 'border-main-600'
                    }`}
                    key={post.blogID}
                  >
                    <button
                      className="w-full outline-none"
                      onClick={() => navigate(`/v/${post.blogID}`)}
                    >
                      <div
                        className={`text-base lg:text-lg tracking-wide text-left font-spartan font-semibold ${
                          theme === 'light'
                            ? 'bg-gray-100 text-main-400'
                            : 'bg-gray-900 text-main-200'
                        } bg-opacity-75 rounded py-1 px-2 ease-in-out duration-400`}
                      >
                        {post.title}
                      </div>

                      <div
                        className={`text-sm lg:text-base tracking-wide font-normal mt-1 text-main-300 bg-opacity-75 ${
                          theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                        } rounded px-2 text-left ease-in-out duration-400`}
                      >
                        {post.subtitle}
                      </div>
                    </button>

                    <div
                      className={`text-xxs lg:text-xs tracking-wide font-normal mt-2 ${
                        theme === 'light'
                          ? 'bg-gray-100 text-gray-900'
                          : 'bg-gray-900 text-gray-200'
                      } opacity-75 rounded px-2 w-full text-left font-sans ease-in-out duration-400`}
                    >
                      {terms.last_updated}
                      <span
                        className={`${
                          theme === 'light' ? 'text-main-400' : 'text-main-200'
                        }`}
                      >
                        {convert(post.updated_on, language, true)}
                      </span>
                    </div>

                    <div className="w-full flex lg:justify-between flex-col lg:flex-row mt-2">
                      <button
                        title={post.published ? terms.unpublish : terms.publish}
                        onClick={() =>
                          submitPublishPost(post.blogID, !post.published)
                        }
                        className={`text-center lg:text-sm text-xs border-2 border-transparent flex justify-center items-center rounded lg:w-1/3 py-2 w-full ${
                          theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                        } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ease-in-out duration-400 outline-none`}
                      >
                        <div className="mr-2">
                          {post.published ? terms.unpublish : terms.publish}
                        </div>
                        <div
                          className={`h-full flex items-center ri-inbox-${
                            post.published ? 'un' : ''
                          }archive-line`}
                        ></div>
                      </button>

                      <button
                        title={terms.edit_post}
                        onClick={() => {
                          navigate(`/p/edit/${post.blogID}`);
                        }}
                        className={`text-center lg:text-sm text-xs border-2 border-transparent flex justify-center items-center rounded lg:w-1/3 py-2 w-full ${
                          theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                        } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ease-in-out duration-400 lg:mx-2 lg:my-0 my-1 outline-none`}
                      >
                        <div className="mr-2">{terms.edit_post}</div>
                        <div
                          className={`h-full flex items-center ri-edit-2-line`}
                        ></div>
                      </button>

                      <button
                        title={terms.delete_post}
                        onClick={() => {
                          setDeletingPost(post.blogID);
                        }}
                        className={`text-center lg:text-sm text-xs border-2 border-transparent flex justify-center items-center rounded lg:w-1/3 py-2 w-full ${
                          theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                        } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ease-in-out duration-400 outline-none`}
                      >
                        <div className="mr-2">{terms.delete_post}</div>
                        <div
                          className={`h-full flex items-center ri-delete-bin-2-line`}
                        ></div>
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>

        <div className="w-full rounded-full pt-1 opacity-75 bg-gray-500 my-4" />

        <div
          className={`${
            theme === 'light' ? 'text-main-400' : 'text-main-200'
          } w-full text-xl lg:text-2xl font-semibold font-spartan text-left mt-2`}
        >
          {terms.choose_language}
        </div>

        <div className="w-full flex mt-2">
          {makeLangButton(terms.english, 'en', false, true)}
          {makeLangButton(terms.french, 'fr', true, true)}
          {makeLangButton(terms.creole, 'mu', true, false)}
        </div>
      </div>

      <IncludeDeletePost
        deletingPost={deletingPost}
        setDeletingPost={setDeletingPost}
        submitDeletePost={submitDeletePost}
        terms={terms}
        theme={theme}
      />
    </div>
  );
}
