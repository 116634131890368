import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useAlert } from 'react-alert';

import banner from '../assets/images/typo.png';

import { LocalContext } from '../wrappers/LocalContext';
import useLanguage from '../wrappers/LanguageWrapper';

import { Container, Linker } from './Components';

import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';
import { useNotificationStore } from '../stores/useNotificationStore';

export default function Navbar({ width }) {
  const { API_URL, setShowNotifications, search, setSearch } =
    useContext(LocalContext);

  const { profile } = useUserProfileStore((state) => state);
  const { theme, setTheme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);
  const { notifications } = useNotificationStore((state) => state);

  const alert = useAlert();
  const navigate = useNavigate();

  const terms = useLanguage(language).navbar;

  const changeTheme = () => {
    let newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    localStorage.setItem(
      '_userData',
      JSON.stringify({
        uid: profile.profileID,
        jwt: profile.jwt,
        theme: newTheme,
        lang: profile.language,
      })
    );

    axios
      .post(
        `${API_URL}/profile/update`,
        { uid: profile.profileID, prefer_dark: newTheme === 'dark' },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error !== 0) {
          console.log(res.data);
        } else {
          alert.success(terms.theme_success);
        }
      });
  };

  return (
    <>
      <div
        className={`w-full fixed z-50 h-16 lg:h-20 ${
          theme === 'light' ? 'bg-white' : 'bg-main-900'
        } py-2 lg:py-4 duration-400 ease-in-out lg:overflow-y-hidden`}
      >
        <div className="flex w-full h-full justify-between items-center px-2 lg:px-4">
          <Container additional="w-2/5 sm:w-1/5 h-full flex justify-start items-center mr-4 lg:mr-0">
            <Link
              className="h-full flex justify-start items-center"
              to="/"
              onClick={() => setShowNotifications(false)}
            >
              <img
                src={banner}
                alt="banner"
                className="h-4/5 sm:h-3/5 lg:h-4/5 object-scale-down"
              />

              <div
                className={`text-purple-500 text-2.5xl sm:text-4.5xl font-spartan uppercase ml-2 lg:ml-3 h-full flex items-center pt-2 font-bold`}
              >
                Blog
              </div>
            </Link>
          </Container>

          <div
            className={`sm:w-1/2 sm:flex justify-around h-full rounded-lg p-1 bg-main-300 hidden ease-in-out duration-400`}
          >
            <input
              className={`outline-none w-full h-full text-sm lg:text-sm p-2 rounded-l-lg font-gilroy placeholder-opacity-75 bg-${
                theme === 'light'
                  ? 'gray-200 text-main-900 placeholder-main-900'
                  : 'main-900 text-main-100 placeholder-main-100'
              } ease-in-out duration-400`}
              placeholder={terms.search}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setShowNotifications(false);
                  // saveSearch(search, language);
                  navigate(
                    `/search/${
                      search ? search.trim().split(' ').join('+') : ''
                    }`
                  );
                }
              }}
            />
            <button
              className={`w-12 outline-none flex items-center font-gilroy text-lg hover:opacity-95 focus:opacity-95 justify-center font-bold ri-search-2-line rounded-r-lg ${
                theme === 'light'
                  ? 'bg-gray-300 text-gray-800'
                  : 'bg-gray-800 text-gray-200'
              } ease-in-out duration-400`}
              onClick={() => {
                setShowNotifications(false);
                // saveSearch(search, language);
                navigate(
                  `/search/${search ? search.trim().split(' ').join('+') : ''}`
                );
              }}
            ></button>
          </div>

          <div className="sm:w-1/5 h-full flex justify-end items-center relative">
            <button
              className={`w-9 h-9 sm:w-10 sm:h-10 sm:hidden outline-none border-2 lg:border-4 border-transparent hover:border-main-300 focus:border-main-300 flex justify-center items-center rounded-full ri-search-2-line text-sm lg:text-xl ${
                theme === 'light'
                  ? 'bg-gray-300 text-gray-800'
                  : 'bg-gray-800 text-gray-200'
              } ease-in-out duration-400`}
              onClick={() => {
                setShowNotifications(false);
                // saveSearch(search, language);
                navigate(`/search/`);
              }}
            />

            <Link
              to={
                profile && profile.profileID
                  ? `/u/${profile.profileID}`
                  : '/login'
              }
              title={terms.profile_go}
              onClick={() => setShowNotifications(false)}
              className={`w-9 h-9 sm:w-10 sm:h-10 lg:w-12 lg:h-12 ml-1 sm:ml-0 border-2 lg:border-4 border-transparent hover:border-main-300 focus:border-main-300 flex justify-center items-center rounded-full ri-user-3-line text-sm sm:text-lg lg:text-xl ${
                theme === 'light'
                  ? 'bg-gray-300 text-gray-800'
                  : 'bg-gray-800 text-gray-200'
              } sm:flex hidden ease-in-out duration-400`}
            />

            {profile && profile.profileID && (
              <Link
                to="/p/create"
                title={terms.create}
                onClick={() => setShowNotifications(false)}
                className={`w-9 h-9 sm:w-10 sm:h-10 lg:w-12 lg:h-12 ml-1 sm:ml-2 border-2 lg:border-4 border-transparent hover:border-main-300 focus:border-main-300 flex justify-center items-center rounded-full ri-add-line text-sm sm:text-lg lg:text-xl ${
                  theme === 'light'
                    ? 'bg-gray-300 text-gray-800'
                    : 'bg-gray-800 text-gray-200'
                } ease-in-out duration-400`}
              />
            )}

            {profile && profile.profileID && (
              <button
                title={terms.notifications}
                onClick={() => {
                  setShowNotifications((prev) => !prev);
                }}
                className={`ml-1 sm:ml-2 w-9 h-9 sm:w-10 sm:h-10 lg:w-12 lg:h-12 outline-none border-2 lg:border-4 border-transparent hover:border-main-300 focus:border-main-300 flex justify-center items-center rounded-full ri-notification-3-${
                  notifications &&
                  notifications.filter((n) => !n.read).length > 0
                    ? 'fill text-main-200'
                    : 'line'
                } text-sm sm:text-lg lg:text-xl ${
                  theme === 'light'
                    ? 'bg-gray-300 text-gray-800'
                    : 'bg-gray-800 text-gray-200'
                } ease-in-out duration-400`}
              />
            )}

            {profile && profile.profileID && (
              <Link
                to="/admin"
                title={terms.admin_go}
                onClick={() => setShowNotifications(false)}
                className={`ml-1 hidden lg:ml-2 w-9 h-9 sm:w-10 sm:h-10 lg:w-12 lg:h-12 border-2 lg:border-4 border-transparent hover:border-main-300 focus:border-main-300 lg:flex justify-center items-center rounded-full ri-settings-3-line text-sm lg:text-xl ${
                  theme === 'light'
                    ? 'bg-gray-300 text-gray-800'
                    : 'bg-gray-800 text-gray-200'
                } ease-in-out duration-400`}
              />
            )}

            <button
              title={`${
                theme === 'light' ? terms.dark_mode : terms.light_mode
              }`}
              className={`ml-1 lg:ml-2 w-9 h-9 sm:w-10 sm:h-10 lg:w-12 lg:h-12 outline-none border-2 lg:border-4 border-transparent hover:border-main-300 focus:border-main-300 flex justify-center items-center rounded-full ri-${
                theme === 'light' ? 'sun' : 'moon'
              }-line text-sm lg:text-xl ${
                theme === 'light'
                  ? 'bg-gray-300 text-gray-800'
                  : 'bg-gray-800 text-gray-200'
              } hidden lg:block ease-in-out duration-400`}
              onClick={() => changeTheme()}
            />
          </div>
        </div>

        <div className="w-full mt-2">
          <div
            className="pt-1 bg-main-300"
            style={{ width: `${width !== undefined ? width : 100}%` }}
          ></div>
        </div>
      </div>

      <div
        className={`py-3 lg:hidden bottom-0 fixed z-30 w-screen flex justify-around border-t-2 border-main-300 ${
          theme === 'light' ? 'bg-main-100' : 'bg-main-900'
        } ease-in-out duration-400`}
      >
        <Linker
          to="/"
          title=""
          theme={theme}
          icon="home-2"
          additional="rounded-full ease-in-out duration-400"
        />
        <Linker
          to={
            profile && profile.profileID ? `/u/${profile.profileID}` : '/login'
          }
          theme={theme}
          icon="user-3"
          additional="rounded-full ease-in-out duration-400"
        />
        {profile && profile.profileID && (
          <Linker
            to="/admin"
            theme={theme}
            icon="settings-3"
            additional="rounded-full ease-in-out duration-400"
          />
        )}
        <button
          className={`text-center text-lg px-3 border-2 border-transparent flex justify-center items-center ${
            theme === 'light'
              ? 'bg-gray-300 text-gray-800'
              : 'bg-gray-800 text-gray-200'
          } hover:border-main-300 focus:border-main-300 font-gilroy rounded-full ri-${
            theme === 'light' ? 'sun' : 'moon'
          }-line ease-in-out duration-400`}
          onClick={() => changeTheme()}
        ></button>
      </div>
    </>
  );
}
