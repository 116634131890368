const terms = {
  homepage: {
    latest: 'Dernier posts',
    trending: 'Trending',
    favorites: 'Favoris',
    all: 'Tout bann posts',
    last_updated: 'Ine modifier dernier fois par ',
  },

  login: {
    auto_login: 'P sey konecter automatikment...',
    name_error: 'Nom la bizin ena nek alphabets',
    username_error:
      'Username la pas bizin ena bann symbol particuliers ek bann lespass',
    email_error: 'e-mail la pas valide',
    pass_lowercase: 'Password la bzn ena aumoins 1 ti lettre',
    pass_uppercase: 'Password la bzn ena aumoins 1 lettre majuscule',
    pass_number: 'Password la bzn ena aumoins 1 numero',
    pass_symbol: 'Password la bzn ena aumoins 1 symbol',
    pass_length: 'Password la bzn ena aumoins 8 characters',
    pass_check_error: 'Bann passwords la pas pareil',
    pass_check_error_two: 'Bann passwords la pas pareil',
    privacy_accept: 'Aksepter privacy policy la pou contigner',
    register_success: 'Enrezistrer! Check ou bann mails pou active ou kompte.',
    submitting: 'P soumet...',
    logging: 'P konecter...',
    forgot_success: 'Korek! Check ou bann mails pou trouve reset link la.',
    register_h: 'Enrezistrer',
    name_input: 'Ekrir ou nom...',
    username_input: 'Ekrir ene username...',
    email_input: 'Ekrir ou e-mail...',
    password_input: 'Ekrir ene password...',
    password_check_input: 'Re ekrir password la...',
    policy_read: 'Mone lire ek aksepter ',
    privacy: 'privacy policy',
    register_button: 'Cre',
    account_already: 'Deza ena ene kompte? ',
    login_now: 'Konecter-toi!',
    login_h: 'Konecter',
    multi_auth: 'Ekrir ou username ou e-mail...',
    login_pass: 'Ekrir ou password...',
    password_forget: 'Bliyer password?',
    login_button: 'Nwaller!',
    new_konnect: 'Nouvo lor Konnect Blog? ',
    create_account: 'Cre ene kompte lor Konnect asterla!',
    forgot_h: 'Bliyer password',
    login_back: 'Retourne lor Konecter',
    forgot_button: 'Avoy link pou reset password',
    login_demo: 'Sey Konnect kouma ene user demo!',
    login_sso: 'Konnecter par SSO',
  },

  auto_login: {
    login_success: 'Konection automatik ine marser',
    login_failed: 'Konection automatik pane marser',
    login_message: 'P sey konecter automatikment...',
  },

  navbar: {
    search: 'Rod kitsoz ou kiken...',
    profile_go: 'Ale lor ou Profile',
    create: 'Cre',
    cancel: 'Cancel',
    new_post: 'Cre ene Nouvo Post',
    new_group: 'Cre ene Nouvo Group',
    notifications: 'Notifications',
    settings_go: 'Ale dans settings',
    admin_go: 'Ale lor Admin',
    dark_mode: 'Ale lor dark mode',
    light_mode: 'Ale lor light mode',
    menu: 'Menu',
    navigation: 'Navigation',
    profile: 'Profile',
    chats: 'Chat',
    groups: 'Groups',
    people: 'Dimounes',
    games: 'Zwes',
    settings: 'Settings',
    post_new: 'Nouvo Post',
    group_new: 'Nouvo Group',
    misc: 'Lezot',
    theme_success: 'Theme la ine update!',
    new_status: 'Cre ene Nouvo Status',
    status_new: 'Nouvo Status',
    saved_posts: 'Bann Posts oune Save',
  },

  feed: {
    stories: 'Status',
    story: 'Gett so Status',
    online: 'Online',
    content: 'Conteni',
    chats: 'Chat',
    groups: 'Groups',
    people: 'Dimounes',
    games: 'Zwes',
    pymk: 'Dimounes ou kav koner',
    people_add: 'Avoy ene connection request',
    remove_list: 'Tir li depi sa laliste la',
    react: 'React',
    comment: 'Comment',
    save: 'Save',
    share: 'Partazer',
    enter_comment: 'Ekrir ene comment...',
    reply: 'Reponn',
    show_more: 'Montrer plis...',
    no_comments: 'Pencore gagne oken comments lor sa post la...',
    comments_not_allowed: 'Bann comments dezaktiver.',
    view_post: 'Gett Post la',
    edit_post: 'Modifier Post la',
    delete_post: 'Delete Post la',
    save_post: 'Save Post la',
    unsave_post: 'Unsave Post la',
    save_success: 'Post la ine save',
    unsave_success: 'Post la ine unsave',
    username: 'Username',
    name: 'Nom',
    no_posts: 'Pena posts dans ou feed... Sey follow plis dimounes',
    sure_delete: 'Ou sire ou envi delete sa post la?',
    yes: 'Oui',
    no: 'Non',
    deleting: 'P delete...',
    deleted_success: 'Post la ine delete!',
    reacts: 'Reactions',
    replying: 'P reponn',
    edit_comment: 'Edit comment la',
    update_comment: 'Ekrir ene nouvo comment...',
    delete_comment: 'Delete comment la',
    delete_comment_sure: 'Ou sire ou envi delete sa comment la?',
    back_original: 'Gett post orizinal la',
    post_share_success: 'Post la ine share',
    share_post_sure: 'Ou sire ou envi share sa post la?',
    post_not_found: 'Pane resi trouve sa post la.',
    return_feed: 'Retourne lor ou Feed',
    loading: 'P load...',
    no_stories: 'Oken status pane fek poster...',
    success: 'Operation la ine resi!',
    create_story: 'Cre ene nouvo Status',
    saved_posts: 'Bann Posts oune Save',
    collections: 'Collections',
    story_new: 'Nouvo Status',
    you: 'Ou',
    and: 'ek',
    others: 'lot dimounes',
    reacted_to: 'ine react lor sa',
    this_post: 'post la',
    this_comment: 'comment la',
    next: 'Prosain Paz',
    previous: 'Paz Avant',
    seen_all: 'Oune trouve tout bann posts dans ou feed!',
    choose_collection: 'Soizir ene collection',
    no_collections: 'Pencore cre oken collection...',
    no_collections_saved: 'Pencore save dans oken collection...',
    edited: 'Ine Modifier',
    uploading: 'P upload...',
    image_success: 'Zimaz la ine resi upload',
  },

  create_post: {
    uploading: 'P upload...',
    image_success: 'Zimaz la ine resi upload',
    content_short: 'Pna assez contenu',
    post_create_success: 'Post ine cre avec succes',
    post_edit_success: 'Post ine edit avec succes',
    create_post: 'Cre ene Nouvo Post',
    edit_post: 'Edit ene Post',
    posting_as: 'P poster kouma',
    submitting: 'P soumet...',
    editor: 'Editer',
    preview: 'Preview',
    edit: 'Edit',
    create: 'Cre',
    enable_comments: 'Active bann Comments',
    visibility: 'Vizibiliter',
    choose_visibility: 'Soizir ene Vizibiliter',
    in: 'dan',
    title_placeholder: 'Ekrir ene Titre...',
    subtitle_placeholder: 'Ekrir ene Subtitle...',
    slug_placeholder: "Ekrri ene 'Slug'...",
    preview_img: 'Soizir ene zimaz preview',
    optional: 'optionel',
    carousel: 'Soizir bann fotos pou Carousel',
    upload: 'Upload',
    chosen: 'ine soizir',
    tags: 'Soizir bann tags (separer par bann virgules)',
    remove: 'Retirer',
    content: 'Content',
    submit: 'Soumet',
    cancel: 'Cancel',
    loading: 'P load...',
    post_not_found: 'Pane resi trouv post la :(',
    return_feed: 'Retourne lor ou Feed',
  },

  settings: {
    settings: 'Settings',
    preferences: 'Preferans',
    language: 'Langaz',
    english: 'Angler',
    french: 'Francer',
    creole: 'Kreol',
    theme: 'Theme',
    dark: 'Dark',
    light: 'Light',
    account: 'Kompte',
    name: 'Nom',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    verified: 'Ine Verifier',
    unverified: 'Pane verifier',
    submit: 'Soumet',
    cancel: 'Cancel',
    change: 'Sanzer',
    account_type: 'Kaliter ou Kompte',
    public: 'Publik',
    restricted: 'Restrain',
    private: 'Private',
    ghost: 'Fantom',
    name_error: 'Nom la bizin ena nek alphabets',
    username_error:
      'Username la pas bizin ena bann symbol particuliers ek bann lespass',
    email_error: 'e-mail la pas valide',
    pass_lowercase: 'Password la bzn ena aumoins 1 ti lettre',
    pass_uppercase: 'Password la bzn ena aumoins 1 lettre majuscule',
    pass_number: 'Password la bzn ena aumoins 1 numero',
    pass_symbol: 'Password la bzn ena aumoins 1 symbol',
    pass_length: 'Password la bzn ena aumoins 8 characters',
    pass_check_error: 'Bann passwords la pas pareil',
    pass_check_error_two: 'Bann passwords la pas pareil',
    name_input: 'Ekrir ou nom...',
    username_input: 'Ekrir ene username...',
    email_input: 'Ekrir ou e-mail...',
    password_input: 'Ekrir ene password...',
    password_check_input: 'Re ekrir password la...',
    current_password_input: 'Ekrir ou password actuel',
    name_success: 'Ou nom ine update!',
    username_success: 'Ou username ine update!',
    email_success: 'Ou email ine update!',
    password_success: 'Ou password ine update!',
    language_success: 'Langaz la ine update!',
    theme_success: 'Theme la ine update!',
    account_type_success: 'Kaliter kompte la ine update!',
    submitting: 'P soumet...',
    reset_password: 'Reset ou password',
    password_reset_success: 'Link pou reset ou password ine avoyer!',
    sure_reset: 'Ou sire ou envi reset ou password?',
    yes: 'Oui',
    no: 'Non',
    deactivate_success: 'Ou kompte in desactiver!',
    logout_success: 'Oune log out!',
    deactivate: 'Desactiv ou kompte',
    logout: 'Log out',
    contributors: 'Contributers',
    access_groups: 'Bann Access Groups',
    access_group_name: 'Ekrir ene nom...',
    access_group_members: 'Soizir bann Membres',
    create: 'Cre',
    update: 'Update',
    create_access_success: 'Access Group la ine cre',
    update_access_success: 'Access Group la ine update',
    create_access: 'Cre ene Access Group',
    edit_access: 'Edit Access Group la',
    sure_logout: 'Ou sire ou envi logout?',
    unauthorized_action: 'Errer: Sa action la pas autorizer',
  },

  stories: {
    viewers: 'Visiters',
    delete_status_sure: 'Ou sire ou envi delete sa status la?',
    delete_status_success: 'Status la ine delete!',
    yes: 'Oui',
    no: 'Non',
  },

  profile: {
    story: 'Gett so Status',
    no_posts: 'Sa user la pencore poster...',
    posts: 'Posts',
    loading: 'P load...',
    user_not_found: 'Pane resi trouv user la...',
    return_feed: 'Retourne lor ou Feed',
    edit_bio: 'Edit ou bio',
    enter_bio: 'Ekrir ene bio...',
    submit: 'Soumet',
    cancel: 'Cancel',
    bio_update_success: 'Ou bio ine update!',
    change_profile_pic: 'Sanz ou Profile Picture',
    uploading: 'P upload...',
    image_success: 'Zimaz la ine resi upload',
    ppic_success: 'Ou Profile Picture ine update!',
    cover_image_success: 'Ou Cover Image ine update!',
    file_too_big: 'File la trop gros (> 10 MB)',
    update_ppic: 'Update ou profile pic',
    update_avatar: 'Update ou Avatar',
    update_cover_image: 'Update ou cover image',
    submitting: 'P soumet...',
    create_story: 'Cre ene nouvo Status',
    story_create_success: 'Status la ine poster!',
    editor: 'Editer',
    create: 'Cre',
    preview: 'Preview',
    posting_as: 'P poster kouma',
    no_followers: 'Personne pas p follow sa dimoune la',
    no_following: 'Sa dimoune la pas p follow oken lezot dimounes',
    cannot_view_followers: 'Pas kav montrer bann followers sa user la',
    cannot_view_following: 'Pas kav montrer bann dimounes ki sa dimoune follow',
    follows_you: 'Sa dimoune la follow ou',
    follow: 'Follow',
    unfollow: 'Aret Follow',
    send_follow_request: 'Avoy ene Follow Request',
    unsend_follow_request: 'Delete Follow Request la',
    block: 'Bloker',
    unblock: 'Unblock',
    success: 'Operation la ine resi!',
    followers: 'Followers',
    following: 'Following',
    blocked: 'Blocker',
    verified: 'Sa kompte la ine verifier.',
    search_history: 'Bann zafrs oune roder',
    no_search: 'Ou pencore rod nannier.',
    story_archive: 'Archive ou bann Status',
    manage_highlights: 'Manage ou bann Highlights',
    no_highlights: 'Pencore cre oken highlights.',
    create_highlight: 'Cre ene Highlight',
    edit_highlight: 'Edit sa Highlight la',
    create_highlight_success: 'Highlight la in cre',
    edit_highlight_success: 'Highlight la ine update',
    delete_highlight_success: 'Highlight la ine delete',
    enter_highlight_name: 'Ekrir ene nom...',
    highlight_icon: 'Soizir ene icon (optionel)',
    choose_stories: 'Soizir bann status pour azouter',
    visibility: 'Vizibiliter',
    choose_visibility: 'Soizir ene Vizibiliter',
    avatar_update_success: 'Ou Avatar ine update!',
    last_updated: 'Ine modifier dernier fois par ',
  },

  badge: {
    contributor: 'Contributer',
    contributor_desc: 'Donner pou ztu ki contribuer dans development Konnect.',
    early: 'Adopteur',
    early_desc: 'Donner pou bann premier membres Konnect.',
  },

  notifications: {
    notifications: 'Notifications',
    follow_requests: 'Follow Requests',
    invites: 'Bann Invites',
    received: 'Gagner',
    sent: 'Avoyer',
    accept: 'Accepter',
    decline: 'Decliner',
    cancel: 'Cancel',
    request_accept: 'Follow Request la ine Accepter',
    request_decline: 'Follow Request la ine Decliner',
    request_cancel: 'Follow Request la ine Unsend',
    mark_read: 'Marker kouma read',
    mark_unread: 'Marker kouma unread',
    chat: 'Chats',
    group: 'Groups',
    invite_accept: 'Reket la ine aksepter',
    invite_reject: 'Reket la ine rezecter',
  },

  search: {
    people: 'Dimounes',
    groups: 'Groups',
    rooms: 'Bann Conversations',
    posts: 'Posts',
    placeholder: 'Rod kitsoz ou kiken...',
    searching: 'P roder...',
    last_updated: 'Ine modifier dernier fois par ',
  },

  people: {
    people: 'Dimounes',
    follow: 'Follow',
    unfollow: 'Aret Follow',
    send_follow_request: 'Avoy ene Follow Request',
    unsend_follow_request: 'Delete Follow Request la',
    success: 'Operation la ine resi!',
    followers: 'Followers',
    following: 'Following',
  },

  groups: {
    groups: 'Groups',
    create_group: 'Cre ene Group',
    name: 'Nom Group la',
    name_placeholder: 'Ekrir ene nom...',
    description: 'Description Group la',
    description_placeholder: 'Ekrir ene description...',
    privacy: 'Soizir ene Privacy',
    public: 'Publik',
    private: 'Priver',
    select_members: 'Soizir bann Membres',
    create: 'Cre',
    update: 'Update',
    create_group_success: 'Group la ine cre',
    update_group_success: 'Group la ine update',
    submit: 'Soumet',
    submitting: 'P soumet...',
    file_too_big: 'File la trop gros (> 10 MB)',
    uploading: 'P upload...',
    image_success: 'Zimaz la ine resi upload',
    group_icon: 'Soizir ene icon pou group la (optionel)',
    group_cover: 'Soizir ene cover image pou group la (optionel)',
    loading: 'P load...',
    group_not_found: 'Pane Resi trouv Group la...',
    no_groups_found: 'Pane Trouv oken Groups...',
    return_feed: 'Retourne lor ou Feed',
    follow_this_group: 'Ou ene membre sa group la.',
    members: 'Membres',
    edit_name: 'Modifier nom la',
    edit_description: 'Modifier description la',
    change_logo: 'Sanz group la so icon',
    change_cover: 'Sanz group la so cover',
    change_privacy: 'Update Group la so Privacy',
    invite_people: 'Invite bann Dimounes',
    accept_request: 'Accepter Reket la',
    decline_request: 'Dekline Reket la',
    leave: 'Kiter',
    join: 'Rentrer',
    no_posts: 'Pencore poster dans sa group la...',
    posts: 'Posts',
    no_members: 'Pencore ena membres dans sa Group la.',
    remove: 'Tir li depi Group la',
    ban: 'Ban li depi Group la',
    promote: 'Fr li vinn ene Admin',
    invite: 'Inviter',
    name_update_success: 'Group la so Nom ine update!',
    description_update_success: 'Group la so Description ine update!',
    icon_update_success: 'Group la so Icon ine update!',
    cover_update_success: 'Group la so Cover ine update!',
    privacy_update_success: 'Group la so Privacy ine update!',
    invite_accept: 'Reket la ine aksepter',
    invite_reject: 'Reket la ine rezecter',
    joined_group: 'Oune Join Group la!',
    left_group: 'Oune Kit Group la',
    invite_update_success: 'Bann dimounes la ine inviter!',
    member_promote: 'Dimoune la ine vinn ene Admin!',
    member_ban: 'Dimoune la ine bannii!',
    member_remove: 'Dimoune la ine mett dehors!',
    mute: 'Mute bann Notifications depi sa Group la',
    unmute: 'Unmute bann Notifications depi sa Group la',
    muted_success: 'Bann Notifications depi sa Group la ine Mute!',
    unmuted_success: 'Banne Notifications depi sa Group la ine Unmute!',
    create_post: 'Cre ene Nouvo Post',
    sure_leave: 'Ou sire ou envi kit sa Group la?',
    yes: 'Oui',
    no: 'Non',
    group_name_placeholder: 'Ekrir ene nom...',
    group_description_placeholder: 'Ekrir ene description...',
  },

  saved_posts: {
    saved_posts: 'Collections',
    no_saved_posts: 'Pena oken Posts oune Save',
    create_collection: 'Cre ene collection',
    edit_collection: 'Update collection la',
    delete_collection: 'Ou sire ou envi delete sa collection la?',
    collection_name: 'Nom',
    collection_name_placeholder: 'Ekrir ene nom...',
    submit: 'Soumet',
    default_collection: 'Defaut',
    submitting: 'P Soumet...',
    create_collection_success: 'Collection la ine cre!',
    update_collection_success: 'Collection la ine update!',
    delete_collection_success: 'Collection la ine delete!',
    no_collections: 'Pencore cre oken collections...',
    posts: 'posts',
    yes: 'Oui',
    no: 'Non',
    back_collections: 'Retourne lor bann Collections',
  },

  games: {
    games: 'Zwes',
    ttt_next: 'Prosain Zoueur',
    ttt_winner: 'Gagnant',
    restart: 'Rekoumancer',
  },

  rooms: {
    rooms: 'Conversations',
    create_room: 'Cre ene Conversation',
    name: 'Nom Conversation la',
    name_placeholder: 'Ekrir ene nom...',
    description: 'Description Conversation la',
    description_placeholder: 'Ekrir ene description...',
    privacy: 'Soizir ene Privacy',
    public: 'Publik',
    private: 'Priver',
    select_members: 'Soizir bann Membres',
    create: 'Cre',
    update: 'Update',
    create_room_success: 'Conversation la ine cre',
    update_room_success: 'Conversation la ine update',
    submit: 'Soumet',
    submitting: 'P soumet...',
    file_too_big: 'File la trop gros (> 10 MB)',
    uploading: 'P upload...',
    image_success: 'Zimaz la ine resi upload',
    room_icon: 'Soizir ene icon (optionel)',
    loading: 'P load...',
    room_not_found: 'Pane resi trouv Conversation la...',
    no_rooms_found: 'Pane trouv oken Conversations...',
    return_feed: 'Retourne lor ou Feed',
    member_room: 'Ou ene membre sa conversation la.',
    members: 'Membres',
    edit_name: 'Modifier nom la',
    edit_description: 'Modifier description la',
    change_logo: 'Sanz conversation la so icon',
    change_privacy: 'Update Conversation la so Privacy',
    invite_people: 'Invite bann Dimounes',
    accept_request: 'Accepter Reket la',
    decline_request: 'Dekline Reket la',
    leave: 'Kiter',
    join: 'Rentrer',
    no_messages: 'Pencore ena oken messages dans sa conversation la...',
    no_messages_thread: 'Pencore ena oken messages dans sa thread la...',
    messages: 'Messages',
    no_members: 'Pencore ena membres dans sa Group la.',
    remove: 'Tir li depi Group la',
    ban: 'Ban li depi Group la',
    promote: 'Fr li vinn ene Admin',
    invite: 'Inviter',
    name_update_success: 'Group la so Nom ine update!',
    description_update_success: 'Group la so Description ine update!',
    icon_update_success: 'Group la so Icon ine update!',
    privacy_update_success: 'Group la so Privacy ine update!',
    invite_accept: 'Reket la ine aksepter',
    invite_reject: 'Reket la ine rezecter',
    joined_group: 'Oune Join Group la!',
    left_group: 'Oune Kit Group la',
    invite_update_success: 'Bann dimounes la ine inviter!',
    member_promote: 'Dimoune la ine vinn ene Admin!',
    member_ban: 'Dimoune la ine bannii!',
    member_remove: 'Dimoune la ine mett dehors!',
    mute: 'Mute bann Notifications depi sa Group la',
    unmute: 'Unmute bann Notifications depi sa Group la',
    muted_success: 'Bann Notifications depi sa Group la ine Mute!',
    unmuted_success: 'Banne Notifications depi sa Group la ine Unmute!',
    enter_message: 'Ekrir ene Mesaz...',
    messages_loading: 'P load bann Mesazs...',
    edit_message: 'Edit Mesaz la',
    delete_message: 'Delete Mesaz la',
    reply_message: 'Reply Mesaz la',
    views: 'Bann Views',
    edit_placeholder: 'Ekrir ene Mesaz update...',
    edited: 'modifier',
    sure_delete: 'Ou sire ou envi delete sa mesaz la?',
    yes: 'Oui',
    no: 'Non',
    deleted: 'Ine Delete',
    replying_to: 'P reponn',
    thread: 'Thread',
    open_thread: 'Ouver Thread la',
    sure_leave: 'Ou sire ou envi kit sa Conversation la?',
    room_name_placeholder: 'Ekrir ene nom...',
    room_description_placeholder: 'Ekrir ene description...',
    load_more: 'Load Plis Mesaz...',
    is_typing: 'p type...',
    has_new_messages: 'Ena bann nouvo mesaz',
  },

  avatar: {
    maker_title: 'Cre Avatar',
    generate: 'Zenerer',
    top: 'Top',
    hat_color: 'Couleur sapo',
    hair_color: 'Couleur sever',
    accessory: 'Akseswar',
    accessory_color: 'Couler Akseswar',
    facial_hair: 'Poils',
    facial_hair_color: 'Couler Poils',
    clothes: 'Linz',
    clothes_color: 'Couler Linz',
    eyes: 'Lizie',
    eyebrow: 'Sourcil',
    mouth: 'Labous',
    skin: 'Lapo',
    none: 'Nannier',
    random: 'Random',
    save: 'Save',
    reset: 'Reset',
  },

  admin: {
    settings: 'Settings',
    preferences: 'Preferans',
    choose_language: 'Soizir Langaz',
    english: 'Angler',
    french: 'Francer',
    creole: 'Kreol',
    language_success: 'Langaz la ine update!',
    last_updated: 'Ine modifier dernier fois, ',
    posts: 'Ou bann posts',
    create_post: 'Cre ene Nouvo Post',
    edit_post: 'Edit Post',
    delete_post: 'Delete Post',
    sure_delete: 'Ou sire ou envi delete sa post la?',
    yes: 'Oui',
    no: 'Non',
    deleting: 'P delete...',
    deleted_success: 'Post la ine delete!',
    publish: 'Piblier Post',
    unpublish: 'Depiblier Post',
    publish_success: 'Post la ine Publish!',
    unpublish_success: 'Post la ine Unpublish!',
  },

  view: {
    enter_comment: 'Ekrir ene comment...',
    edit_comment: 'Edit comment la',
    reply_comment: 'Reply comment la',
    update_comment: 'Ekrir ene nouvo comment...',
    delete_comment: 'Delete comment la',
    sure_delete: 'Ou sire ou envi delete sa comment la?',
    submit: 'Soumet',
    posted_on: 'Ine post',
    last_modified: 'Ine edit dernier fois',
    edited: '(ine edit)',
    yes: 'Oui',
    no: 'Non',
    submitting: 'P soumet...',
    deleting: 'P delete...',
    comment_add_success: 'Comment la ine post!',
    comment_update_success: 'Comment la ine update!',
    comment_delete_success: 'Comment la ine delete!',
    loading: 'P load...',
    post_not_found: 'Pane resi trouve sa post la.',
    return_feed: 'Retourne lor ou Feed',
    comments: 'Bann Comments',
    show_comments: 'Montrer bann Comments',
    hide_comments: 'Kasiet bann Comments',
    no_comments: 'Pencore ena Comments...',
    views: 'Vues',
    likes: 'Likes',
    like: 'Like',
    unlike: 'Unlike',
    remove_from: 'Tirer dpi',
    add_to: 'Azouter a',
    favorites: 'ou bann Favoris',
    reply: 'Reponn',
    edit: 'Modifie',
    delete: 'Delete',
    share: 'Partazer',
    link_copied: 'Link la ine Copier!',
  },
};

export default terms;
