const findProfile = (uid, profiles) => {
  let found = profiles.find((p) => p.uid === uid || p.uid === uid);

  if (!found || !found.username) {
    found = {
      uid: '',
      profileID: '',
      username: '?????????',
      name: '?????????',
      profile_pic: '',
      avatar: {},
    };
  }

  return found;
};

export { findProfile };
