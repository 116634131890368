import React, { useState } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import LoginWrapper from './wrappers/LoginWrapper.jsx';
import NotificationProcessor from './wrappers/NotificationProcessor.jsx';

import Homepage from './pages/Homepage.jsx';
import Login from './pages/Login.jsx';
import Create from './pages/Create.jsx';
import Profile from './pages/Profile.jsx';
import Clear from './pages/Clear.jsx';
import Search from './pages/Search.jsx';
import Admin from './pages/Admin.jsx';
import View from './pages/View.jsx';

import './assets/css/blinker.css';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [executed, setExecuted] = useState(false);

  return (
    <div className="w-full">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
            >
              <NotificationProcessor>
                <Homepage />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route exact path="/login" element={<Login />} />

        <Route
          exact
          path="/p/create"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
            >
              <NotificationProcessor>
                <Create />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route
          exact
          path="/p/edit/:blogID"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
            >
              <NotificationProcessor>
                <Create />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route
          exact
          path="/u/:profileID"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
              noredirect
            >
              <NotificationProcessor>
                <Profile />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route
          exact
          path="/search"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
              noredirect
            >
              <NotificationProcessor>
                <Search />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route
          exact
          path="/search/:searchString"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
              noredirect
            >
              <NotificationProcessor>
                <Search />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route
          exact
          path="/admin"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
            >
              <NotificationProcessor>
                <Admin />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route
          exact
          path="/v/:blogID"
          element={
            <LoginWrapper
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              executed={executed}
              setExecuted={setExecuted}
              noredirect
            >
              <NotificationProcessor>
                <View />
              </NotificationProcessor>
            </LoginWrapper>
          }
        />

        <Route exact path="/clear" element={<Clear />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
