import create from 'zustand';
import { combine } from 'zustand/middleware';

export const useLinkStore = create(
  combine(
    {
      links: [],
    },
    (set) => ({
      setLinks: (value) => set({ links: value }),

      addLink: (value) => set((state) => ({ links: [...state.links, value] })),

      removeLink: (linkID) =>
        set((state) => ({
          links: [...state.links.filter((l) => l.linkID !== linkID)],
        })),
    })
  )
);
