import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { usePostStore } from '../stores/usePostStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useProfileStore } from '../stores/useProfileStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';

import useLanguage from '../wrappers/LanguageWrapper';
import { convert } from '../utils/timestamp';

import { LocalContext } from '../wrappers/LocalContext';

import tmpAvatar from '../assets/images/avatar.png';

import { automaticLogin } from '../utils/fetcher';

import Navbar from '../components/Navbar';

export default function Search() {
  const { posts } = usePostStore((state) => state);
  const { profile } = useUserProfileStore((state) => state);
  const { profiles } = useProfileStore((state) => state);
  const { theme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);

  const { API_URL, search, setSearch } = useContext(LocalContext);
  const terms = useLanguage(language).search;
  const navigate = useNavigate();

  const { searchString } = useParams();

  const convertedSearchString = () => search.split(' ').join('+').toLowerCase();

  const sortDate = (posts) => {
    let updatedPosts = [...posts];
    return updatedPosts
      .sort((a, b) => new Date(b.modified_on) - new Date(a.modified_on))
      .reverse();
  };

  const filteredPosts = posts
    ? !convertedSearchString() || convertedSearchString().trim().length < 1
      ? sortDate(posts)
      : sortDate(
          posts.filter(
            (p) =>
              p.title
                .split(' ')
                .join('+')
                .toLowerCase()
                .includes(convertedSearchString()) ||
              p.subtitle
                .split(' ')
                .join('+')
                .toLowerCase()
                .includes(convertedSearchString()) ||
              p.content
                .split(' ')
                .join('+')
                .toLowerCase()
                .includes(convertedSearchString()) ||
              p.tags.join('+').toLowerCase().includes(convertedSearchString())
          )
        )
    : [];

  const filteredProfiles = profiles
    ? !convertedSearchString() || convertedSearchString().trim().length < 1
      ? profiles
      : profiles.filter(
          (p) =>
            p.username
              .split(' ')
              .join('+')
              .toLowerCase()
              .includes(convertedSearchString()) ||
            p.name
              .split(' ')
              .join('+')
              .toLowerCase()
              .includes(convertedSearchString())
        )
    : [];

  useEffect(() => {
    const fetchData = async () => {
      if (!profile || !profile.profileID) {
        await automaticLogin(API_URL, '', '');
      }
    };

    if (searchString) {
      setSearch(searchString.split('+').join(' '));
    }

    fetchData();

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`w-full ${
        theme === 'light' ? 'bg-main-100' : 'bg-main-900'
      } ease-in-out duration-400 lg:pb-0 pb-20 flex justify-center`}
    >
      <Navbar />

      <div className="w-full lg:w-4/5 px-4 p-2 pt-20 lg:pt-24">
        <div className="p-2 rounded-lg border-4 border-main-300 mb-4 -mx-1">
          <input
            className={`outline-none w-full text-sm lg:text-sm p-2 font-gilroy placeholder-opacity-75 ${
              theme === 'light'
                ? 'bg-gray-200 text-main-900 placeholder-main-900'
                : 'bg-gray-800 text-main-100 placeholder-main-100'
            } transition duration-400 ease-in-out`}
            placeholder={terms.placeholder}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate(
                  `/search/${search ? search.trim().split(' ').join('+') : ''}`
                );
              }
            }}
          />
        </div>

        <div
          className={`${
            theme === 'light' ? 'text-main-400' : 'text-main-200'
          } w-full text-xl lg:text-2xl font-semibold font-spartan text-left mt-2`}
        >
          {terms.posts} ({filteredPosts && filteredPosts.length})
        </div>

        <div className="mb-4 lg:pb-2 w-full flex overflow-x-scroll py-2">
          {filteredPosts &&
            filteredPosts.map((post, i) => {
              const author = profiles.find((p) => p.uid === post.uid);

              return (
                <div
                  className={`transition-all flex-shrink-0 flex-grow-0 sm:w-120 w-92 ease-in-out duration-400
              hover:border-main-300 bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500 focus:from-green-400 ${
                i < filteredPosts.length - 1 ? 'mr-2' : 'mr-0'
              } mr-2 focus:to-blue-50 border-4 ${
                    theme === 'light' ? 'border-gray-800' : 'border-gray-200'
                  } rounded-lg sm:h-72 h-40 p-2 flex flex-col justify-end`}
                  key={post.blogID}
                  style={
                    post.preview_img && post.preview_img.length > 8
                      ? {
                          color: '#4a5568',
                          background: `url(${post.preview_img}) center / cover no-repeat #4a5568`,
                        }
                      : {}
                  }
                >
                  <button
                    className="w-full outline-none"
                    onClick={() => navigate(`/v/${post.blogID}`)}
                  >
                    <div
                      className={`text-base lg:text-lg tracking-wide text-left text-main-200 font-spartan font-semibold bg-gray-900 bg-opacity-75 rounded py-1 px-2`}
                    >
                      {post.title}
                    </div>

                    <div className="text-sm lg:text-base tracking-wide font-normal mt-1 text-main-300 bg-opacity-75 bg-gray-900 rounded px-2 text-left">
                      {post.subtitle}
                    </div>
                  </button>

                  <div className="text-xxs lg:text-xs tracking-wide font-normal mt-1 text-gray-200 bg-gray-900 opacity-75 rounded px-2 w-full text-left font-sans">
                    {terms.last_updated}
                    <button
                      className="text-yellow-300 hover:underline focus:underline"
                      onClick={() => navigate(`/u/${author.uid}`)}
                    >
                      {author && author.username
                        ? author.username.length > 20
                          ? author.username.substring(0, 20)
                          : author.username
                        : ''}
                    </button>
                    {', '}
                    <span className="text-green-300">
                      {convert(post.updated_on, language, false)}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>

        <div
          className={`${
            theme === 'light' ? 'text-main-400' : 'text-main-200'
          } w-full text-xl lg:text-2xl font-semibold font-spartan text-left mt-2`}
        >
          {terms.people} ({filteredProfiles && filteredProfiles.length})
        </div>

        <div className="mb-4 lg:pb-2 w-full flex overflow-x-scroll py-2">
          {filteredProfiles &&
            filteredProfiles.map((p, i) => (
              <button
                className={`outline-none transition-all flex-shrink-0 flex-grow-0 sm:w-100 w-80 ease-in-out duration-400
              hover:border-main-300 bg-gradient-to-r from-main-300 to-main-200 ${
                i < filteredPosts.length - 1 ? 'mr-2' : 'mr-0'
              } mr-2 focus:to-blue-50 border-4 ${
                  theme === 'light' ? 'border-gray-800' : 'border-gray-200'
                } rounded-lg lg:h-80 h-44 p-2 flex flex-col justify-end`}
                key={p.uid}
                style={
                  p.banner_img && p.banner_img.length > 8
                    ? {
                        color: '#4a5568',
                        background: `url("${p.banner_img}") center / cover no-repeat #4a5568`,
                      }
                    : {}
                }
                onClick={() => navigate(`/u/${p.uid}`)}
              >
                <div className="w-full h-full flex justify-center items-center sm:mt-0 mt-2">
                  <img
                    src={
                      p.profile_pic && p.profile_pic.length > 3
                        ? p.profile_pic
                        : tmpAvatar
                    }
                    alt="p.pic"
                    className={`h-20 w-20 sm:h-36 sm:w-36 lg:h-40 lg:w-40 border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                      theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                    } bg-opacity-50 ease-in-out duration-400 lg:mb-0 mb-2`}
                  />
                </div>

                <div
                  className={`w-full text-base lg:text-lg tracking-wide text-left text-main-200 font-spartan font-semibold bg-gray-900 bg-opacity-75 rounded py-1 px-2`}
                >
                  {p.name}
                </div>

                <div className="w-full py-1 text-sm lg:text-base tracking-wide font-normal mt-1 text-main-300 bg-opacity-75 bg-gray-900 rounded px-2 text-left">
                  {p.username}
                </div>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}
