import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAlert } from 'react-alert';
import axios from 'axios';
import Slider from 'react-slick';

import { LocalContext } from '../wrappers/LocalContext';

import Parser from '../processors/markdownEngine';
import Navbar from '../components/Navbar';

import useLanguage from '../wrappers/LanguageWrapper';

import { usePostStore } from '../stores/usePostStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useProfileStore } from '../stores/useProfileStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';

import { convert, generate } from '../utils/timestamp';
import { automaticLogin } from '../utils/fetcher';

import tmpAvatar from '../assets/images/avatar.png';
import IncludeDeleteComment from './includes/IncludeDeleteComment';
import IncludeEditComment from './includes/IncludeEditComment';
import IncludeReplyComment from './includes/IncludeReplyComment';

export default function View() {
  const [isLoading, setIsLoading] = useState(false);
  const [didOnce, setDidOnce] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [hideComments, setHideComments] = useState(false);

  const [comment, setComment] = useState('');
  const [editingComment, setEditingComment] = useState(null);
  const [replyingComment, setReplyingComment] = useState(null);
  const [replyingUid, setReplyingUid] = useState('');
  const [deletingComment, setDeletingComment] = useState(null);

  const {
    posts,
    setPosts,
    addComment,
    editComment,
    reactPost,
    deleteComment,
    reactComment,
  } = usePostStore((state) => state);
  const { profile, savePost } = useUserProfileStore((state) => state);
  const { profiles } = useProfileStore((state) => state);
  const { theme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);

  const { blogID } = useParams();
  const progressRef = useRef();

  const [width, setWidth] = useState(0);

  const handleScroll = () => {
    if (!progressRef || !progressRef.current) {
      return;
    }

    let bottom =
      progressRef && progressRef.current
        ? progressRef.current.getBoundingClientRect().bottom
        : 0;

    // console.log(width, bottom, document.body.scrollHeight);

    const percentage =
      100 -
      Math.min(
        Math.max(
          ((bottom - document.body.scrollHeight) / document.body.scrollHeight) *
            100,
          0
        ),
        100
      );
    setWidth(percentage);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleScroll();
    }, 25);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  let author =
    currentPost && currentPost.uid && profiles
      ? profiles.find((p) => p.uid === currentPost.uid)
      : null;

  const makeProcessedComments = (comments) => {
    let updatedComments = [];

    for (let i = 0; i < comments.length; i++) {
      if (!comments[i].reply_to || comments[i].reply_to.length <= 0) {
        updatedComments.push({ ...comments[i], replies: [] });
      }
    }

    for (let i = 0; i < comments.length; i++) {
      if (comments[i].reply_to) {
        for (let j = 0; j < updatedComments.length; j++) {
          if (updatedComments[j].commentID === comments[i].reply_to) {
            updatedComments[j].replies.push(comments[i]);
            break;
          }
        }
      }
    }

    return updatedComments;
  };

  let processedComments =
    currentPost && currentPost.comments && currentPost.comments.length > 0
      ? makeProcessedComments(currentPost.comments)
      : [];

  const terms = useLanguage(language).view;

  const alert = useAlert();
  const navigate = useNavigate();

  const { API_URL } = useContext(LocalContext);

  useEffect(() => {
    const fetchData = async () => {
      if (!profile || !profile.profileID) {
        await automaticLogin(API_URL, '', '');
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setEditingComment(null);
      setReplyingComment(null);
      setReplyingUid('');
      setDeletingComment(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => setIsLoading(false), 4000);

    setCurrentPost(null);
    setIsLoading(true);
    setDidOnce(false);
    setHideComments(false);
    setEditingComment(null);
    setDeletingComment(null);

    return () => {
      clearTimeout(timer);
    };
  }, [blogID]);

  if (!didOnce) {
    if (!blogID) {
      navigate('/');
    }

    let foundPost = posts.find((p) => p.blogID === blogID);

    setDidOnce(true);

    const setPost = () => {
      setCurrentPost(foundPost);
    };

    if (!foundPost || !foundPost.uid || foundPost.uid !== profile.uid) {
      axios
        .post(
          `${API_URL}/blog/post/fetch/one`,
          { uid: profile.uid, blogID },
          {
            headers: { Authorization: `Bearer ${profile.jwt}` },
          }
        )
        .then((response) => {
          if (response.data.error === 0) {
            foundPost = response.data.post;

            let currentPosts = [...posts];
            let currentPostIDs = currentPosts.map((p) => p.postID);

            if (!currentPostIDs.includes(foundPost.postID)) {
              currentPosts.push(foundPost);
            }

            axios.post(
              `${API_URL}/blog/post/view`,
              { uid: profile.uid, blogID },
              {
                headers: { Authorization: `Bearer ${profile.jwt}` },
              }
            );

            setPosts(currentPosts);

            setPost();
          }
        });
    } else {
      setPost();
    }
  }

  const submitLikePost = (like) => {
    const data = {
      uid: profile.uid,
      blogID: currentPost ? currentPost.blogID : blogID,
      like,
    };

    axios
      .post(
        `${API_URL}/blog/post/react`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          setCurrentPost((prev) => {
            let updatedPost = { ...prev };
            updatedPost.likes = like
              ? [...updatedPost.likes, profile.uid]
              : updatedPost.likes.filter((l) => l !== profile.uid);
            return updatedPost;
          });

          reactPost(currentPost.blogID, like, profile.uid);
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const submitFavoritePost = (favorite) => {
    const data = {
      uid: profile.uid,
      blogID: currentPost ? currentPost.blogID : blogID,
      favorite,
    };

    axios
      .post(
        `${API_URL}/blog/profile/favorite`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          savePost(currentPost.blogID, favorite);
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const submitAddComment = () => {
    alert.info(terms.submitting);

    const data = {
      uid: profile.uid,
      blogID: currentPost ? currentPost.blogID : blogID,
      commentID: editingComment ? editingComment : '',
      comment: comment,
      reply_to: replyingComment ? replyingComment : '',
    };

    setComment('');
    setReplyingComment(null);
    setReplyingUid('');
    setEditingComment(null);

    axios
      .post(
        `${API_URL}/blog/post/comment/${data.commentID ? 'update' : 'add'}`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          if (data.commentID) {
            editComment(data.blogID, data.commentID, data.comment);
            setCurrentPost((post) => {
              let updatedPost = { ...post };

              updatedPost.comments = updatedPost.comments.map((c) => {
                let updatedComment = { ...c };

                if (updatedComment.commentID === data.commentID) {
                  updatedComment.comment = data.comment;
                }

                return updatedComment;
              });

              return updatedPost;
            });
          } else {
            addComment(
              data.uid,
              data.blogID,
              data.comment,
              data.reply_to,
              res.data.commentID
            );
            setCurrentPost((post) => {
              let updatedPost = { ...post };
              updatedPost.comments = [
                ...updatedPost.comments,
                {
                  uid: data.uid,
                  commentID: res.data.commentID,
                  comment: data.comment,
                  posted_on: generate(),
                  modified_on: generate(),
                  reply_to: data.reply_to,
                  reacts: [],
                },
              ];
              return updatedPost;
            });
          }

          alert.success(
            data.commentID
              ? terms.comment_update_success
              : terms.comment_add_success
          );
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const submitLikeComment = (commentID, react) => {
    const data = {
      uid: profile.uid,
      blogID: currentPost ? currentPost.blogID : blogID,
      commentID: commentID,
      like: react,
    };

    axios
      .post(
        `${API_URL}/blog/post/comment/react`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          reactComment(data.blogID, data.commentID, react, data.uid);

          setCurrentPost((post) => {
            let updatedPost = { ...post };

            updatedPost.comments = updatedPost.comments.map((c) => {
              let updatedComment = { ...c };

              if (updatedComment.commentID === data.commentID) {
                updatedComment.reacts = data.like
                  ? [...updatedComment.reacts, data.uid]
                  : updatedComment.reacts.filter((r) => r !== data.uid);
              }

              return updatedComment;
            });

            return updatedPost;
          });
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const submitDeleteComment = () => {
    alert.info(terms.deleting);

    const data = {
      uid: profile.uid,
      blogID: currentPost ? currentPost.blogID : blogID,
      commentID: deletingComment,
    };

    setDeletingComment(null);

    axios
      .post(
        `${API_URL}/blog/post/comment/delete`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          deleteComment(data.blogID, data.commentID);

          setCurrentPost((post) => {
            let updatedPost = { ...post };
            updatedPost.comments = updatedPost.comments.filter(
              (c) =>
                c.commentID !== data.commentID && c.reply_to !== data.commentID
            );
            return updatedPost;
          });

          alert.success(terms.comment_delete_success);
        } else {
          alert.error(res.data.message);
        }
      });
  };

  const settings = {
    customPaging: function (i) {
      return (
        <div
          className={`w-6 h-6 border-2 border-transparent focus:border-main-300 hover:border-main-300 ${
            theme === 'light'
              ? 'bg-gray-100 text-gray-900'
              : 'bg-gray-900 text-gray-100'
          } p-2 rounded-full flex items-center justify-center font-spartan text-xs pb-2 ease-in-out duration-400`}
        >
          {i + 1}
        </div>
      );
    },
    useTransform: true,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 3000,
    cssEase: 'ease-out',
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const skeleton = (
    <div className="w-full flex flex-col items-center">
      <div
        className={`w-full ${
          theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
        } ease-in-out duration-400 rounded-lg p-2 flex lg:flex-row flex-col`}
      >
        <div className="w-full lg:flex flex-col lg:h-24 lg:justify-between rounded-lg animate-pulse">
          <div className="text-main-300 w-full text-3xl sm:text-4xl font-semibold font-gilroy lg:mt-2 rounded-lg animate-pulse"></div>
          <div
            className={`text-main-200 opacity-75 w-full text-base sm:text-xl font-spartan ${
              theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
            } rounded-lg animate-pulse ease-in-out duration-400 p-4 mt-1`}
          ></div>
        </div>

        <button
          className={`flex w-full lg:w-1/2 border-2 border-transparent hover:border-main-300 focus:border-main-300 ${
            theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
          } ease-in-out duration-400 p-2 lg:ml-2 lg:mt-0 mt-2 rounded-lg animate-pulse`}
        >
          <div className="h-12 w-12 sm:h-20 sm:w-20 flex-shrink-0 flex-grow-0">
            <div
              className={`w-full h-full border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
              } bg-opacity-50 ease-in-out duration-400 rounded-lg animate-pulse`}
            />
          </div>
          <div className="w-full ml-4">
            <div className="text-main-300 w-full text-base sm:text-lg text-left font-gilroy"></div>

            <div className="w-full rounded-full pt-1 opacity-75 bg-main-200 mb-2 mt-1" />

            <div
              className={`${
                theme === 'light' ? 'text-gray-900' : 'text-gray-100'
              } w-full text-xxs sm:text-xs text-left font-spartan`}
            ></div>

            <div
              className={`${
                theme === 'light' ? 'text-gray-900' : 'text-gray-100'
              } w-full text-xxs sm:text-xs text-left font-spartan`}
            ></div>
          </div>
        </button>
      </div>

      <div
        className={`w-full animate-pulse h-150 flex items-center justify-center mt-2 ${
          theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
        } ease-in-out duration-400 rounded-lg p-2 flex flex-col mb-2`}
      >
        <div className="w-full rounded-lg sm:text-xl text-base text-gray-300 p-2 flex items-center justify-center font-spartan blink">
          {terms.loading}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`w-full ${
        theme === 'light' ? 'bg-main-100' : 'bg-main-900'
      } ease-in-out duration-400 lg:pb-0 pb-20`}
    >
      <Navbar width={width} />
      <div className={`w-full p-2 lg:px-56 pt-20 lg:pt-24`}>
        {currentPost && (
          <div className="w-full">
            <div
              className={`${
                theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
              } ease-in-out duration-400 rounded-lg p-2 flex lg:flex-row flex-col`}
            >
              <div className="w-full lg:flex flex-col lg:h-24 lg:justify-between">
                <div className="text-main-300 w-full text-3xl sm:text-4xl font-semibold font-gilroy lg:mt-2">
                  {currentPost.title}
                </div>

                <div
                  className={`text-main-200 opacity-75 w-full text-base sm:text-xl font-spartan ${
                    theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                  } ease-in-out duration-400 rounded-lg p-1 mt-1`}
                >
                  {currentPost.subtitle}
                </div>
              </div>

              {author && (
                <button
                  className={`flex w-full lg:w-1/2 border-2 border-transparent hover:border-main-300 focus:border-main-300 ${
                    theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                  } ease-in-out duration-400 rounded-lg p-2 lg:ml-2 lg:mt-0 mt-2`}
                  onClick={() => navigate(`/u/${author.uid}`)}
                >
                  <div className="h-12 w-12 sm:h-20 sm:w-20 flex-shrink-0 flex-grow-0">
                    <img
                      src={
                        author.profile_pic.length > 3
                          ? author.profile_pic
                          : tmpAvatar
                      }
                      alt="p.pic"
                      className={`w-full h-full border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                        theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                      } bg-opacity-50 ease-in-out duration-400`}
                    />
                  </div>
                  <div className="w-full ml-4">
                    <div className="text-main-300 w-full text-base sm:text-lg text-left font-gilroy">
                      {author.name}{' '}
                      <span className="text-main-200">({author.username})</span>
                    </div>

                    <div className="w-full rounded-full pt-1 opacity-75 bg-main-200 mb-2 mt-1" />

                    <div
                      className={`${
                        theme === 'light' ? 'text-gray-900' : 'text-gray-100'
                      } w-full text-xxs sm:text-xs text-left font-spartan`}
                    >
                      {terms.posted_on}{' '}
                      {convert(currentPost.created_on, language, true)}
                    </div>

                    <div
                      className={`${
                        theme === 'light' ? 'text-gray-900' : 'text-gray-100'
                      } w-full text-xxs sm:text-xs text-left font-spartan`}
                    >
                      {terms.last_modified}{' '}
                      {convert(currentPost.updated_on, language, true)}
                    </div>
                  </div>
                </button>
              )}
            </div>

            <div
              className={`${
                theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
              } ease-in-out duration-400 rounded-lg p-2 flex flex-col my-2`}
            >
              {currentPost.carousel && currentPost.carousel.length > 0 && (
                <div className="w-full flex justify-center">
                  <div className="lg:w-4/5 w-5/6 mb-2">
                    <Slider {...settings}>
                      {currentPost.carousel.map((im, i) => (
                        <img
                          src={im}
                          alt={`slider-${i}`}
                          key={`slider-${i}`}
                          className="sm:h-100 h-60 object-scale-down mb-2"
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              )}

              <div
                className={`w-full ${
                  currentPost.carousel && currentPost.carousel.length > 0
                    ? 'sm:mt-8'
                    : ''
                } mt-2`}
                ref={progressRef}
              >
                <Parser theme={theme}>{currentPost.content}</Parser>
              </div>
            </div>
          </div>
        )}

        {!currentPost && blogID && (
          <div className="w-full">
            {!currentPost &&
              (isLoading ? (
                skeleton
              ) : (
                <>
                  <div className="text-main-600 w-full flex items-center justify-start mt-4 text-left text-xl lg:text-2xl font-semibold font-spartan">
                    {terms.post_not_found}
                  </div>

                  <Link
                    to="/"
                    className={`text-center text-lg p-2 w-full lg:w-1/4 border-2 border-transparent flex justify-center items-center ${
                      theme === 'light'
                        ? 'bg-gray-300 text-gray-800'
                        : 'bg-gray-800 text-gray-200'
                    } hover:border-main-300 focus:border-main-300 font-gilroy rounded-lg mt-4 ease-in-out duration-400`}
                  >
                    <div className="h-full">{terms.return_feed}</div>
                  </Link>
                </>
              ))}
          </div>
        )}

        {currentPost && (
          <div className="w-full">
            <div className="w-full rounded-full pt-1 opacity-75 bg-gray-500 mt-2" />

            <div className="w-full flex flex-col items-center">
              <div className="w-full flex overflow-x-scroll pb-2 mt-2">
                <div
                  className={`p-1 font-mono rounded-full w-8 text-center h-8 lg:text-xs text-xxs bg-main-300 mr-2 px-2 ease-in-out duration-400 text-gray-900 flex items-center justify-center`}
                  title={terms.views}
                >
                  {currentPost.views.length}
                </div>

                <div
                  className={`p-1 font-mono rounded-full w-8 text-center h-8 lg:text-xs text-xxs bg-main-400 mr-2 px-2 ease-in-out duration-400 text-gray-900 flex items-center justify-center`}
                  title={terms.likes}
                >
                  {currentPost.likes.length}
                </div>

                <div
                  className={`p-1 font-mono rounded-full w-8 text-center h-8 lg:text-xs text-xxs bg-yellow-300 mr-2 px-2 ease-in-out duration-400 text-gray-900 flex items-center justify-center`}
                  title={terms.comments}
                >
                  {currentPost.comments.length}
                </div>

                {currentPost &&
                  currentPost.tags.length > 0 &&
                  currentPost.tags.map((t) => (
                    <button
                      key={`tag-${t}`}
                      className={`p-1 rounded-lg lg:text-base text-sm font-spartan ${
                        theme === 'light'
                          ? 'bg-gray-200 text-gray-800'
                          : 'bg-gray-800 text-gray-200'
                      } mr-2 px-2 ease-in-out duration-400`}
                      onClick={() => navigate(`/search/${t}`)}
                    >
                      {t}
                    </button>
                  ))}
              </div>
            </div>

            {profile && profile.uid && (
              <div
                className={`mt-2 my-4 w-full flex lg:flex-row items-center ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                } ease-in-out duration-400 bg-opacity-75 p-2 rounded-lg`}
              >
                <button
                  className={`p-4 w-full lg:w-1/3 justify-center font-gilroy tracking-wide text-blue-400 text-opacity-75 flex items-center ${
                    theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                  } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                ri-thumb-up-${
                  currentPost.likes.includes(profile.uid) ? 'fill' : 'line'
                } hover:border-blue-400 focus:border-blue-400 text-lg lg:text-xl`}
                  title={
                    currentPost.likes.includes(profile.uid)
                      ? terms.unlike
                      : terms.like
                  }
                  onClick={() =>
                    submitLikePost(!currentPost.likes.includes(profile.uid))
                  }
                ></button>

                <button
                  className={`p-4 mx-2 w-full lg:w-1/3 justify-center font-gilroy tracking-wide text-main-600 text-opacity-75 flex items-center ${
                    theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                  } border-2 border-transparent rounded-lg outline-none hover:border-main-600 focus:border-main-600 ease-in-out duration-400
                ri-heart-3-${
                  profile.favorites.includes(currentPost.blogID)
                    ? 'fill'
                    : 'line'
                } text-lg lg:text-xl`}
                  title={`
                    ${
                      profile.favorites.includes(currentPost.blogID)
                        ? terms.remove_from
                        : terms.add_to
                    } ${terms.favorites}
                  `}
                  onClick={() =>
                    submitFavoritePost(
                      !profile.favorites.includes(currentPost.blogID)
                    )
                  }
                ></button>

                <button
                  className={`p-4 w-full lg:w-1/3 justify-center font-gilroy tracking-wide text-main-300 text-opacity-75 flex items-center ${
                    theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                  } border-2 border-transparent rounded-lg outline-none hover:border-main-300 focus:border-main-300 ease-in-out duration-400
                ri-share-forward-line text-lg lg:text-xl`}
                  title={terms.share}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${API_URL}/blog/post/fetch/info/complete?blogID=${blogID}`
                    );
                    alert.info(terms.link_copied);
                  }}
                ></button>
              </div>
            )}

            {currentPost && (
              <div
                className={`mt-2 my-4 w-full flex flex-col items-center ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                } ease-in-out duration-400 bg-opacity-75 p-2 rounded-lg`}
              >
                <div className="w-full flex items-center">
                  <div
                    className={`text-main-200 opacity-75 lg:w-auto w-full  text-base sm:text-xl font-spartan rounded-lg p-1 mt-1`}
                  >
                    {terms.comments} ({currentPost.comments.length})
                  </div>

                  <button
                    className={`p-2 ml-2 lg:w-auto w-full justify-center font-gilroy tracking-wide text-main-300 text-opacity-75 flex items-center ${
                      theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                    } border-2 border-transparent rounded-lg outline-none hover:border-main-300 focus:border-main-300 ease-in-out duration-400 text-lg lg:text-xl`}
                    onClick={() => setHideComments((prev) => !prev)}
                  >
                    {hideComments ? terms.show_comments : terms.hide_comments}
                  </button>
                </div>

                <div className="w-full p-2 rounded-lg flex flex-col items-center mt-2 border-opacity-50">
                  <textarea
                    type="text"
                    name={terms.enter_comment}
                    title={terms.enter_comment}
                    className={`w-full outline-none rounded-lg p-2 border-2 border-opacity-50 border-main-300 ease-in-out duration-400
    text-xs sm:text-base opacity-85 hover:border-opacity-95 focus:border-opacity-95 ${
      theme === 'light'
        ? 'bg-gray-100 text-gray-900 border-gray-900 placeholder-gray-900'
        : 'bg-gray-900 text-gray-100 border-gray-100 placeholder-gray-100'
    } font-spartan min-h-20`}
                    placeholder={terms.enter_comment}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />

                  <div className="w-full flex justify-start">
                    <button
                      className={`p-2 mt-2 lg:px-20 justify-center font-gilroy tracking-wide h-full text-main-300 text-opacity-75 flex items-center lg:w-auto w-full  ${
                        theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                      } border-2 border-transparent rounded-lg outline-none ${
                        comment.length > 0
                          ? 'hover:border-main-300 focus:border-main-300'
                          : 'opacity-50'
                      } ease-in-out duration-400 text-lg lg:text-xl`}
                      onClick={() =>
                        comment.length > 0 ? submitAddComment() : null
                      }
                    >
                      {terms.submit}
                    </button>
                  </div>
                </div>

                <div className="w-full rounded-full pt-1 opacity-75 bg-gray-500 my-2" />

                {!hideComments && (
                  <div className="w-full flex flex-col">
                    {currentPost && currentPost.comments.length <= 0 && (
                      <div className="w-full text-main-600 text-center font-spartan lg:text-lg my-2 text-base">
                        {terms.no_comments}
                      </div>
                    )}

                    {processedComments &&
                      processedComments.length > 0 &&
                      processedComments.map((c) => {
                        const author = profiles.find((p) => p.uid === c.uid);

                        return (
                          <div
                            className={`flex w-full border-2 border-transparent hover:border-main-300 focus:border-main-300 ${
                              theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                            } ease-in-out duration-400 rounded-lg p-2 mb-2`}
                            key={`comm-${c.commentID}`}
                          >
                            <div className="h-12 w-12 sm:h-20 sm:w-20 flex-shrink-0 flex-grow-0">
                              <button
                                className="outline-none"
                                onClick={() => navigate(`/u/${c.uid}`)}
                              >
                                <img
                                  src={
                                    author && author.profile_pic.length > 3
                                      ? author.profile_pic
                                      : tmpAvatar
                                  }
                                  alt="p.pic-author"
                                  className={`w-full h-full border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                                    theme === 'light'
                                      ? 'bg-gray-100'
                                      : 'bg-gray-900'
                                  } bg-opacity-50 ease-in-out duration-400`}
                                />
                              </button>
                            </div>
                            <div className="w-full ml-4">
                              <button
                                className="text-main-300 w-full text-base sm:text-lg text-left font-gilroy outline-none"
                                onClick={() => navigate(`/u/${c.uid}`)}
                              >
                                {author && author.name}{' '}
                                <span className="text-main-200">
                                  ({author && author.username})
                                </span>
                              </button>

                              <div className="w-full rounded-full pt-1 opacity-75 bg-main-200 mb-2 mt-1" />

                              <div
                                className={`${
                                  theme === 'light'
                                    ? 'text-gray-900'
                                    : 'text-gray-100'
                                } w-full text-xxs sm:text-xs text-left font-spartan`}
                              >
                                {terms.posted_on}{' '}
                                {convert(c.posted_on, language, true)}{' '}
                                {new Date(c.posted_on).getSeconds() -
                                  new Date(c.modified_on).getSeconds() >
                                  3 && terms.edited}
                              </div>

                              <div
                                className={`p-1 rounded-lg ease-in-out duration-400 mt-2 ${
                                  theme === 'light'
                                    ? 'text-gray-900 bg-gray-200'
                                    : 'text-gray-100 bg-gray-800'
                                } w-full text-base sm:text-sm text-left font-spartan`}
                              >
                                <Parser theme={theme}>{c.comment}</Parser>
                              </div>

                              {profile && profile.uid && (
                                <div
                                  className={`p-1 rounded-lg mt-2 w-full flex items-center text-base sm:text-sm text-left font-spartan`}
                                >
                                  <div
                                    className={`p-1 font-mono rounded-full w-8 text-center h-8 lg:text-xs text-xxs bg-main-400 mr-2 px-2 ease-in-out duration-400 text-gray-900 flex items-center justify-center`}
                                    title={terms.likes}
                                  >
                                    {c.reacts.length}
                                  </div>

                                  <button
                                    className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-blue-400 text-opacity-75 flex items-center ${
                                      theme === 'light'
                                        ? 'bg-gray-200'
                                        : 'bg-gray-800'
                                    } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                    ri-thumb-up-${
                                      c.reacts.includes(profile.uid)
                                        ? 'fill'
                                        : 'line'
                                    } hover:border-blue-400 focus:border-blue-400 text-sm lg:text-lg`}
                                    title={
                                      c.reacts.includes(profile.uid)
                                        ? terms.unlike
                                        : terms.like
                                    }
                                    onClick={() =>
                                      submitLikeComment(
                                        c.commentID,
                                        !c.reacts.includes(profile.uid)
                                      )
                                    }
                                  ></button>

                                  {c.reply_to.length <= 0 && (
                                    <button
                                      className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-main-200 text-opacity-75 flex items-center ${
                                        theme === 'light'
                                          ? 'bg-gray-200'
                                          : 'bg-gray-800'
                                      } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                    ri-reply-line hover:border-main-200 focus:border-main-200 text-sm lg:text-lg`}
                                      title={terms.reply}
                                      onClick={() => {
                                        setReplyingUid(c.uid);
                                        setReplyingComment(c.commentID);
                                      }}
                                    ></button>
                                  )}

                                  {profile.uid === c.uid && (
                                    <button
                                      className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-yellow-400 text-opacity-75 flex items-center ${
                                        theme === 'light'
                                          ? 'bg-gray-200'
                                          : 'bg-gray-800'
                                      } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                    ri-pencil-line hover:border-yellow-400 focus:border-yellow-400 text-sm lg:text-lg`}
                                      title={terms.edit}
                                      onClick={() => {
                                        setComment(c.comment);
                                        setEditingComment(c.commentID);
                                      }}
                                    ></button>
                                  )}

                                  {profile.uid === c.uid && (
                                    <button
                                      className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-main-600 text-opacity-75 flex items-center ${
                                        theme === 'light'
                                          ? 'bg-gray-200'
                                          : 'bg-gray-800'
                                      } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                    ri-delete-bin-2-line hover:border-main-600 focus:border-main-600 text-sm lg:text-lg`}
                                      title={terms.delete}
                                      onClick={() =>
                                        setDeletingComment(c.commentID)
                                      }
                                    ></button>
                                  )}
                                </div>
                              )}

                              {c.replies.length > 0 && (
                                <div
                                  className={`w-full p-2 mt-2 rounded-lg ${
                                    theme === 'light'
                                      ? 'bg-gray-200'
                                      : 'bg-gray-800'
                                  } ease-in-out duration-400`}
                                >
                                  {c.replies.map((r) => {
                                    const author2 = profiles.find(
                                      (p) => p.uid === c.uid
                                    );

                                    return (
                                      <div
                                        className={`flex w-full border-2 border-transparent hover:border-main-300 focus:border-main-300 ${
                                          theme === 'light'
                                            ? 'bg-gray-100'
                                            : 'bg-gray-900'
                                        } ease-in-out duration-400 rounded-lg p-2 mt-2`}
                                        key={`reply-${r.commentID}`}
                                      >
                                        <div className="h-12 w-12 sm:h-20 sm:w-20 flex-shrink-0 flex-grow-0">
                                          <button
                                            className="outline-none"
                                            onClick={() =>
                                              navigate(`/u/${c.uid}`)
                                            }
                                          >
                                            <img
                                              src={
                                                author2 &&
                                                author2.profile_pic.length > 3
                                                  ? author2.profile_pic
                                                  : tmpAvatar
                                              }
                                              alt="p.pic-author"
                                              className={`w-full h-full border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                                                theme === 'light'
                                                  ? 'bg-gray-100'
                                                  : 'bg-gray-900'
                                              } bg-opacity-50 ease-in-out duration-400`}
                                            />
                                          </button>
                                        </div>
                                        <div className="w-full ml-4">
                                          <button
                                            className="text-main-300 w-full text-base sm:text-lg text-left font-gilroy outline-none"
                                            onClick={() =>
                                              navigate(`/u/${r.uid}`)
                                            }
                                          >
                                            {author2 && author2.name}{' '}
                                            <span className="text-main-200">
                                              ({author2 && author2.username})
                                            </span>
                                          </button>

                                          <div className="w-full rounded-full pt-1 opacity-75 bg-main-200 mb-2 mt-1" />

                                          <div
                                            className={`${
                                              theme === 'light'
                                                ? 'text-gray-900'
                                                : 'text-gray-100'
                                            } w-full text-xxs sm:text-xs text-left font-spartan`}
                                          >
                                            {terms.posted_on}{' '}
                                            {convert(
                                              r.posted_on,
                                              language,
                                              true
                                            )}{' '}
                                            {new Date(
                                              r.posted_on
                                            ).getSeconds() -
                                              new Date(
                                                r.modified_on
                                              ).getSeconds() >
                                              3 && '(edited)'}
                                          </div>

                                          <div
                                            className={`p-1 rounded-lg ease-in-out duration-400 mt-2 ${
                                              theme === 'light'
                                                ? 'text-gray-900 bg-gray-200'
                                                : 'text-gray-100 bg-gray-800'
                                            } w-full text-base sm:text-sm text-left font-spartan`}
                                          >
                                            <Parser theme={theme}>
                                              {r.comment}
                                            </Parser>
                                          </div>

                                          {profile && profile.uid && (
                                            <div
                                              className={`p-1 rounded-lg mt-2 w-full flex items-center text-base sm:text-sm text-left font-spartan`}
                                            >
                                              <div
                                                className={`p-1 font-mono rounded-full w-8 text-center h-8 lg:text-xs text-xxs bg-main-400 mr-2 px-2 ease-in-out duration-400 text-gray-900 flex items-center justify-center`}
                                                title={terms.likes}
                                              >
                                                {r.reacts.length}
                                              </div>

                                              <button
                                                className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-blue-400 text-opacity-75 flex items-center ${
                                                  theme === 'light'
                                                    ? 'bg-gray-200'
                                                    : 'bg-gray-800'
                                                } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                              ri-thumb-up-${
                                                r.reacts.includes(profile.uid)
                                                  ? 'fill'
                                                  : 'line'
                                              } hover:border-blue-400 focus:border-blue-400 text-sm lg:text-lg`}
                                                title={
                                                  r.reacts.includes(profile.uid)
                                                    ? terms.unlike
                                                    : terms.like
                                                }
                                                onClick={() =>
                                                  submitLikeComment(
                                                    r.commentID,
                                                    !r.reacts.includes(
                                                      profile.uid
                                                    )
                                                  )
                                                }
                                              ></button>

                                              {profile.uid === r.uid && (
                                                <button
                                                  className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-yellow-400 text-opacity-75 flex items-center ${
                                                    theme === 'light'
                                                      ? 'bg-gray-200'
                                                      : 'bg-gray-800'
                                                  } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                                ri-pencil-line hover:border-yellow-400 focus:border-yellow-400 text-sm lg:text-lg`}
                                                  title={terms.edit}
                                                  onClick={() => {
                                                    setComment(r.comment);
                                                    setEditingComment(
                                                      r.commentID
                                                    );
                                                  }}
                                                ></button>
                                              )}

                                              {profile.uid === r.uid && (
                                                <button
                                                  className={`lg:py-2 py-1 lg:px-8 px-4 lg:ml-2 ml-1 justify-center font-gilroy tracking-wide text-main-600 text-opacity-75 flex items-center ${
                                                    theme === 'light'
                                                      ? 'bg-gray-200'
                                                      : 'bg-gray-800'
                                                  } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
                                                ri-delete-bin-2-line hover:border-main-600 focus:border-main-600 text-sm lg:text-lg`}
                                                  title={terms.delete}
                                                  onClick={() =>
                                                    setDeletingComment(
                                                      r.commentID
                                                    )
                                                  }
                                                ></button>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <IncludeEditComment
        theme={theme}
        terms={terms}
        editingComment={editingComment}
        setEditingComment={setEditingComment}
        comment={comment}
        setComment={setComment}
        submitUpdateComment={submitAddComment}
      />

      <IncludeReplyComment
        theme={theme}
        terms={terms}
        replyingComment={replyingComment}
        setReplyingComment={setReplyingComment}
        comment={comment}
        setComment={setComment}
        submitUpdateComment={submitAddComment}
        author={
          replyingUid && profiles
            ? profiles.find((p) => p.uid === replyingUid)
            : null
        }
        navigate={navigate}
        tmpAvatar={tmpAvatar}
        language={language}
        comm={
          replyingComment && currentPost && currentPost.comments
            ? currentPost.comments.find((c) => c.commentID === replyingComment)
            : null
        }
      />

      <IncludeDeleteComment
        deletingComment={deletingComment}
        setDeletingComment={setDeletingComment}
        submitDeleteComment={submitDeleteComment}
        theme={theme}
        terms={terms}
      />
    </div>
  );
}
