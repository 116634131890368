import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Clear() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate('/');

    // eslint-disable-next-line
  }, []);

  return <></>;
}
