import upload from './upload';

export const handleImage = async (
  alert,
  API_URL,
  profile,
  setContent,
  addLink,
  terms,
  pure
) => {
  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    let file = input.files[0];
    let formData = new FormData();

    formData.append('file', file);

    alert.info(terms.uploading);
    const uploadResult = await upload(
      API_URL,
      formData,
      profile.uid,
      profile.jwt,
      addLink
    );

    if (!uploadResult[0]) {
      if (uploadResult[1]) {
        alert.error(uploadResult[1]);
        if (pure) {
          return '';
        }
      }
    } else {
      alert.success(terms.image_success);
      if (pure) {
        return uploadResult[1];
      } else {
        setContent((prev) => prev + `\n![](${uploadResult[1]})`);
      }
    }
  };
};
