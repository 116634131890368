import React, { useState } from 'react';

const LocalContext = React.createContext();

function LocalContextProvider({ children }) {
  // const API_URL = 'http://localhost:32000/api/v2';
  const API_URL = 'https://api.konnect.dev/api/v2';

  const [showNotifications, setShowNotifications] = useState(false);
  const [search, setSearch] = useState('');

  const autoSSOLogin = () => {
    let url = window.location.href;
    if (url.includes('uid') && url.includes('jwt')) {
      let uid = window.location.href.split('uid=')[1];
      if (uid.includes('&')) {
        uid = uid.split('&')[0];
      }

      let jwt = window.location.href.split('jwt=')[1];
      if (jwt.includes('&')) {
        jwt = jwt.split('&')[0];
      }

      let t = window.location.href.split('theme=')[1];
      if (t.includes('&')) {
        t = t.split('&')[0];
      }

      let lang = window.location.href.split('lang=')[1];
      if (lang.includes('&')) {
        lang = lang.split('&')[0];
      }
      if (lang.includes('#/')) {
        lang = lang.split('#/')[0];
      }

      localStorage.setItem(
        '_userData',
        JSON.stringify({
          uid: uid,
          jwt: jwt,
          theme: t ? t : 'dark',
          lang: lang ? lang : 'en',
        })
      );

      window.location.href = url.split('?')[0];
    }
  };

  return (
    <LocalContext.Provider
      value={{
        API_URL,
        showNotifications,
        setShowNotifications,
        search,
        setSearch,
        autoSSOLogin,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
}

export { LocalContext, LocalContextProvider };
