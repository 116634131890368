import React from 'react';

import { FullAbsoluteContainer } from './FullAbsoluteContainer';

export default function IncludeEditComment({
  theme,
  terms,
  editingComment,
  setEditingComment,
  comment,
  setComment,
  submitUpdateComment,
}) {
  return (
    <FullAbsoluteContainer
      additional={`w-screen h-screen top-0 left-0 lg:px-2 lg:-mt-4 lg:pt-0 ${
        editingComment ? 'translate-y-0' : 'translate-y-full'
      }`}
      additionalIn="flex flex-col items-center justify-center"
      outFunction={() => setEditingComment(false)}
      theme={theme}
    >
      <div className="flex w-full lg:w-1/2 justify-between items-center">
        <div
          className={`text-main-200 w-full text-xl lg:text-2xl font-semibold font-spartan mt-2 mb-2 text-left`}
        >
          {terms.edit_comment}
        </div>

        <button
          onClick={() => setEditingComment(false)}
          className={`text-center text-lg border-2 border-transparent flex justify-center items-center ${
            theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
          } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ml-3 px-2 my-3 rounded-lg ease-in-out duration-400`}
        >
          <div className={`h-full flex items-center ri-close-line`}></div>
        </button>
      </div>

      <div className="w-full lg:w-1/2 p-2 rounded-lg mt-2 border-opacity-50">
        <textarea
          type="text"
          name={terms.enter_comment}
          title={terms.enter_comment}
          className={`mt-2 w-full outline-none rounded-lg p-2 border-2 border-opacity-50 border-main-300 ease-in-out duration-400
    text-xs sm:text-base opacity-85 hover:border-opacity-95 focus:border-opacity-95 ${
      theme === 'light'
        ? 'bg-gray-100 text-gray-900 border-gray-900 placeholder-gray-900'
        : 'bg-gray-900 text-gray-100 border-gray-100 placeholder-gray-100'
    } font-spartan min-h-20`}
          placeholder={terms.enter_comment}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <div className="w-full lg:w-1/2 mt-4">
        <button
          title={terms.submit}
          className={`justify-center p-2 mt-2 w-full font-gilroy tracking-wide text-main-300 flex items-center ${
            theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
          } border-2 border-transparent
          rounded-lg ease-in-out duration-400 outline-none ${
            comment.trim()
              ? 'hover:border-main-300 focus:border-main-300'
              : 'opacity-50'
          } ease-in-out duration-400`}
          onClick={() => (comment.trim() ? submitUpdateComment() : null)}
        >
          {terms.submit}
        </button>
      </div>
    </FullAbsoluteContainer>
  );
}
