import create from 'zustand';
import { combine } from 'zustand/middleware';

export const useVerifiedStore = create(
  combine(
    {
      verified: [],
    },

    (set) => ({
      setVerified: (value) => set({ verified: value.map((v) => v.uid) }),
    })
  )
);
