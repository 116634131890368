import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import dark from 'react-syntax-highlighter/dist/esm/styles/prism/material-oceanic';
import light from 'react-syntax-highlighter/dist/esm/styles/prism/material-light';

import '../assets/css/markdown.css';

const components = (children, theme) => ({
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || '');

    return !inline && match ? (
      <SyntaxHighlighter
        style={match[1] && theme === 'dark' ? dark : light}
        language={match[1]}
        PreTag="div"
        children={String(children).replace(/\n$/, '')}
        {...props}
      />
    ) : (
      <code
        className={`${
          match && match[1] ? 'text-gray-900' : 'text-gray-100'
        } ${className} rounded-lg`}
        {...props}
      >
        {children}
      </code>
    );
  },
  a: ({ children, href }) => {
    return (
      <a
        href={href}
        className={`outline-none w-full text-xs sm:text-base font-semibold font-spartan opacity-35 hover:opacity-65 focus:opacity-65 ease-in-out duration-400`}
      >
        {children}
      </a>
    );
  },
  img: ({ src, alt }) => {
    return (
      <img
        src={src}
        alt={alt}
        className={`outline-none w-full object-scale-down`}
      />
    );
  },
});

export default function Parser({ smaller, children, theme }) {
  return (
    <ReactMarkdown
      remarkPlugins={[gfm]}
      components={components(theme)}
      className={`w-full markdown-body ${
        theme === 'light' ? 'text-gray-900' : 'text-gray-100'
      } list-disc ${smaller ? 'text-xs' : 'text-sm'} lg:text-base`}
    >
      {children}
    </ReactMarkdown>
  );
}
