import React from 'react';
import { Link } from 'react-router-dom';
import ParticlesBg from 'particles-bg';

import '../assets/css/blinker.css';

export const ContainerFull = ({ children, additional }) => (
  <div className={`w-full lg:h-screen ${additional}`}>{children}</div>
);

export const Container = ({ children, additional, customref }) => (
  <div className={`${additional}`} ref={customref}>
    {children}
  </div>
);

export const FlexContainer = ({ children, additional }) => (
  <div className={`${additional} flex justify-center items-center`}>
    {children}
  </div>
);

export const FlexContainerFull = ({ children, additional }) => (
  <div
    className={`w-full h-full ${additional} rounded-lg opacity-95 flex flex-col-reverse lg:flex-row justify-between`}
  >
    {children}
  </div>
);

export const FlexContainerException = ({ children, additional }) => (
  <div
    className={`w-full h-full ${additional} rounded-lg opacity-95 flex flex-col items-center justify-center`}
  >
    {children}
  </div>
);

export const ParticlesContainer = ({ type, theme }) => (
  <div
    className={`absolute w-full h-full ${
      theme === 'dark' ? 'bg-main-900' : 'bg-main-100'
    } z-0 left-0 top-0`}
  >
    <ParticlesBg type={type} bg={false} />
  </div>
);

export const Image = ({ src, alt, additional, noRounded, noFill }) => (
  <img
    src={src}
    alt={alt}
    className={`${!noFill && 'object-fill'} flex justify-center ${
      !noRounded && 'rounded-full'
    } items-center ${additional}`}
  />
);

export const Header1 = ({ title, children, width, color, additional }) => (
  <div
    className={`text-main-100 w-${
      width ? width : 'full'
    } text-3xl sm:text-4xl font-semibold font-spartan ${additional}`}
    title={title}
  >
    {children}
  </div>
);

export const Header2 = ({ width, children, color, additional }) => (
  <div
    className={`text-main-${color} w-${
      width ? width : 'full'
    } text-xl lg:text-2xl font-semibold font-spartan ${additional}`}
  >
    {children}
  </div>
);

export const Header3 = ({ children, color, additional }) => (
  <div
    className={`text-main-${color} w-full text-xl font-semibold font-spartan ${additional}`}
  >
    {children}
  </div>
);

export const RegularText = ({ children, additional, bigger, notFull }) => (
  <div
    className={`${additional} ${!notFull && 'w-full'} ${
      bigger
        ? 'text-base sm:text-lg'
        : additional && additional.includes('xl')
        ? ''
        : 'text-xs sm:text-base'
    } font-normal font-spartan`}
  >
    {children}
  </div>
);

export const SmallText = ({ children, color, additional, extraSmall }) => (
  <div
    className={`text-${color} ${additional} w-full ${
      extraSmall ? 'text-xxs lg:text-xs' : 'text-xs'
    } font-normal font-spartan flex items-center`}
  >
    {children}
  </div>
);

export const ExtraSmallText = ({ children, color, additional, notFull }) => (
  <div
    className={`text-${color} ${additional} ${
      !notFull && 'w-full'
    } text-xxs lg:text-xs font-normal font-spartan flex items-center`}
  >
    {children}
  </div>
);

export const ALink = ({ to, children, color, additional }) => (
  <a
    href={to}
    className={`text-${color} outline-none w-full text-xs sm:text-base font-semibold font-spartan ${additional} opacity-35 hover:opacity-65 focus:opacity-65`}
  >
    {children}
  </a>
);

export const Input = ({
  value,
  placeholder,
  additional,
  type,
  title,
  change,
  theme,
}) => (
  <input
    type={type}
    name={title}
    title={title}
    className={`${additional} outline-none rounded-lg p-2 text-gray-${
      theme === 'light' ? '900' : '100'
    } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 bg-gray-${
      theme === 'light' ? '400' : '900'
    } font-spartan placeholder-gray-${theme === 'light' ? '800' : '200'}`}
    placeholder={placeholder}
    value={value}
    onChange={(e) => change(e)}
  />
);

export const InputTextArea = ({
  value,
  placeholder,
  additional,
  type,
  title,
  change,
  theme,
  onKeyDown,
}) => (
  <textarea
    type={type}
    name={title}
    title={title}
    className={`${additional} outline-none rounded-lg p-2 text-gray-${
      theme === 'light' ? '900' : '100'
    } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 bg-gray-${
      theme === 'light' ? '400' : '900'
    } font-spartan min-h-5 placeholder-gray-${
      theme === 'light' ? '800' : '200'
    }`}
    placeholder={placeholder}
    value={value}
    onChange={(e) => change(e)}
    onKeyDown={(e) => onKeyDown(e)}
  />
);

export const PasswordInput = ({
  value,
  placeholder,
  additional,
  title,
  change,
  showPassword,
  setShowPassword,
  theme,
}) => (
  <div
    className={`${additional} rounded-lg text-gray-100 opacity-85 hover:opacity-95 focus:opacity-95 bg-gray-${
      theme === 'light' ? '400' : '900'
    } font-spartan flex justify-start items-center`}
  >
    <input
      type={showPassword ? 'text' : 'password'}
      name={title}
      title={title}
      className={`outline-none rounded-lg p-2 text-gray-${
        theme === 'light' ? '900' : '100'
      } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 bg-gray-${
        theme === 'light' ? '400' : '900'
      } font-spartan placeholder-gray-${
        theme === 'light' ? '800' : '200'
      } w-full`}
      placeholder={placeholder}
      value={value}
      onChange={(e) => change(e)}
    />

    <button
      className={`outline-none w-8 h-10 sm:w-10 sm:h-10 rounded-lg flex justify-center items-center text-gray-${
        theme === 'light' ? '900' : '100'
      } opacity-85`}
      type="button"
      onClick={(e) => {
        e.preventDefault();
        setShowPassword((prev) => !prev);
      }}
    >
      <p className={`ri-eye-${showPassword ? '' : 'off-'}line text-lg`}></p>
    </button>
  </div>
);

export const Checkbox = ({ value, additional, title, change, children }) => (
  <div
    className={`${additional} mt-3 font-spartan text-main-300 flex items-center`}
  >
    <input
      type="checkbox"
      name={title}
      value={value}
      onChange={(e) => change(e.target.checked)}
      className="opacity-85 hover:opacity-95 focus:opacity-95 w-4 h-4 mr-2 -mt-1"
    />
    {children}
  </div>
);

export const ImportantButton = ({ title, additional, hoverCondition }) => (
  <button
    className={`${additional} font-gilroy tracking-wide text-main-300 justify-center w-full lg:w-auto text-opacity-75 uppercase font-bold flex items-center sm:px-8 py-3 bg-main-900 rounded-lg bg-opacity-75 ${
      hoverCondition ? 'hover:bg-opacity-90 focus:bg-opacity-90' : 'opacity-50'
    } outline-none`}
    type="submit"
  >
    {title}
  </button>
);

export const ButtonLink = ({ click, children, color, additional }) => (
  <button
    type="button"
    onClick={(e) => click(e)}
    className={`text-${color} outline-none text-xs sm:text-base font-semibold font-spartan ${additional} opacity-35 hover:opacity-65 focus:opacity-65`}
  >
    {children}
  </button>
);

export const AnotherButtonLink = ({
  click,
  children,
  color,
  additional,
  icon,
  extraSmall,
}) => (
  <button
    type="button"
    onClick={(e) => click(e)}
    className={`text-${color} outline-none text-x${
      extraSmall ? 'x' : ''
    }s sm:text-sm font-spartan ${additional} opacity-80 hover:opacity-100 focus:opacity-100 flex`}
  >
    <>
      {children}
      {icon && (
        <span className={`ri-${icon}-line ml-2 flex items-center`}></span>
      )}
    </>
  </button>
);

export const Button = ({
  click,
  color,
  children,
  additional,
  theme,
  transparent,
  notHover,
  noBorder,
  title,
  overrideColor,
}) => (
  <button
    title={title ? title : ''}
    className={`${
      !additional ||
      additional === undefined ||
      (!additional.includes('justify') && 'justify-center')
    } ${additional} font-gilroy tracking-wide ${`text-${
      color
        ? overrideColor
          ? color
          : `${color}-${theme === 'light' ? '700' : '300'}`
        : `main-300`
    }`} text-opacity-75 flex items-center ${
      transparent
        ? 'bg-transparent'
        : `bg-gray-${theme === 'light' ? '400' : '800'}`
    } ${!noBorder && 'border-2 border-transparent rounded-lg'}
     outline-none ${
       !notHover &&
       `hover:border-${
         color
           ? overrideColor
             ? color
             : `${color}-${theme === 'light' ? '500' : '300'}`
           : 'main-300'
       }`
     }`}
    type="button"
    onClick={() => (click ? click() : null)}
  >
    {children}
  </button>
);

export const FakeContainer = ({
  color,
  children,
  additional,
  theme,
  transparent,
  notHover,
  overrideColor,
}) => (
  <div
    className={`${additional} font-gilroy tracking-wide ${`text-${
      color
        ? overrideColor
          ? color
          : `${color}-${theme === 'light' ? '700' : '300'}`
        : `main-300`
    }`} justify-center text-opacity-75 flex items-center ${
      transparent
        ? 'bg-transparent'
        : `bg-gray-${theme === 'light' ? '400' : '800'}`
    } rounded-lg border-2 border-transparent
     outline-none transition ease-in-out duration-400 ${
       !notHover &&
       `hover:border-${
         color
           ? overrideColor
             ? color
             : `${color}-${theme === 'light' ? '500' : '300'}`
           : 'main-300'
       }`
     }`}
  >
    {children}
  </div>
);

export const Linker = ({ to, title, icon, theme, additional }) => (
  <Link
    to={to}
    className={`text-center text-lg p-2 border-2 border-transparent flex justify-center items-center bg-gray-${
      theme === 'light' ? '300 text-gray-800' : '800 text-gray-200'
    } hover:border-main-300 focus:border-main-300 font-gilroy ${additional}`}
  >
    {title && <div className="h-full mr-2">{title}</div>}
    <div
      className={`h-full flex items-center ri-${icon}-line ${
        title ? '' : 'px-1'
      }`}
    ></div>
  </Link>
);

export const LinkerButton = ({
  click,
  title,
  icon,
  theme,
  condition,
  additional,
  smaller,
  noFill,
}) => (
  <button
    title={title}
    type="button"
    onClick={() => (click ? click() : null)}
    className={`text-center ${
      smaller ? 'text-sm lg:text-lg' : 'text-lg'
    } border-2 border-transparent flex justify-center items-center bg-gray-${
      theme === 'light' ? '200' : '900'
    } text-main-300 ${
      condition
        ? 'hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80'
        : 'opacity-50'
    } font-gilroy ${additional}`}
  >
    {icon && (
      <div
        className={`h-full flex items-center ri-${icon}-${
          condition && !noFill ? 'fill' : 'line'
        }`}
      ></div>
    )}
  </button>
);

export const SpecialLinkerButton = ({
  click,
  title,
  icon,
  theme,
  condition,
  additional,
}) => (
  <button
    title={title}
    type="button"
    onClick={() => (click ? click() : null)}
    className={`text-center text-sm border-2 border-transparent flex justify-center items-center h-full bg-gray-${
      theme === 'light' ? '200' : '900'
    } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ${additional}`}
  >
    {title && <div className={`${icon && 'mr-2'}`}>{title}</div>}
    {icon && (
      <div
        className={`h-full flex items-center ri-${icon}-${
          condition ? 'fill' : 'line'
        }`}
      ></div>
    )}
  </button>
);

export const SpecialContainer = ({
  color,
  children,
  additional,
  theme,
  transparent,
  notHover,
  title,
}) => (
  <div
    title={title ? title : ''}
    className={`${additional} font-gilroy tracking-wide text-${
      color ? `${color}-${theme === 'light' ? '500' : '300'}` : `main-300`
    } justify-center text-opacity-75 font-bold flex items-center ${
      transparent
        ? 'bg-transparent'
        : `bg-gray-${theme === 'light' ? '400' : '800'}`
    } rounded-lg border-2 border-transparent
     outline-none transition ease-in-out duration-400 ${
       !notHover &&
       `hover:border-${
         color ? `${color}-${theme === 'light' ? '500' : '300'}` : 'main-300'
       }`
     }`}
  >
    {children}
  </div>
);

export const FullAbsoluteContainer = ({
  additional,
  additionalIn,
  children,
  theme,
  outFunction,
  height,
  moreAdditional,
}) => (
  <div
    className={`w-screen ${additional} ${
      height ? `h-${height}` : 'lg:h-full h-screen lg:top-auto'
    } top-0 ${
      !additional.includes('z-') && 'z-20'
    } fixed transform ease-in-out duration-400`}
    onKeyPress={(e) => e.key === 'Escape' && outFunction()}
  >
    <Container
      additional={`bg-main-${
        theme === 'light' ? '100' : '900'
      } opacity-95 w-full h-full px-2 lg:px-0 ${additionalIn}`}
    >
      <Container
        additional={`${
          moreAdditional ? moreAdditional : `${additionalIn} w-full`
        }`}
      >
        {children}
      </Container>
    </Container>
  </div>
);

export const Separator = (smaller) => (
  <span
    className={`pt-1 w-full bg-gray-500 ${
      smaller ? 'my-2 lg:my-4' : 'my-4'
    } rounded-lg opacity-50`}
  />
);
