import axios from 'axios';

import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useProfileStore } from '../stores/useProfileStore';
import { usePostStore } from '../stores/usePostStore';
import { useNotificationStore } from '../stores/useNotificationStore';
import { useLinkStore } from '../stores/useLinkStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';
import { useVerifiedStore } from '../stores/useVerifiedStore';

export const fetchCurrentProfile = (API_URL, uid, jwt) => {
  return axios
    .post(
      `${API_URL}/profile/fetch/current`,
      { uid, profileID: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((res) => {
      let currentUser = { ...res.data, uid, jwt };

      return axios
        .post(
          `${API_URL}/blog/profile/fetch/one`,
          { uid, profileID: uid },
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        )
        .then((response) => {
          if (response.data.error === 0) {
            let updatedProfile = { ...currentUser };

            updatedProfile.bio = response.data.profile.bio;
            updatedProfile.posts = response.data.profile.posts;
            updatedProfile.followers_length = response.data.profile.followers_length;
            updatedProfile.following_length = response.data.profile.following_length;
            updatedProfile.following = response.data.profile.following;
            updatedProfile.followers = response.data.profile.followers;
            updatedProfile.profile_pic = response.data.profile.profile_pic;
            updatedProfile.banner_img = response.data.profile.banner_img;
            updatedProfile.favorites = response.data.profile.favorites;

            return updatedProfile;
          } else {
            return { error: 500 };
          }
        });
    });
};

export const fetchUserProfile = (API_URL, uid, jwt) => {
  const { setUserProfile } = useUserProfileStore.getState();

  const { setTheme } = useThemeStore.getState();
  const { setLanguage } = useLanguageStore.getState();

  axios
    .post(
      `${API_URL}/profile/fetch/current`,
      { uid: uid, profileID: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((response) => {
      if (response.data.error === 0) {
        axios
          .post(
            `${API_URL}/blog/profile/fetch/one`,
            { uid, profileID: uid },
            {
              headers: { Authorization: `Bearer ${jwt}` },
            }
          )
          .then((res) => {
            let updatedProfile = { ...response.data, uid, jwt };

            updatedProfile.bio = res.data.profile.bio;
            updatedProfile.posts = res.data.profile.posts;
            updatedProfile.followers_length = res.data.profile.followers_length;
            updatedProfile.following_length = res.data.profile.following_length;
            updatedProfile.following = res.data.profile.following;
            updatedProfile.followers = res.data.profile.followers;
            updatedProfile.profile_pic = res.data.profile.profile_pic;
            updatedProfile.banner_img = res.data.profile.banner_img;
            updatedProfile.favorites = res.data.profile.favorites;

            setUserProfile(updatedProfile);
          });

        setTheme(response.data.prefer_dark ? 'dark' : 'light');
        setLanguage(response.data.language);
      } else {
        setUserProfile({});
        console.log(response.data);
      }
    });
};

const fetchPosts = (API_URL, uid, jwt, offset, currentPosts) => {
  const { setPosts } = usePostStore.getState();

  axios
    .post(
      `${API_URL}/blog/post/fetch?offset=${offset}&limit=10`,
      { uid: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then(async (response) => {
      if (response.data.error === 0) {
        setPosts([...currentPosts]);
        if (offset * 10 + 10 < response.data.amount) {
          await fetchPosts(API_URL, uid, jwt, offset + 1, [...response.data.posts, ...currentPosts]);
        } else {
          setPosts([...response.data.posts, ...currentPosts]);
        }
      } else {
        setPosts([]);
        console.log(response.data);
      }
    });
};

const fetchProfiles = (API_URL, uid, jwt, offset, currentProfiles) => {
  const { setProfiles } = useProfileStore.getState();

  axios
    .post(
      `${API_URL}/blog/profile/fetch?offset=${offset}&limit=30`,
      { uid: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then(async (response) => {
      if (response.data.error === 0) {
        setProfiles([...currentProfiles]);
        if (currentProfiles.length < response.data.amount) {
          await fetchProfiles(API_URL, uid, jwt, offset + 1, [...currentProfiles, ...response.data.profiles]);
        } else {
          setProfiles([...currentProfiles, ...response.data.profiles]);
        }
      } else {
        setProfiles([]);
        console.log(response.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

const fetchNotifications = (API_URL, uid, jwt, offset, currentNotifications) => {
  const { setNotifications } = useNotificationStore.getState();

  axios
    .post(
      `${API_URL}/blog/notification/fetch?offset=${offset}&limit=10`,
      { uid: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then(async (response) => {
      if (response.data.error === 0) {
        setNotifications([...currentNotifications]);
        if (offset * 10 + 10 < response.data.amount) {
          await fetchNotifications(API_URL, uid, jwt, offset + 1, [
            ...response.data.notifications,
            ...currentNotifications,
          ]);
        } else {
          setNotifications([...response.data.notifications, ...currentNotifications]);
        }
      } else {
        setNotifications([]);
        console.log(response.data);
      }
    });
};

const fetchLinks = (API_URL, uid, jwt, offset, currentLinks) => {
  const { setLinks } = useLinkStore.getState();

  axios
    .post(
      `${API_URL}/link/fetch?offset=${offset}&limit=20`,
      { uid: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then(async (response) => {
      if (response.data.error === 0) {
        setLinks([...currentLinks]);
        if (offset * 20 + 20 < response.data.amount) {
          await fetchLinks(API_URL, uid, jwt, offset + 1, [...currentLinks, ...response.data.links]);
        } else {
          setLinks([...response.data.links, ...currentLinks]);
        }
      } else {
        setLinks([]);
        console.log(response.data);
      }
    });
};

const fetchVerified = (API_URL, uid, jwt) => {
  const { setVerified } = useVerifiedStore.getState();

  axios
    .post(
      `${API_URL}/verified/fetch`,
      { uid: uid },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    .then((response) => {
      if (response.data.error === 0) {
        setVerified([...response.data.verified]);
      } else {
        setVerified([]);
        console.log(response.data);
      }
    });
};

export const automaticLogin = async (API_URL, uid, jwt) => {
  await fetchPosts(API_URL, uid, jwt, 0, []);
  await fetchUserProfile(API_URL, uid, jwt);
  await fetchProfiles(API_URL, uid, jwt, 0, []);
  await fetchNotifications(API_URL, uid, jwt, 0, []);
  await fetchLinks(API_URL, uid, jwt, 0, []);
  await fetchVerified(API_URL, uid, jwt);
};

export const logout = async () => {
  const { setUserProfile } = useUserProfileStore.getState();
  const { setProfiles } = useProfileStore.getState();
  const { setPosts } = usePostStore.getState();
  const { setNotifications } = useNotificationStore.getState();
  const { setLinks } = useLinkStore.getState();
  const { setTheme } = useThemeStore.getState();
  const { setLanguage } = useLanguageStore.getState();

  setUserProfile({});
  setProfiles([]);
  setPosts([]);
  setNotifications([]);
  setLinks([]);
  setTheme('dark');
  setLanguage('en');

  localStorage.clear();
};
