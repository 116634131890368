import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalContext } from '../wrappers/LocalContext';
import useLanguage from '../wrappers/LanguageWrapper';

import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';

import { fetchCurrentProfile } from '../utils/fetcher';

import logomix from '../assets/images/logo_mix.png';

import {
  Container,
  FlexContainer,
  ParticlesContainer,
  RegularText,
} from '../components/Components';

import LanguagePicker from '../components/LanguagePicker';

export default function Login() {
  const { API_URL, autoSSOLogin } = useContext(LocalContext);

  const { theme, setTheme } = useThemeStore((state) => state);
  const { language, setLanguage } = useLanguageStore((state) => state);

  const navigate = useNavigate();
  const terms = useLanguage(language).login;

  const [showLanguages, setShowLanguages] = useState(false);
  // const [showLoginPassword, setShowLoginPassword] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitError] = useState(false);

  useEffect(() => {
    // localStorage.clear();
    // console.log(JSON.parse(localStorage.getItem('_userData')));

    autoSSOLogin();

    async function autoLogin() {
      if (localStorage.getItem('_userData')) {
        setSubmit(true);
        setSubmitMessage(terms.auto_login);

        let allowed = false;

        const { uid, jwt, lang, theme } = JSON.parse(
          localStorage.getItem('_userData')
        );
        setTheme(theme ? theme : 'dark');
        setLanguage(lang ? lang : 'en');

        const fetchUserProfile = await fetchCurrentProfile(API_URL, uid, jwt);
        allowed = fetchUserProfile.error === 0;

        setTimeout(() => {
          setSubmit(false);
          setSubmitMessage(terms.submitting);

          allowed && navigate('/profile');
        }, 1500);
      }
    }

    autoLogin();
    // eslint-disable-next-line
  }, [terms]);

  return (
    <div className="w-full lg:h-screen px-3 py-8 sm:p-10 h-screenm sm:h-screentab flex flex-col items-center justify-center overflow-hidden">
      <ParticlesContainer type="color" theme={theme} />

      <div className="w-full h-full rounded-lg opacity-95 flex flex-col-reverse lg:flex-row justify-between bg-gray-800 py-10 lg:py-0">
        <Container additional="w-full h-full absolute inset-x-0 inset-y-0">
          <LanguagePicker
            language={language}
            setLanguage={setLanguage}
            showLanguages={showLanguages}
            setShowLanguages={setShowLanguages}
          />
        </Container>

        <Container
          additional={`w-full lg:w-2/5 ${
            submit ? 'px-2' : 'px-3 lg:px-20 lg:py-32'
          } h-2/3 lg:h-auto z-40`}
        >
          {submit && (
            <FlexContainer additional="w-full items-center h-full my-2">
              <RegularText
                additional={`text-2xl lg:text-3xl font-semibold text-center ${
                  submitError ? 'text-red-500' : 'text-green-400'
                }`}
              >
                {submitMessage}
              </RegularText>
            </FlexContainer>
          )}

          <Container
            additional={`transform transition duration-400 ${
              !submit ? 'translate-x-0' : '-translate-x-full opacity-0 absolute'
            }
`}
          >
            <div className="text-main-100 w-full text-3xl sm:text-4xl font-semibold font-spartan opacity-75 text-center lg:text-left mb-4 lg:mb-6">
              {terms.login_h}
            </div>

            <div className="w-full px-3 lg:px-0">
              <a
                className={`mt-3 font-gilroy tracking-wide text-main-300 justify-center w-full lg:w-auto text-opacity-75 uppercase font-bold flex items-center sm:px-8 py-3 bg-main-900 rounded-lg bg-opacity-75 hover:bg-opacity-90 focus:bg-opacity-90 outline-none`}
                href={`https://sso.konnect.dev/?origin=${
                  window.location.href.split('?')[0]
                }`}
              >
                {terms.login_sso}
              </a>
            </div>
          </Container>
        </Container>

        <div className="flex justify-center items-center w-full lg:w-3/5 mt-4 lg:mt-0">
          <img
            src={logomix}
            alt="logo-mix"
            additional="w-2/3 lg:w-11/12 opacity-90 mb-8 lg:mb-0 object-fill justify-center items-center"
          />
        </div>
      </div>
    </div>
  );
}
