import React from 'react';

import Parser from '../../processors/markdownEngine';
import { convert } from '../../utils/timestamp';

import { FullAbsoluteContainer } from './FullAbsoluteContainer';

export default function IncludeReplyComment({
  theme,
  terms,
  replyingComment,
  setReplyingComment,
  comment,
  setComment,
  submitUpdateComment,
  author,
  navigate,
  tmpAvatar,
  language,
  comm,
}) {
  return (
    <FullAbsoluteContainer
      additional={`w-screen h-screen top-0 left-0 lg:px-2 lg:-mt-4 lg:pt-0 ${
        replyingComment ? 'translate-y-0' : 'translate-y-full'
      }`}
      additionalIn="flex flex-col items-center justify-center"
      outFunction={() => setReplyingComment(false)}
      theme={theme}
    >
      <div className="flex w-full lg:w-1/2 justify-between items-center">
        <div
          className={`text-main-200 w-full text-xl lg:text-2xl font-semibold font-spartan mt-2 mb-2 text-left`}
        >
          {terms.reply_comment}
        </div>

        <button
          onClick={() => setReplyingComment(false)}
          className={`text-center text-lg border-2 border-transparent flex justify-center items-center ${
            theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
          } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ml-3 px-2 my-3 rounded-lg ease-in-out duration-400`}
        >
          <div className={`h-full flex items-center ri-close-line`}></div>
        </button>
      </div>

      {replyingComment && (
        <div
          className={`flex w-full lg:w-1/2 p-2 rounded-lg mt-2 border-opacity-50 border-2 border-main-300 ${
            theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
          } ease-in-out duration-400 rounded-lg p-2 mb-2`}
        >
          <div className="h-12 w-12 sm:h-20 sm:w-20 flex-shrink-0 flex-grow-0">
            <button
              className="outline-none"
              onClick={() => navigate(`/u/${comm.uid}`)}
            >
              <img
                src={
                  author && author.profile_pic.length > 3
                    ? author.profile_pic
                    : tmpAvatar
                }
                alt="p.pic-author"
                className={`w-full h-full border-2 lg:border-4 rounded-full border-main-300 p-1 object-scale-down ${
                  theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
                } bg-opacity-50 ease-in-out duration-400`}
              />
            </button>
          </div>
          <div className="w-full ml-4">
            <button
              className="text-main-300 w-full text-base sm:text-lg text-left font-gilroy outline-none"
              onClick={() => navigate(`/u/${author.uid}`)}
            >
              {author && author.name}{' '}
              <span className="text-main-200">
                ({author && author.username})
              </span>
            </button>

            <div className="w-full rounded-full pt-1 opacity-75 bg-main-200 mb-2 mt-1" />

            <div
              className={`${
                theme === 'light' ? 'text-gray-900' : 'text-gray-100'
              } w-full text-xxs sm:text-xs text-left font-spartan`}
            >
              {terms.posted_on} {convert(comm.posted_on, language, true)}{' '}
              {new Date(comm.posted_on).getSeconds() -
                new Date(comm.modified_on).getSeconds() >
                3 && terms.edited}
            </div>

            <div
              className={`p-1 rounded-lg ease-in-out duration-400 mt-2 ${
                theme === 'light'
                  ? 'text-gray-900 bg-gray-200'
                  : 'text-gray-100 bg-gray-800'
              } w-full text-base sm:text-sm text-left font-spartan`}
            >
              <Parser theme={theme}>{comm.comment}</Parser>
            </div>
          </div>
        </div>
      )}

      <div className="w-full lg:w-1/2 py-2 rounded-lg border-opacity-50">
        <textarea
          type="text"
          name={terms.enter_comment}
          title={terms.enter_comment}
          className={`mt-2 w-full outline-none rounded-lg p-2 border-2 border-opacity-50 border-main-300 ease-in-out duration-400
    text-xs sm:text-base opacity-85 hover:border-opacity-95 focus:border-opacity-95 ${
      theme === 'light'
        ? 'bg-gray-100 text-gray-900 border-gray-900 placeholder-gray-900'
        : 'bg-gray-900 text-gray-100 border-gray-100 placeholder-gray-100'
    } font-spartan min-h-20`}
          placeholder={terms.enter_comment}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <div className="w-full lg:w-1/2 mt-4">
        <button
          title={terms.submit}
          className={`justify-center p-2 mt-2 w-full font-gilroy tracking-wide text-main-300 flex items-center ${
            theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
          } border-2 border-transparent
          rounded-lg ease-in-out duration-400 outline-none ${
            comment.trim()
              ? 'hover:border-main-300 focus:border-main-300'
              : 'opacity-50'
          } ease-in-out duration-400`}
          onClick={() => (comment.trim() ? submitUpdateComment() : null)}
        >
          {terms.submit}
        </button>
      </div>
    </FullAbsoluteContainer>
  );
}
