import create from 'zustand';
import { combine } from 'zustand/middleware';

export const useProfileStore = create(
  combine(
    {
      profiles: [],
    },
    (set) => ({
      setProfiles: (value) => set({ profiles: value }),
    })
  )
);
