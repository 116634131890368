import React, { useCallback, useContext, useEffect } from 'react';

import { FullAbsoluteContainer } from '../pages/includes/FullAbsoluteContainer';
import Notification from '../components/Notification';

import { useLanguageStore } from '../stores/useLanguageStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useProfileStore } from '../stores/useProfileStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useNotificationStore } from '../stores/useNotificationStore';

import useLanguage from './LanguageWrapper';
import { LocalContext } from './LocalContext';

export default function NotificationProcessor({ children }) {
  const { theme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);

  const terms = useLanguage(language).notifications;

  const { profile } = useUserProfileStore((state) => state);
  const { profiles } = useProfileStore((state) => state);
  const { notifications } = useNotificationStore((state) => state);

  const allProfiles = [...profiles, { ...profile }];

  const { showNotifications, setShowNotifications } = useContext(LocalContext);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowNotifications(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div
        className={`bg-main-${
          theme === 'light' ? '100' : '900'
        } overflow-x-hidden lg:h-screen lg:max-h-screen lg:overflow-y-scroll ease-in-out duration-400`}
      >
        {children}
      </div>

      <FullAbsoluteContainer
        theme={theme}
        height="screen"
        additional={`w-screen ease-in-out duration-400 ${
          showNotifications ? 'translate-y-0' : 'translate-y-full'
        } z-40`}
        additionalIn="flex flex-col items-center w-full pt-8 lg:pt-10 lg:pb-12 ease-in-out duration-400"
        outFunction={() => setShowNotifications(false)}
      >
        <div className="w-full lg:w-1/2 pt-4">
          <div className="h-screen pb-72 lg:pb-40 mt-4 pr-2 overflow-y-scroll">
            {notifications &&
              notifications
                .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
                .map((n, i) => (
                  <Notification
                    notification={n}
                    theme={theme}
                    profile={profile}
                    profiles={allProfiles}
                    terms={terms}
                    key={`notif-${i}`}
                    language={language}
                  />
                ))}
          </div>
        </div>
      </FullAbsoluteContainer>
    </div>
  );
}
