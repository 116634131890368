import create from 'zustand';
import { combine } from 'zustand/middleware';

export const useUserProfileStore = create(
  combine({ profile: {} }, (set) => ({
    setUserProfile: (value) => set({ profile: value }),

    savePost: (blogID, save) =>
      set((state) => {
        let updatedProfile = state.profile;

        if (!save) {
          updatedProfile.favorites = updatedProfile.favorites.filter(
            (f) => f !== blogID
          );
        } else {
          updatedProfile.favorites = [
            ...updatedProfile.favorites.filter((f) => f !== blogID),
            blogID,
          ];
        }

        return { profile: updatedProfile };
      }),

    setBio: (bio) =>
      set((state) => {
        let updatedProfile = state.profile;
        updatedProfile.bio = bio;
        return { profile: updatedProfile };
      }),

    setProfilePic: (profile_pic) =>
      set((state) => {
        let updatedProfile = state.profile;
        updatedProfile.profile_pic = profile_pic;
        return { profile: updatedProfile };
      }),

    setBannerImg: (banner_img) =>
      set((state) => {
        let updatedProfile = state.profile;
        updatedProfile.banner_img = banner_img;
        return { profile: updatedProfile };
      }),

    addFollowing: (profileUID) =>
      set((state) => {
        let updatedProfile = state.profile;
        updatedProfile.following_length = updatedProfile.following.includes(
          profileUID
        )
          ? updatedProfile.following_length
          : updatedProfile.following_length + 1;
        updatedProfile.following = [
          ...updatedProfile.following.filter((f) => f !== profileUID),
          profileUID,
        ];
        return { profile: updatedProfile };
      }),

    removeFollowing: (profileUID) =>
      set((state) => {
        let updatedProfile = state.profile;
        updatedProfile.following_length = updatedProfile.following.includes(
          profileUID
        )
          ? updatedProfile.following_length - 1
          : updatedProfile.following_length;
        updatedProfile.following = [
          ...updatedProfile.following.filter((f) => f !== profileUID),
        ];
        return { profile: updatedProfile };
      }),
  }))
);
