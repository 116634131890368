import React from 'react';

export default function LanguagePicker({
  language,
  setLanguage,
  showLanguages,
  setShowLanguages,
}) {
  return (
    <div className="z-10 w-screen h-custom lg:h-screen pt-12 pr-8 sm:pr-12 lg:pr-16 lg:pt-14 opacity-75 flex justify-end font-gilroy overflow-hidden relative">
      {!showLanguages && (
        <button
          className="w-12 h-12 opacity-80 border-2 border-main-200 rounded-full font-semibold text-main-200"
          onClick={() => setShowLanguages(true)}
        >
          {language}
        </button>
      )}
      <div
        className={`w-1/4 lg:w-1/12 transform transition duration-400 absolute ${
          showLanguages ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <button
          className={`w-full text-sm sm:text-base p-1 lg:p-2 ${
            language === 'en' ? 'bg-main-300' : 'bg-main-200'
          } rounded-lg text-center text-main-900 opacity-75 hover:opacity-90 focus:opacity-90`}
          onClick={() => {
            setShowLanguages(false);
            setLanguage('en');
          }}
        >
          English
        </button>

        <button
          className={`w-full text-sm sm:text-base mt-2 p-1 lg:p-2 ${
            language === 'fr' ? 'bg-main-300' : 'bg-main-200'
          } rounded-lg text-center text-main-900 opacity-75 hover:opacity-90 focus:opacity-90`}
          onClick={() => {
            setShowLanguages(false);
            setLanguage('fr');
          }}
        >
          Français
        </button>

        <button
          className={`w-full text-sm sm:text-base mt-2 p-1 lg:p-2 ${
            language === 'mu' ? 'bg-main-300' : 'bg-main-200'
          } rounded-lg text-center text-main-900 opacity-75 hover:opacity-90 focus:opacity-90`}
          onClick={() => {
            setShowLanguages(false);
            setLanguage('mu');
          }}
        >
          Morisien
        </button>
      </div>
    </div>
  );
}
