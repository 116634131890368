import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import MDEditor, { commands } from '@uiw/react-md-editor';
import { useAlert } from 'react-alert';
import axios from 'axios';

import { LocalContext } from '../wrappers/LocalContext';

import { handleImage } from '../utils/handleImage';

import Parser from '../processors/markdownEngine';
import Navbar from '../components/Navbar';

import useLanguage from '../wrappers/LanguageWrapper';

import { usePostStore } from '../stores/usePostStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useLinkStore } from '../stores/useLinkStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';

import { generate } from '../utils/timestamp';

export default function Create() {
  const [showPreview, setShowPreview] = useState(false);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [slug, setSlug] = useState('');
  const [previewImg, setPreviewImg] = useState('');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [carousel, setCarousel] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [didOnce, setDidOnce] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);

  const { posts, addPost, editPost, setPosts } = usePostStore((state) => state);
  const { profile } = useUserProfileStore((state) => state);
  const { links, addLink } = useLinkStore((state) => state);
  const { theme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);

  const { blogID } = useParams();

  const terms = useLanguage(language).create_post;

  const alert = useAlert();
  const navigate = useNavigate();

  const { API_URL } = useContext(LocalContext);

  const generateID = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    let timer = setTimeout(() => setIsLoading(false), 4000);

    setCurrentPost(null);
    setIsLoading(true);
    setDidOnce(false);

    return () => {
      clearTimeout(timer);
    };
  }, [blogID]);

  if (!didOnce) {
    if (blogID) {
      let foundPost = posts.find((p) => p.blogID === blogID);

      setDidOnce(true);

      const setPost = () => {
        setCurrentPost(foundPost);

        setTitle(foundPost.title);
        setSubtitle(foundPost.subtitle);
        setSlug(foundPost.slug);
        setPreviewImg(foundPost.preview_img);
        setContent(foundPost.content);
        setContent(foundPost.content);
        setTags(foundPost.tags.join(','));
        setCarousel(foundPost.carousel);
      };

      if (!foundPost || !foundPost.uid || foundPost.uid !== profile.uid) {
        axios
          .post(
            `${API_URL}/blog/post/fetch/one`,
            { uid: profile.uid, blogID },
            {
              headers: { Authorization: `Bearer ${profile.jwt}` },
            }
          )
          .then((response) => {
            if (response.data.error === 0) {
              if (response.data.post.uid !== profile.uid) {
                navigate('/');
              }

              foundPost = response.data.post;

              let currentPosts = [...posts];
              let currentPostIDs = currentPosts.map((p) => p.postID);

              if (!currentPostIDs.includes(foundPost.postID)) {
                currentPosts.push(foundPost);
              }

              setPosts(currentPosts);

              setPost();
            }
          });
      } else {
        setPost();
      }
    }
  }

  const submitPost = () => {
    if (content.length <= 0) {
      alert.error(terms.content_short);
      return;
    }

    setIsSubmitting(true);

    const data = {
      uid: profile.uid,
      blogID: currentPost ? currentPost.blogID : '',
      title: title.trim(),
      subtitle: subtitle.trim(),
      slug: slug
        ? slug.trim()
        : `${title.trim().split(' ').join('-')}-${generateID(5)}`,
      preview_img: previewImg,
      published:
        currentPost && currentPost.published ? currentPost.published : false,
      tags: tags,
      content: content.trim(),
      carousel: carousel,
      created_on:
        currentPost && currentPost.created_on
          ? currentPost.created_on
          : generate(),
      modified_on: generate(),
      updated_on: generate(),
      views: currentPost && currentPost.views ? currentPost.views : [],
      likes: currentPost && currentPost.likes ? currentPost.likes : [],
      comments: currentPost && currentPost.comments ? currentPost.comments : [],
    };

    setTitle('');
    setSubtitle('');
    setSlug('');
    setPreviewImg('');
    setTags('');
    setContent('');
    setCarousel([]);

    axios
      .post(
        `${API_URL}/blog/post/${currentPost ? 'update' : 'create'}`,
        { ...data },
        {
          headers: { Authorization: `Bearer ${profile.jwt}` },
        }
      )
      .then((res) => {
        if (res.data.error === 0) {
          if (!currentPost) {
            data.blogID = res.data.blogID;
            data.tags = data.tags.split(' ').join('_').split(',');
            addPost(data);
          } else {
            editPost(
              data.blogID,
              data.title,
              data.subtitle,
              data.slug,
              data.preview_img,
              data.tags.split(' ').join('_').split(','),
              data.content,
              data.carousel
            );
          }

          alert.success(
            currentPost ? terms.post_edit_success : terms.post_create_success
          );

          navigate(`/post/${res.data.postID}`);
        } else {
          alert.error(res.data.message);
        }

        setIsSubmitting(false);
      });
  };

  return (
    <div
      className={`w-full ${
        theme === 'light' ? 'bg-main-100' : 'bg-main-900'
      } ease-in-out duration-400 lg:pb-0 pb-20`}
    >
      <Navbar />
      <div className={`w-full p-2 lg:px-56 pt-20 lg:pt-24`}>
        <div className="text-main-300 w-full text-3xl sm:text-4xl font-semibold font-gilroy">
          {terms.create_post}
        </div>

        {isSubmitting ? (
          <div className="text-main-200 w-full text-center lg:text-left blink mt-4 text-xl lg:text-2xl font-semibold font-spartan">
            {terms.submitting}
          </div>
        ) : !currentPost && blogID ? (
          <div className="w-full">
            {!currentPost &&
              (isLoading ? (
                <>
                  <div
                    className={`${
                      theme === 'light' ? 'text-main-400' : 'text-main-200'
                    } text-xl lg:text-2xl font-semibold font-spartan w-full flex items-center justify-start mt-4 text-left blink`}
                  >
                    {terms.loading}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-main-600 w-full flex items-center justify-start mt-4 text-left text-xl lg:text-2xl font-semibold font-spartan">
                    {terms.post_not_found}
                  </div>

                  <Link
                    to="/"
                    className={`text-center text-lg p-2 w-full lg:w-1/4 border-2 border-transparent flex justify-center items-center ${
                      theme === 'light'
                        ? 'bg-gray-300 text-gray-800'
                        : 'bg-gray-800 text-gray-200'
                    } hover:border-main-300 focus:border-main-300 font-gilroy rounded-lg mt-4 ease-in-out duration-400`}
                  >
                    <div className="h-full">{terms.return_feed}</div>
                  </Link>
                </>
              ))}
          </div>
        ) : (
          <div className="w-full">
            <div className="w-full flex lg:flex-row flex-col items-center mt-3 ">
              <div className="text-main-300 opacity-75 w-full lg:w-1/3 text-lg sm:text-xl font-spartan">
                {terms.title_placeholder}
              </div>

              <input
                type="text"
                className={`w-full lg:mt-0 mt-1 outline-none border-2 border-opacity-50 border-main-300 rounded-lg p-2 ${
                  theme === 'light' ? 'text-gray-900' : 'text-gray-100'
                } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                } font-spartan ${
                  theme === 'light'
                    ? 'placeholder-gray-800'
                    : 'placeholder-gray-200'
                } hover:border-main-200 focus:border-main-200 ease-in-out duration-400`}
                value={title}
                onChange={(e) => {
                  e.persist();
                  setTitle(e.target.value);
                  setSlug(
                    `${e.target.value.trim().split(' ').join('-')}-${generateID(
                      5
                    )}`.toLowerCase()
                  );
                }}
              />
            </div>

            <div className="w-full flex lg:flex-row flex-col items-center mt-3 ">
              <div className="text-main-300 opacity-75 w-full lg:w-1/3 text-lg sm:text-xl font-spartan">
                {terms.subtitle_placeholder}
              </div>

              <input
                type="text"
                className={`w-full lg:mt-0 mt-1 outline-none border-2 border-opacity-50 border-main-300 rounded-lg p-2 ${
                  theme === 'light' ? 'text-gray-900' : 'text-gray-100'
                } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                } font-spartan ${
                  theme === 'light'
                    ? 'placeholder-gray-800'
                    : 'placeholder-gray-200'
                } hover:border-main-200 focus:border-main-200 ease-in-out duration-400`}
                value={subtitle}
                onChange={(e) => {
                  e.persist();
                  setSubtitle(e.target.value);
                }}
              />
            </div>

            <div className="w-full flex lg:flex-row flex-col items-center mt-3 ">
              <div className="text-main-300 opacity-75 w-full lg:w-1/3 text-lg sm:text-xl font-spartan">
                {terms.slug_placeholder}
              </div>

              <input
                type="text"
                className={`w-full lg:mt-0 mt-1 outline-none border-2 border-opacity-50 border-main-300 rounded-lg p-2 ${
                  theme === 'light' ? 'text-gray-900' : 'text-gray-100'
                } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                } font-spartan ${
                  theme === 'light'
                    ? 'placeholder-gray-800'
                    : 'placeholder-gray-200'
                } hover:border-main-200 focus:border-main-200 ease-in-out duration-400`}
                value={slug}
                onChange={(e) => {
                  e.persist();
                  setSlug(
                    `${e.target.value.split(' ').join('-')}-${generateID(
                      5
                    )}`.toLowerCase()
                  );
                }}
              />
            </div>

            <div className="w-full flex lg:flex-row flex-col items-center mt-3">
              <div className="text-main-300 opacity-75 w-full lg:w-1/3 text-lg font-spartan">
                {terms.preview_img} ({terms.optional})
              </div>

              <div className="w-full lg:mt-0 mt-1 border-main-300 py-2">
                <div className="flex w-full overflow-x-scroll lg:h-40 h-20 pb-2">
                  {links.map((l) => (
                    <button
                      key={`preview-${l.linkID}`}
                      className={`border-2 border-transparent rounded-lg mr-2 ${
                        previewImg === l.link
                          ? 'border-main-200'
                          : 'hover:border-main-300 focus:border-main-300'
                      } ease-in-out duration-400 flex-none flex-shrink-0 flex-grow-0`}
                      onClick={() => setPreviewImg(l.link)}
                    >
                      <img
                        src={l.link}
                        alt={l.linkID}
                        className="h-full w-full rounded-lg object-scale-down"
                      />
                    </button>
                  ))}
                </div>
                <div className="w-full flex mt-2">
                  <button
                    className={`p-2 w-1/2 justify-center font-gilroy tracking-wide text-main-300 text-opacity-75 flex items-center ${
                      theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                    } border-2 border-transparent rounded-lg outline-none hover:border-main-300 focus:border-main-300 ease-in-out duration-400`}
                    onClick={() =>
                      handleImage(
                        alert,
                        API_URL,
                        profile,
                        () => null,
                        addLink,
                        terms,
                        true
                      )
                    }
                  >
                    {terms.upload}
                  </button>

                  <button
                    className={`p-2 w-1/2 justify-center font-gilroy tracking-wide text-main-600 text-opacity-75 flex items-center ml-2 ${
                      theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                    } border-2 border-transparent rounded-lg outline-none hover:border-main-600 focus:border-main-600 ease-in-out duration-400`}
                    onClick={() => setPreviewImg('')}
                  >
                    {terms.remove}
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col items-center mt-3">
              <div className="text-main-300 opacity-75 w-full text-lg sm:text-xl font-spartan">
                {terms.tags}
              </div>

              <input
                type="text"
                className={`w-full mt-1 outline-none border-2 border-opacity-50 border-main-300 rounded-lg p-2 ${
                  theme === 'light' ? 'text-gray-900' : 'text-gray-100'
                } text-xs sm:text-base opacity-85 hover:opacity-95 focus:opacity-95 ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-900'
                } font-spartan ${
                  theme === 'light'
                    ? 'placeholder-gray-800'
                    : 'placeholder-gray-200'
                } hover:border-main-200 focus:border-main-200 ease-in-out duration-400`}
                value={tags}
                onChange={(e) => {
                  e.persist();
                  setTags(
                    `${e.target.value.split(' ').join('_')}`.toLowerCase()
                  );
                }}
              />

              {tags.trim().length > 0 && (
                <div className="w-full flex overflow-x-scroll pb-2 mt-2">
                  {tags.split(',').map((t) => (
                    <div
                      key={`tag-${t}`}
                      className={`p-1 rounded-lg lg:text-base text-sm font-sans ${
                        theme === 'light'
                          ? 'bg-gray-200 text-gray-800'
                          : 'bg-gray-800 text-gray-200'
                      } mr-2 ease-in-out duration-400`}
                    >
                      {t}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="w-full rounded-full pt-1 opacity-75 bg-gray-500 my-4" />

            <div className="mt-2 my-4 w-full flex items-center">
              <div className="text-main-300 opacity-75 text-xl lg:text-2xl font-semibold font-spartan mr-4">
                {terms.content}
              </div>

              <button
                className={`p-2 w-1/2 lg:w-1/4 justify-center font-gilroy tracking-wide text-main-200 text-opacity-75 flex items-center ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                } border-2 border-transparent rounded-lg outline-none hover:border-main-200 focus:border-main-200 ease-in-out duration-400`}
                onClick={() => setShowPreview(false)}
              >
                {terms.editor}
              </button>

              <button
                className={`p-2 ml-2 w-1/2 lg:w-1/4 justify-center font-gilroy tracking-wide text-main-300 text-opacity-75 flex items-center ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                } border-2 border-transparent rounded-lg outline-none hover:border-main-300 focus:border-main-300 ease-in-out duration-400`}
                onClick={() => setShowPreview(true)}
              >
                {terms.preview}
              </button>
            </div>

            {!showPreview && (
              <div className={`w-full bg-gray-800 rounded-lg`}>
                <MDEditor
                  value={content}
                  onChange={setContent}
                  visiableDragbar={true}
                  preview="edit"
                  minHeight="200"
                  className="mb-2 h-100"
                  autoFocus={true}
                  extraCommands={[
                    commands.group([], {
                      name: 'upload-image',
                      groupName: 'upload-image',
                      icon: (
                        <svg viewBox="0 0 1024 1024" width="12" height="12">
                          <path
                            fill="currentColor"
                            d="M716.8 921.6a51.2 51.2 0 1 1 0 102.4H307.2a51.2 51.2 0 1 1 0-102.4h409.6zM475.8016 382.1568a51.2 51.2 0 0 1 72.3968 0l144.8448 144.8448a51.2 51.2 0 0 1-72.448 72.3968L563.2 541.952V768a51.2 51.2 0 0 1-45.2096 50.8416L512 819.2a51.2 51.2 0 0 1-51.2-51.2v-226.048l-57.3952 57.4464a51.2 51.2 0 0 1-67.584 4.2496l-4.864-4.2496a51.2 51.2 0 0 1 0-72.3968zM512 0c138.6496 0 253.4912 102.144 277.1456 236.288l10.752 0.3072C924.928 242.688 1024 348.0576 1024 476.5696 1024 608.9728 918.8352 716.8 788.48 716.8a51.2 51.2 0 1 1 0-102.4l8.3968-0.256C866.2016 609.6384 921.6 550.0416 921.6 476.5696c0-76.4416-59.904-137.8816-133.12-137.8816h-97.28v-51.2C691.2 184.9856 610.6624 102.4 512 102.4S332.8 184.9856 332.8 287.488v51.2H235.52c-73.216 0-133.12 61.44-133.12 137.8816C102.4 552.96 162.304 614.4 235.52 614.4l5.9904 0.3584A51.2 51.2 0 0 1 235.52 716.8C105.1648 716.8 0 608.9728 0 476.5696c0-132.1984 104.8064-239.872 234.8544-240.2816C258.5088 102.144 373.3504 0 512 0z"
                          />
                        </svg>
                      ),
                      children: () => {
                        return <div></div>;
                      },
                      execute: () => {
                        handleImage(
                          alert,
                          API_URL,
                          profile,
                          setContent,
                          addLink,
                          terms
                        );
                      },
                      buttonProps: { 'aria-label': 'Insert title' },
                    }),
                  ]}
                />
              </div>
            )}

            {showPreview && (
              <div
                className={`w-full rounded-lg bg-gray-800 text-gray-100 ease-in-out duration-400`}
              >
                <div className="flex w-full items-center p-2">
                  <Parser>{content}</Parser>
                </div>
              </div>
            )}

            <div className="w-full rounded-full pt-1 opacity-75 bg-gray-500 my-4" />

            <div className="w-full flex lg:flex-row flex-col items-center mt-3 ">
              <div className="text-main-300 opacity-75 w-full lg:w-1/3 text-lg lg:text-base font-spartan">
                {terms.carousel} (
                {carousel.length > 0
                  ? `${carousel.length} ${terms.chosen}`
                  : terms.optional}
                )
              </div>

              <div className="w-full lg:mt-0 mt-1 border-main-300 py-2">
                <div className="w-full flex overflow-x-scroll lg:h-40 h-20 pb-2">
                  {links.map((l) => (
                    <button
                      key={`carousel-${l.linkID}`}
                      className={`border-2 border-transparent rounded-lg mr-2 ${
                        carousel.includes(l.link)
                          ? 'border-main-200'
                          : 'hover:border-main-300 focus:border-main-300'
                      } ease-in-out duration-400 flex-none flex-shrink-0 flex-grow-0`}
                      onClick={() =>
                        setCarousel((prev) =>
                          prev.includes(l.link)
                            ? prev.filter((p) => p !== l.link)
                            : [...prev, l.link]
                        )
                      }
                    >
                      <img
                        src={l.link}
                        alt={l.linkID}
                        className="w-full h-full rounded-lg object-scale-down"
                      />
                    </button>
                  ))}
                </div>
                <div className="w-full flex mt-2">
                  <button
                    className={`p-2 w-1/2 justify-center font-gilroy tracking-wide text-main-300 text-opacity-75 flex items-center ${
                      theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                    } border-2 border-transparent rounded-lg outline-none hover:border-main-300 focus:border-main-300 ease-in-out duration-400`}
                    onClick={() =>
                      handleImage(
                        alert,
                        API_URL,
                        profile,
                        () => null,
                        addLink,
                        terms,
                        true
                      )
                    }
                  >
                    {terms.upload}
                  </button>

                  <button
                    className={`p-2 w-1/2 justify-center font-gilroy tracking-wide text-main-600 text-opacity-75 flex items-center ml-2 ${
                      theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                    } border-2 border-transparent rounded-lg outline-none hover:border-main-600 focus:border-main-600 ease-in-out duration-400`}
                    onClick={() => setCarousel([])}
                  >
                    {terms.remove}
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-2 my-4 w-full flex lg:flex-row flex-col items-center bg-main-300 bg-opacity-75 p-2 rounded-lg">
              <button
                className={`p-4 w-full lg:w-1/2 justify-center font-gilroy tracking-wide text-main-200 text-opacity-75 flex items-center ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                } border-2 border-transparent rounded-lg outline-none ease-in-out duration-400
            ${
              title.trim().length > 0 && content.trim().length > 0
                ? 'hover:border-main-200 focus:border-main-200'
                : 'opacity-50'
            }
            `}
                onClick={() =>
                  title.trim().length > 0 && content.trim().length > 0
                    ? submitPost()
                    : null
                }
              >
                {terms.submit}
              </button>

              <button
                className={`p-4 lg:ml-2 w-full lg:w-1/2 lg:mt-0 mt-2 justify-center font-gilroy tracking-wide text-main-600 text-opacity-75 flex items-center ${
                  theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
                } border-2 border-transparent rounded-lg outline-none hover:border-main-600 focus:border-main-600 ease-in-out duration-400`}
                onClick={() => navigate('/admin')}
              >
                {terms.cancel}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
