import axios from 'axios';

export default async function upload(API_URL, formData, uid, jwt, addLink) {
  return axios.post(`${API_URL}/upload`, formData).then((res) => {
    if (res.data.error === 0) {
      const url = `${API_URL}/public${res.data.path}`;

      return axios
        .post(
          `${API_URL}/link/create`,
          { uid, link: url },
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        )
        .then((resp) => {
          if (resp.data.error === 0) {
            addLink({
              linkID: resp.data.linkID,
              link: url,
            });
            return [true, url];
          } else {
            console.log(resp.data.message);
            return [false, ''];
          }
        });
    } else {
      return [false, res.data.message];
    }
  });
}
