import React, { useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import { LocalContext } from './LocalContext';
import useLanguage from './LanguageWrapper';

import { fetchCurrentProfile, automaticLogin } from '../utils/fetcher';

import logo from '../assets/images/logo.png';

import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useThemeStore } from '../stores/useThemeStore';
import { useLanguageStore } from '../stores/useLanguageStore';

export default function LoginWrapper({
  children,
  loggedIn,
  setLoggedIn,
  executed,
  setExecuted,
  noredirect,
}) {
  const alert = useAlert();
  const navigate = useNavigate();

  const { profile, setUserProfile } = useUserProfileStore((state) => state);
  const { theme, setTheme } = useThemeStore((state) => state);
  const { language, setLanguage } = useLanguageStore((state) => state);

  const { API_URL, autoSSOLogin } = useContext(LocalContext);

  const terms = useLanguage(language).auto_login;

  useEffect(() => {
    autoSSOLogin();
  });

  useEffect(() => {
    async function fetchLocal() {
      if (localStorage.getItem('_userData')) {
        const { uid, jwt, lang, theme } = JSON.parse(
          localStorage.getItem('_userData')
        );
        setTheme(theme ? theme : 'light');
        setLanguage(lang ? lang : 'en');

        if (profile.jwt !== undefined && profile.jwt) {
          setLoggedIn(true);
          return;
        }

        const data = await fetchCurrentProfile(API_URL, uid, jwt);

        if (data.error === 0) {
          setUserProfile({ ...data, jwt: jwt });
          setLoggedIn(true);
          setTheme(data.prefer_dark ? 'dark' : 'light');
          await automaticLogin(API_URL, uid, jwt);
          alert.success(terms.login_success);
        } else {
          console.log(data);
          // localStorage.clear();
          alert.error(terms.login_failed);
          if (!noredirect) {
            navigate('/');
          }
        }
      } else {
        alert.error(terms.login_failed);
        // localStorage.clear();

        if (!noredirect) {
          navigate('/');
        }
      }

      setExecuted(true);
    }

    fetchLocal();
    setTimeout(() => setExecuted(true), 1000);

    // eslint-disable-next-line
  }, []);

  const temporaryContainer = (
    <div className={`h-screen w-screen bg-main-900 overflow-hidden`}>
      <div className="overflow-hidden w-full h-full rounded-lg opacity-95 flex flex-col items-center justify-center">
        <div className="w-full flex justify-center items-center lg:w-1/5 mb-8 lg:mb-20">
          <img
            src={logo}
            alt="main-logo"
            className="object-fill flex items-center justify-center w-2/3 lg:w-11/12 opacity-90"
          />
        </div>

        <div className="text-main-300 w-full text-xl lg:text-2xl font-semibold font-gilroy opacity-80 text-center px-4 blink">
          {terms.login_message}
        </div>
      </div>
    </div>
  );

  return !loggedIn && !executed ? (
    temporaryContainer
  ) : (
    <div
      additional={`${
        theme === 'light' ? 'bg-main-100' : 'bg-main-900'
      } duration-400 ease-in-out`}
    >
      {children}
    </div>
  );
}
