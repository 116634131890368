import React from 'react';

import { FullAbsoluteContainer } from './FullAbsoluteContainer';

export default function IncludeDeleteComment({
  deletingComment,
  setDeletingComment,
  submitDeleteComment,
  theme,
  terms,
}) {
  return (
    <FullAbsoluteContainer
      additional={`w-screen h-screen top-0 left-0 lg:px-2 lg:-mt-4 lg:pt-0 ${
        deletingComment ? 'translate-y-0' : 'translate-y-full'
      }`}
      additionalIn="flex flex-col items-center justify-center"
      outFunction={() => setDeletingComment(null)}
      theme={theme}
    >
      <div className="flex w-full lg:w-1/2 justify-between items-center">
        <div
          className={`text-main-200 w-full text-xl lg:text-2xl font-semibold font-spartan mt-2 mb-2 text-left`}
        >
          {terms.sure_delete}
        </div>

        <button
          onClick={() => setDeletingComment(null)}
          className={`text-center text-lg border-2 border-transparent flex justify-center items-center ${
            theme === 'light' ? 'bg-gray-100' : 'bg-gray-900'
          } text-main-300 hover:border-main-300 focus:border-main-300 hover:opacity-80 focus:opacity-80 font-gilroy ml-3 px-2 my-3 rounded-lg ease-in-out duration-400`}
        >
          <div className={`h-full flex items-center ri-close-line`}></div>
        </button>
      </div>

      <div className="w-full flex justify-center mt-2">
        <button
          className={`uppercase py-2 w-1/2 lg:w-1/4 justify-center font-gilroy tracking-wide text-red-300 text-opacity-75 flex items-center ${
            theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
          } border-2 border-transparent rounded-lg outline-none hover:border-red-300 focus:border-red-300 ease-in-out duration-400`}
          onClick={() => submitDeleteComment(deletingComment)}
        >
          {terms.yes}
        </button>

        <button
          className={`uppercase py-2 ml-4 w-1/2 lg:w-1/4 justify-center font-gilroy tracking-wide text-green-300 text-opacity-75 flex items-center ${
            theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
          } border-2 border-transparent rounded-lg outline-none hover:border-green-300 focus:border-green-300 ease-in-out duration-400`}
          onClick={() => setDeletingComment(null)}
        >
          {terms.no}
        </button>
      </div>
    </FullAbsoluteContainer>
  );
}
