import create from 'zustand';
import { combine } from 'zustand/middleware';

export const useLanguageStore = create(
  combine(
    {
      language: 'en',
    },
    (set) => ({
      setLanguage: (value) => set({ language: value }),
    })
  )
);
