const terms = {
  homepage: {
    latest: 'Latest posts',
    trending: 'Trending',
    favorites: 'Favorites',
    all: 'All posts',
    last_updated: 'Last updated by ',
  },

  login: {
    auto_login: 'Trying to login automatically...',
    name_error: 'Name should contain only alphabets',
    username_error: 'Username should not contain particular symbols and spaces',
    email_error: 'Invalid Email Address entered',
    pass_lowercase:
      'Password should contain at least 1 lowercase alphabetical character',
    pass_uppercase:
      'Password should contain at least 1 upppercase alphabetical character',
    pass_number: 'Password should contain at least 1 numerical character',
    pass_symbol: 'Password should contain at least 1 symbol',
    pass_length: 'Password should be at least 8 characters long',
    pass_check_error: "The passwords don't match",
    pass_check_error_two: "Doesn't match previously entered password",
    privacy_accept: 'Accept the Privacy Policy to continue',
    register_success: 'Registered! Check your mails to activate your account.',
    submitting: 'Submitting...',
    logging: 'Logging in...',
    forgot_success: 'Success! Check your mails to find the reset link.',
    register_h: 'Register',
    name_input: 'Enter Name...',
    username_input: 'Enter Username...',
    email_input: 'Enter Email Address...',
    password_input: 'Enter Password...',
    password_check_input: 'Enter Password Again...',
    policy_read: "I've read and accepted the ",
    privacy: 'Privacy Policy',
    register_button: 'Create!',
    account_already: 'Already have an account? ',
    login_now: 'Login Now!',
    login_h: 'Login',
    multi_auth: 'Enter username or email address...',
    login_pass: 'Enter your password...',
    password_forget: 'Forgot Password?',
    login_button: "Let's Go!",
    new_konnect: 'New to Konnect Blog? ',
    create_account: 'Create an account on Konnect now!',
    forgot_h: 'Forgot Password',
    login_back: 'Back to Login',
    forgot_button: 'Send Reset Link',
    login_demo: 'Try Konnect out as a demo user!',
    login_sso: 'Login via SSO',
  },

  auto_login: {
    login_success: 'Auto Login Successful',
    login_failed: 'Auto Login Failed',
    login_message: 'Trying to automatically log you in...',
  },

  navbar: {
    search: 'Search for something or someone...',
    profile_go: 'Go to Profile',
    create: 'Create',
    cancel: 'Cancel',
    new_post: 'Create New Post',
    new_group: 'Create New Group',
    notifications: 'Notifications',
    settings_go: 'Go to Settings',
    admin_go: 'Go to Admin',
    dark_mode: 'Switch to dark mode',
    light_mode: 'Switch to light mode',
    menu: 'Menu',
    navigation: 'Navigation',
    profile: 'Profile',
    chats: 'Chats',
    groups: 'Groups',
    people: 'People',
    games: 'Games',
    settings: 'Settings',
    post_new: 'New Post',
    group_new: 'New Group',
    misc: 'Misc',
    theme_success: 'Theme updated successfully!',
    new_status: 'Create New Status',
    status_new: 'New Status',
    saved_posts: 'Saved Posts',
    last_updated: 'Last updated by ',
  },

  feed: {
    stories: 'Statuses',
    story: 'View Status',
    online: 'Online',
    content: 'Content',
    chats: 'Chats',
    groups: 'Groups',
    people: 'People',
    games: 'Games',
    pymk: 'People you may know',
    people_add: 'Send a connection request',
    remove_list: 'Remove from this list',
    react: 'React',
    comment: 'Comment',
    save: 'Save',
    share: 'Share',
    enter_comment: 'Enter a comment...',
    reply: 'Reply',
    show_more: 'Show more...',
    no_comments: 'No comments on this post yet...',
    comments_not_allowed: 'Comments are disabled.',
    view_post: 'View Post',
    edit_post: 'Edit Post',
    delete_post: 'Delete Post',
    save_post: 'Save Post',
    unsave_post: 'Unsave Post',
    save_success: 'Post saved successfully',
    unsave_success: 'Post unsaved successfully',
    username: 'Username',
    name: 'Name',
    no_posts: 'No posts in your feed... Try following some more people',
    sure_delete: 'Are you sure that you want to delete this post?',
    yes: 'Yes',
    no: 'No',
    deleting: 'Deleting...',
    deleted_success: 'Post Deleted Successfully!',
    reacts: 'Reacts',
    replying: 'Replying to',
    edit_comment: 'Edit comment',
    update_comment: 'Enter an updated comment...',
    delete_comment: 'Delete comment',
    delete_comment_sure: 'Are you sure that you want to delete this comment?',
    back_original: 'See original post',
    post_share_success: 'Post shared successfully',
    share_post_sure: 'Are you sure that you want to share this post?',
    post_not_found: "This post wasn't found.",
    return_feed: 'Go back to your Feed',
    loading: 'Loading...',
    no_stories: 'No statuses posted recently...',
    success: 'Successful Operation!',
    create_story: 'Create a Status',
    saved_posts: 'Saved Posts',
    collections: 'Collections',
    story_new: 'New Status',
    you: 'You',
    and: 'and',
    others: 'others',
    reacted_to: 'reacted to this',
    this_post: 'post',
    this_comment: 'comment',
    next: 'Next Page',
    previous: 'Previous Page',
    seen_all: 'You have seen all the posts in your feed!',
    choose_collection: 'Choose a collection',
    no_collections: 'No collections created yet...',
    no_collections_saved: 'Not saved in any collections yet...',
    edited: 'Edited',
    uploading: 'Uploading...',
    image_success: 'Image Successfully Uploaded',
  },

  create_post: {
    uploading: 'Uploading...',
    image_success: 'Image Successfully Uploaded',
    content_short: 'Not enough content',
    post_create_success: 'Post created successfully',
    post_edit_success: 'Post edited successfully',
    create_post: 'Create a New Post',
    edit_post: 'Edit Post',
    posting_as: 'Posting as',
    submitting: 'Submitting...',
    editor: 'Editor',
    preview: 'Preview',
    edit: 'Edit',
    create: 'Create',
    enable_comments: 'Enable Comments',
    visibility: 'Visibility',
    choose_visibility: 'Set a Visibility',
    in: 'in',
    title_placeholder: 'Enter a Title...',
    subtitle_placeholder: 'Enter a Subtitle...',
    slug_placeholder: 'Enter a Slug...',
    preview_img: 'Choose a preview image',
    optional: 'optional',
    carousel: 'Select pictures for Carousel',
    upload: 'Upload',
    chosen: 'chosen',
    tags: 'Enter tags (comma-separated)',
    remove: 'Remove',
    content: 'Content',
    submit: 'Submit',
    cancel: 'Cancel',
    loading: 'Loading...',
    post_not_found: 'Blog Post not Found :(',
    return_feed: 'Return to Feed',
  },

  settings: {
    settings: 'Settings',
    preferences: 'Preferences',
    language: 'Language',
    english: 'English',
    french: 'French',
    creole: 'Creole',
    theme: 'Theme',
    dark: 'Dark',
    light: 'Light',
    account: 'Account',
    name: 'Name',
    username: 'Username',
    email: 'Email Address',
    password: 'Password',
    verified: 'Verified',
    unverified: 'Not verified',
    submit: 'Submit',
    cancel: 'Cancel',
    change: 'Change',
    account_type: 'Account Type',
    public: 'Public',
    restricted: 'Restricted',
    private: 'Private',
    ghost: 'Ghost',
    name_error: 'Name should contain only alphabets',
    username_error: 'Username should not contain particular symbols and spaces',
    email_error: 'Invalid Email Address entered',
    pass_lowercase:
      'Password should contain at least 1 lowercase alphabetical character',
    pass_uppercase:
      'Password should contain at least 1 upppercase alphabetical character',
    pass_number: 'Password should contain at least 1 numerical character',
    pass_symbol: 'Password should contain at least 1 symbol',
    pass_length: 'Password should be at least 8 characters long',
    pass_check_error: "The passwords don't match",
    pass_check_error_two: "Doesn't match previously entered password",
    name_input: 'Enter Name...',
    username_input: 'Enter Username...',
    email_input: 'Enter Email Address...',
    password_input: 'Enter Password...',
    password_check_input: 'Enter Password Again...',
    current_password_input: 'Enter Current Password',
    name_success: 'Name updated successfully!',
    username_success: 'Username updated successfully!',
    email_success: 'Email Address updated successfully!',
    password_success: 'Password updated successfully!',
    language_success: 'Language updated successfully!',
    theme_success: 'Theme updated successfully!',
    account_type_success: 'Account type updated successfully!',
    submitting: 'Submitting...',
    reset_password: 'Reset your password',
    password_reset_success: 'Password reset link sent successfully!',
    sure_reset: 'Are you sure that you want to reset your password?',
    yes: 'Yes',
    no: 'No',
    deactivate_success: 'Account successfully deactivated!',
    logout_success: 'Logged out successfully!',
    deactivate: 'Deactivate account',
    logout: 'Log out',
    contributors: 'Contributors',
    access_groups: 'Access Groups',
    access_group_name: 'Enter a name...',
    access_group_members: 'Select Members',
    create: 'Create',
    update: 'Update',
    create_access_success: 'Access Group created successfully',
    update_access_success: 'Access Group updated successfully',
    create_access: 'Create Access Group',
    edit_access: 'Edit Access Group',
    sure_logout: 'Are you sure that you want to log out?',
    unauthorized_action: 'Error: Unauthorized Action',
  },

  stories: {
    viewers: 'Viewers',
    delete_status_sure: 'Are you sure that you want to delete this status?',
    delete_status_success: 'The status was successfully deleted!',
    yes: 'Yes',
    no: 'No',
  },

  profile: {
    story: 'View Status',
    no_posts: 'This user has not posted yet...',
    posts: 'Posts',
    loading: 'Loading...',
    user_not_found: 'User not found...',
    return_feed: 'Go back to your Feed',
    edit_bio: 'Edit your bio',
    enter_bio: 'Enter a bio',
    submit: 'Submit',
    cancel: 'Cancel',
    bio_update_success: 'Bio successfully updated!',
    change_profile_pic: 'Update Profile Picture',
    uploading: 'Uploading...',
    image_success: 'Image Successfully Uploaded',
    ppic_success: 'Profile Picture successfully updated!',
    cover_image_success: 'Cover Image successfully updated!',
    file_too_big: 'File too big (> 10 MB)',
    update_ppic: 'Update profile picture',
    update_avatar: 'Update Avatar',
    update_cover_image: 'Update cover image',
    submitting: 'Submitting...',
    create_story: 'Create a Status',
    story_create_success: 'Status posted successfully!',
    editor: 'Editor',
    create: 'Create',
    preview: 'Preview',
    posting_as: 'Posting as',
    no_followers: 'Nobody follows this user yet',
    no_following: "This user doesn't follow any user yet",
    cannot_view_followers: 'Cannot display the followers of this user',
    cannot_view_following: 'Cannot display the users that this user follows',
    follows_you: 'Follows you',
    follow: 'Follow',
    unfollow: 'Unfollow',
    send_follow_request: 'Send Follow Request',
    unsend_follow_request: 'Unsend Follow Request',
    block: 'Block',
    unblock: 'Unblock',
    success: 'Successful Operation!',
    followers: 'Followers',
    following: 'Following',
    blocked: 'Blocked',
    verified: 'This account has been verified.',
    search_history: 'Search History',
    no_search: 'Nothing searched yet.',
    story_archive: 'Status Archive',
    manage_highlights: 'Manage Highlights',
    no_highlights: 'No highlights yet.',
    create_highlight: 'Create Highlight',
    edit_highlight: 'Edit Highlight',
    create_highlight_success: 'Highlight created successfully',
    edit_highlight_success: 'Highlight updated successfully',
    delete_highlight_success: 'Highlight deleted successfully',
    enter_highlight_name: 'Enter a name...',
    highlight_icon: 'Select an icon (optional)',
    choose_stories: 'Select statuses to add',
    visibility: 'Visibility',
    choose_visibility: 'Set a Visibility',
    avatar_update_success: 'Avatar updated Successfully!',
    last_updated: 'Last updated by ',
  },

  badge: {
    contributor: 'Contributor',
    contributor_desc:
      'Given to everyone who contributes to the development of Konnect.',
    early: 'Early Adopter',
    early_desc: 'Given to the first members of Konnect.',
  },

  notifications: {
    notifications: 'Notifications',
    follow_requests: 'Follow Requests',
    invites: 'Invites',
    received: 'Received',
    sent: 'Sent',
    accept: 'Accept',
    decline: 'Decline',
    cancel: 'Cancel',
    request_accept: 'Follow Request Accepted',
    request_decline: 'Follow Request Declined',
    request_cancel: 'Follow Request Unsent',
    mark_read: 'Mark as read',
    mark_unread: 'Mark as unread',
    chat: 'Chats',
    group: 'Groups',
    invite_accept: 'Invite Accepted',
    invite_reject: 'Invite Rejected',
  },

  search: {
    people: 'People',
    groups: 'Groups',
    rooms: 'Chat Rooms',
    posts: 'Posts',
    placeholder: 'Search for something or someone...',
    searching: 'Searching...',
  },

  people: {
    people: 'People',
    follow: 'Follow',
    unfollow: 'Unfollow',
    send_follow_request: 'Send Follow Request',
    unsend_follow_request: 'Unsend Follow Request',
    success: 'Successful Operation!',
    followers: 'Followers',
    following: 'Following',
  },

  groups: {
    groups: 'Groups',
    create_group: 'Create a Group',
    name: 'Group Name',
    name_placeholder: 'Enter a name...',
    description: 'Group Description',
    description_placeholder: 'Enter a description...',
    privacy: 'Select a Privacy Setting',
    public: 'Public',
    private: 'Private',
    select_members: 'Select Members',
    create: 'Create',
    update: 'Update',
    create_group_success: 'Group created successfully',
    update_group_success: 'Group updated successfully',
    submit: 'Submit',
    submitting: 'Submiting...',
    file_too_big: 'File too big (> 10 MB)',
    uploading: 'Uploading...',
    image_success: 'Image Successfully Uploaded',
    group_icon: 'Select a group icon (optional)',
    group_cover: 'Select a group cover image (optional)',
    loading: 'Loading...',
    group_not_found: 'Group not found...',
    no_groups_found: 'No Groups Found...',
    return_feed: 'Go back to your Feed',
    follow_this_group: 'You are a member of this group.',
    members: 'Members',
    edit_name: 'Edit name',
    edit_description: 'Edit description',
    change_logo: 'Change group icon',
    change_cover: 'Change cover image',
    change_privacy: 'Update Group Privacy',
    invite_people: 'Invite People',
    accept_request: 'Accept Request',
    decline_request: 'Decline Request',
    leave: 'Leave',
    join: 'Join',
    no_posts: 'No posts in this group yet...',
    posts: 'Posts',
    no_members: 'No members in this Group yet.',
    remove: 'Remove from Group',
    ban: 'Ban from Group',
    promote: 'Promote to Admin',
    invite: 'Invite',
    name_update_success: 'Group Name updated Successfully!',
    description_update_success: 'Group Description updated Successfully!',
    icon_update_success: 'Group Icon updated Successfully!',
    cover_update_success: 'Group Cover Image updated Successfully!',
    privacy_update_success: 'Group Privacy updated Successfully!',
    invite_accept: 'Group Invite Accepted',
    invite_reject: 'Group Invite Rejected',
    joined_group: 'Group Joined Successfully!',
    left_group: 'Group Left',
    invite_update_success: 'Individuals Invited Successfully!',
    member_promote: 'Member Successfully promoted to Admin!',
    member_ban: 'Member Successfully Banned!',
    member_remove: 'Member Successfully Kicked!',
    mute: 'Mute Group Notifications',
    unmute: 'Unmute Group Notifications',
    muted_success: 'Group Notifications Muted Successfully!',
    unmuted_success: 'Group Notifications Unmuted Successfully!',
    create_post: 'Create a New Post',
    sure_leave: 'Are you sure that you want to leave this Group?',
    yes: 'Yes',
    no: 'No',
    group_name_placeholder: 'Enter a name...',
    group_description_placeholder: 'Enter a description...',
  },

  saved_posts: {
    saved_posts: 'Collections',
    no_saved_posts: 'No Posts saved yet',
    create_collection: 'Create a collection',
    edit_collection: 'Update collection',
    delete_collection: 'Are you sure that you want to delete this collection?',
    collection_name: 'Name',
    collection_name_placeholder: 'Enter a name...',
    submit: 'Submit',
    default_collection: 'Default',
    submitting: 'Submitting...',
    create_collection_success: 'Collection created successfully!',
    update_collection_success: 'Collection updated successfully!',
    delete_collection_success: 'Collection deleted successfully!',
    no_collections: 'No collections created yet...',
    posts: 'posts',
    yes: 'Yes',
    no: 'No',
    back_collections: 'Back to Collections',
  },

  games: {
    games: 'Games',
    ttt_next: 'Next Player',
    ttt_winner: 'Winner',
    restart: 'Restart',
  },

  rooms: {
    rooms: 'Chat Rooms',
    create_room: 'Create a Chat Room',
    name: 'Room Name',
    name_placeholder: 'Enter a name...',
    description: 'Room Description',
    description_placeholder: 'Enter a description...',
    privacy: 'Select a Privacy Setting',
    public: 'Public',
    private: 'Private',
    select_members: 'Select Members',
    create: 'Create',
    update: 'Update',
    create_room_success: 'Chat Room created successfully',
    update_room_success: 'Chat Room updated successfully',
    submit: 'Submit',
    submitting: 'Submiting...',
    file_too_big: 'File too big (> 10 MB)',
    uploading: 'Uploading...',
    image_success: 'Image Successfully Uploaded',
    room_icon: 'Select an icon (optional)',
    loading: 'Loading...',
    room_not_found: 'Chat Room not found...',
    no_rooms_found: 'No Chat Rooms Found...',
    return_feed: 'Go back to your Feed',
    member_room: 'You are a member of this chat room.',
    members: 'Members',
    edit_name: 'Edit name',
    edit_description: 'Edit description',
    change_logo: 'Change icon',
    change_privacy: 'Update Chat Room Privacy',
    invite_people: 'Invite People',
    accept_request: 'Accept Request',
    decline_request: 'Decline Request',
    leave: 'Leave',
    join: 'Join',
    no_messages: 'No messages in this chat room yet...',
    no_messages_thread: 'No messages in this thread yet...',
    messages: 'Messages',
    no_members: 'No members in this Chat Room yet.',
    remove: 'Remove from Room',
    ban: 'Ban from Room',
    promote: 'Promote to Admin',
    invite: 'Invite',
    name_update_success: 'Room Name updated Successfully!',
    description_update_success: 'Room Description updated Successfully!',
    icon_update_success: 'Room Icon updated Successfully!',
    privacy_update_success: 'Room Privacy updated Successfully!',
    invite_accept: 'Chat Room Invite Accepted',
    invite_reject: 'Chat Room Invite Rejected',
    joined_room: 'Chat Room Joined Successfully!',
    left_room: 'Chat Room Left',
    invite_update_success: 'Individuals Invited Successfully!',
    member_promote: 'Member Successfully promoted to Admin!',
    member_ban: 'Member Successfully Banned!',
    member_remove: 'Member Successfully Kicked!',
    mute: 'Mute Chat Room Notifications',
    unmute: 'Unmute Chat Room Notifications',
    muted_success: 'Chat Room Notifications Muted Successfully!',
    unmuted_success: 'Chat Room Notifications Unmuted Successfully!',
    enter_message: 'Enter a Message...',
    messages_loading: 'Loading Messages...',
    edit_message: 'Edit Message',
    delete_message: 'Delete Message',
    reply_message: 'Reply Message',
    views: 'Views',
    edit_placeholder: 'Enter an updated Message...',
    edited: 'edited',
    sure_delete: 'Are you sure that you want to delete this message?',
    yes: 'Yes',
    no: 'No',
    deleted: 'Deleted',
    replying_to: 'Replying to',
    thread: 'Thread',
    open_thread: 'Open Thread',
    sure_leave: 'Are you sure that you want to leave this Chat Room?',
    room_name_placeholder: 'Enter a name...',
    room_description_placeholder: 'Enter a description...',
    load_more: 'Load More Messages...',
    is_typing: 'is typing...',
    has_new_messages: 'Has new messages',
  },

  avatar: {
    maker_title: 'Avatar Maker',
    generate: 'Generate',
    top: 'Top',
    hat_color: 'Hat Color',
    hair_color: 'Hair Color',
    accessory: 'Accessory',
    accessory_color: 'Accessory Color',
    facial_hair: 'Facial Hair',
    facial_hair_color: 'Facial Hair Color',
    clothes: 'Clothes',
    clothes_color: 'Clothes Color',
    eyes: 'Eyes',
    eyebrow: 'Eyebrow',
    mouth: 'Mouth',
    skin: 'Skin',
    none: 'None',
    random: 'Random',
    save: 'Save',
    reset: 'Reset',
  },

  admin: {
    settings: 'Settings',
    preferences: 'Preferences',
    choose_language: 'Choose a Language',
    language: 'Language',
    english: 'English',
    french: 'French',
    creole: 'Creole',
    language_success: 'Language updated successfully!',
    last_updated: 'Last update, ',
    posts: 'Your posts',
    create_post: 'Create a New Post',
    edit_post: 'Edit Post',
    delete_post: 'Delete Post',
    sure_delete: 'Are you sure that you want to delete this post?',
    yes: 'Yes',
    no: 'No',
    deleting: 'Deleting...',
    deleted_success: 'Post Successfully Deleted!',
    publish: 'Publish Post',
    unpublish: 'Unpublish Post',
    publish_success: 'Post Successfully Published!',
    unpublish_success: 'Post Successfully Unpublished!',
  },

  view: {
    enter_comment: 'Enter a comment...',
    edit_comment: 'Edit comment',
    reply_comment: 'Reply to comment',
    update_comment: 'Enter an updated comment...',
    delete_comment: 'Delete comment',
    sure_delete: 'Are you sure that you want to delete this comment?',
    submit: 'Submit',
    posted_on: 'Posted on',
    last_modified: 'Last modified on',
    edited: '(edited)',
    yes: 'Yes',
    no: 'No',
    submitting: 'Submitting...',
    deleting: 'Deleting...',
    comment_add_success: 'Comment Successfully Added!',
    comment_update_success: 'Comment Successfully Updated!',
    comment_delete_success: 'Comment Successfully Deleted!',
    loading: 'Loading...',
    post_not_found: 'Blog Post not Found :(',
    return_feed: 'Return to Feed',
    comments: 'Comments',
    show_comments: 'Show Comments',
    hide_comments: 'Hide Comments',
    no_comments: 'No Comments yet...',
    views: 'Views',
    likes: 'Likes',
    like: 'Like',
    unlike: 'Unlike',
    remove_from: 'Remove From',
    add_to: 'Add To',
    favorites: 'Favorites',
    reply: 'Reply',
    edit: 'Edit',
    delete: 'Delete',
    share: 'Share',
    link_copied: 'Link Copied!',
  },
};

export default terms;
