import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Navbar from '../components/Navbar';

import { usePostStore } from '../stores/usePostStore';
import { useUserProfileStore } from '../stores/useUserProfileStore';
import { useProfileStore } from '../stores/useProfileStore';
import { useLanguageStore } from '../stores/useLanguageStore';
import { useThemeStore } from '../stores/useThemeStore';

import useLanguage from '../wrappers/LanguageWrapper';
import { convert } from '../utils/timestamp';
import { automaticLogin } from '../utils/fetcher';

import { LocalContext } from '../wrappers/LocalContext';

export default function Homepage() {
  const { posts } = usePostStore((state) => state);
  const { profile } = useUserProfileStore((state) => state);
  const { profiles } = useProfileStore((state) => state);
  const { theme } = useThemeStore((state) => state);
  const { language } = useLanguageStore((state) => state);

  const { API_URL } = useContext(LocalContext);

  useEffect(() => {
    const fetchData = async () => {
      if (!profile || !profile.profileID) {
        await automaticLogin(API_URL, '', '');
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  let blogProfile = profile ? (profile.uid ? profile : null) : null;

  const terms = useLanguage(language).homepage;
  const navigate = useNavigate();

  const sortDate = (posts) => {
    let updatedPosts = [...posts];
    return updatedPosts.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));
  };

  const fromFollowers = (posts) => {
    let updatedPosts = [...posts];
    return updatedPosts.sort((a, b) =>
      blogProfile.following.includes(a.uid) ? (blogProfile.following.includes(b.uid) ? 0 : -1) : 1
    );
  };

  const tmpCard = (
    <div
      className={`flex-shrink-0 flex-grow-0 sm:w-100 w-80 border-4 ${
        theme === 'light' ? 'border-gray-400 bg-gray-100' : 'border-gray-600 bg-gray-900'
      } rounded-lg sm:h-40 h-32 p-2 flex flex-col justify-end mr-2 ease-in-out duration-400`}
    >
      <div
        className={`w-5/6 py-2 ${
          theme === 'light' ? 'bg-gray-200' : 'bg-gray-800'
        } my-2 rounded-lg animate-pulse ease-in-out duration-400`}
      ></div>

      <div
        className={`w-3/5 py-1 ${
          theme === 'light' ? 'bg-gray-300' : 'bg-gray-700'
        } my-2 rounded-lg animate-pulse ease-in-out duration-400`}
      ></div>

      <div
        className={`w-full py-1 ${
          theme === 'light' ? 'bg-gray-400' : 'bg-gray-600'
        } mt-1 rounded-lg animate-pulse ease-in-out duration-400`}
      ></div>
    </div>
  );

  const placeholderCards = (
    <div className="w-full flex flex-col items-start sm:px-4 mb-4">
      <div
        className={`sm:w-2/5 w-5/6 my-2 rounded-lg py-3 ${
          theme === 'light' ? 'bg-gray-300' : 'bg-gray-700'
        } animate-pulse ease-in-out duration-400`}
      ></div>
      <div className="w-full flex overflow-x-scroll py-2">
        {tmpCard}
        {tmpCard}
        {tmpCard}
        {tmpCard}
        {tmpCard}
      </div>
    </div>
  );

  const returnSpecificBlogPosts = (list, title) => {
    if (list.length <= 0) {
      return <div />;
    } else
      return (
        <div key={`${title}`} className="w-full flex flex-col items-center mb-4">
          <div className="w-full text-xl lg:text-3xl text-main-300 tracking-wide text-left font-semibold font-gilroy">
            {title} {`(${list ? list.length : 0})`}
          </div>
          <div className="w-full flex overflow-x-scroll py-2">
            {list.map((post, i) => {
              let author = profiles.find((p) => p.uid === post.uid);
              if (!author) {
                author = { username: 'Unknown', uid: 'unknown' };
              }

              return (
                <div
                  className={`transition-all bg-size-200 bg-pos-0 hover:bg-pos-100 flex-shrink-0 flex-grow-0 sm:w-100 w-80 ease-in-out duration-400
              hover:border-main-300 bg-gradient-to-r from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500 focus:from-green-400 focus:to-blue-50 ${
                i < list.length - 1 ? 'mr-2' : 'mr-0'
              } border-4 ${
                    theme === 'light' ? 'border-gray-800' : 'border-gray-200'
                  } rounded-lg sm:h-40 h-32 p-2 flex flex-col justify-end`}
                  key={post.blogID}
                  style={
                    post.preview_img && post.preview_img.length > 8
                      ? {
                          color: '#4a5568',
                          background: `url(${post.preview_img}) center / cover no-repeat #4a5568`,
                        }
                      : {}
                  }
                >
                  <button className="w-full outline-none" onClick={() => navigate(`/v/${post.blogID}`)}>
                    <div
                      className={`text-base lg:text-lg tracking-wide text-left text-main-200 font-spartan font-semibold bg-gray-900 bg-opacity-75 rounded py-1 px-2`}
                    >
                      {post.title}
                    </div>

                    <div className="text-sm lg:text-base tracking-wide font-normal mt-1 text-main-300 bg-opacity-75 bg-gray-900 rounded px-2 text-left">
                      {post.subtitle}
                    </div>
                  </button>

                  <div className="text-xxs lg:text-xs tracking-wide font-normal mt-1 text-gray-200 bg-gray-900 opacity-75 rounded px-2 w-full text-left font-sans">
                    {terms.last_updated}
                    <button
                      className="text-yellow-300 hover:underline focus:underline"
                      onClick={() => navigate(`/u/${author.uid}`)}
                    >
                      {author.username.length > 20 ? author.username.substring(0, 20) : author.username}
                    </button>
                    {', '}
                    <span className="text-green-300">{convert(post.updated_on, language, false)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
  };

  return (
    <div
      className={`w-full ${theme === 'light' ? 'bg-main-100' : 'bg-main-900'} ease-in-out duration-400 lg:pb-0 pb-20`}
    >
      <Navbar />
      <div className=" p-2 pt-20 lg:pt-24">
        {!posts || posts.length <= 0 ? (
          <>
            {placeholderCards} {placeholderCards} {placeholderCards}
            {placeholderCards} {placeholderCards}
          </>
        ) : (
          <>
            {blogProfile && returnSpecificBlogPosts(fromFollowers(sortDate(posts)).slice(0, 10), terms.latest)}

            {returnSpecificBlogPosts(
              sortDate(posts)
                .sort((a, b) => b.likes.length + b.comments.length - a.likes.length + a.comments.length)
                .slice(0, 5),
              terms.trending
            )}

            {blogProfile &&
              returnSpecificBlogPosts(
                sortDate(posts.filter((p) => blogProfile.favorites.includes(p.blogID))),
                terms.favorites
              )}

            {returnSpecificBlogPosts(sortDate(posts), terms.all)}
          </>
        )}
      </div>
    </div>
  );
}
