import create from 'zustand';
import { combine } from 'zustand/middleware';

import { generate } from '../utils/timestamp';

export const usePostStore = create(
  combine(
    {
      posts: [],
    },
    (set) => ({
      setPosts: (value) =>
        set({
          posts:
            !value || value.length <= 0
              ? []
              : value.map((v) => {
                  let post = v;
                  return post;
                }),
        }),

      addPost: (value) =>
        set((state) => {
          let currentPosts = [...state.posts];
          let shouldAddPost = true;

          currentPosts = currentPosts.map((cp) => {
            let updatedPost = { ...cp };
            if (updatedPost.blogID === value.blogID) {
              shouldAddPost = false;
              updatedPost = value;
              updatedPost.tags = updatedPost.tags
                .split(' ')
                .join('')
                .split(',');
            }
            return updatedPost;
          });

          let newPost = value;

          return {
            posts: shouldAddPost
              ? [newPost, ...state.posts]
              : [...currentPosts],
          };
        }),

      editPost: (
        blogID,
        title,
        subtitle,
        slug,
        preview_img,
        tags,
        content,
        carousel
      ) =>
        set((state) => {
          const currentPosts = [...state.posts];
          const updatedPosts = currentPosts.map((cp) => {
            let post = { ...cp };
            if (post.blogID === blogID) {
              post.content = content;
              post.title = title;
              post.subtitle = subtitle;
              post.slug = slug;
              post.preview_img = preview_img;
              post.tags = tags;
              post.carousel = carousel;
              post.modified_on = generate();
              post.updated_on = generate();
            }
            return post;
          });
          return { posts: updatedPosts };
        }),

      deletePost: (blogID) =>
        set((state) => ({
          posts: state.posts.filter((p) => p.blogID !== blogID),
        })),

      publishPost: (blogID, publish) =>
        set((state) => ({
          posts: state.posts.map((p) => {
            let updatedPost = { ...p };

            if (updatedPost.blogID === blogID) {
              updatedPost.published = publish;
            }

            return updatedPost;
          }),
        })),

      reactPost: (blogID, like, uid) =>
        set((state) => {
          const currentPosts = [...state.posts];
          const updatedPosts = currentPosts.map((cp) => {
            let post = { ...cp };
            if (post.blogID === blogID) {
              if (like) {
                post.likes = [...post.likes, uid];
              } else {
                post.likes = post.likes.filter((r) => r !== uid);
              }
            }
            return post;
          });
          return { posts: updatedPosts };
        }),

      addComment: (uid, blogID, comment, reply_to, commentID) =>
        set((state) => {
          const genDate = generate();

          const newComment = {
            uid,
            commentID,
            comment,
            posted_on: genDate,
            modified_on: genDate,
            reply_to: reply_to,
            reacts: [],
          };

          const currentPosts = [...state.posts];
          const updatedPosts = currentPosts.map((cp) => {
            let post = { ...cp };
            if (post.blogID === blogID) {
              post.comments = [
                ...post.comments.filter((c) => c.commentID !== commentID),
                newComment,
              ];
            }
            return post;
          });

          return { posts: updatedPosts };
        }),

      editComment: (blogID, commentID, comment) =>
        set((state) => {
          const currentPosts = [...state.posts];
          const updatedPosts = currentPosts.map((cp) => {
            let post = { ...cp };
            if (post.blogID === blogID) {
              post.comments = post.comments.map((c) => {
                let updatedComment = { ...c };
                if (c.commentID === commentID) {
                  updatedComment.comment = comment;
                  updatedComment.modified_on = generate();
                }
                return updatedComment;
              });
            }
            return post;
          });

          return { posts: updatedPosts };
        }),

      reactComment: (blogID, commentID, react, uid) =>
        set((state) => {
          const currentPosts = [...state.posts];
          const updatedPosts = currentPosts.map((cp) => {
            let post = { ...cp };
            if (post.blogID === blogID) {
              post.comments = post.comments.map((c) => {
                let updatedComment = { ...c };
                if (c.commentID === commentID) {
                  if (react) {
                    updatedComment.reacts = [...updatedComment.reacts, uid];
                  } else {
                    updatedComment.reacts = updatedComment.reacts.filter(
                      (r) => r !== uid
                    );
                  }
                }
                return updatedComment;
              });
            }
            return post;
          });
          return { posts: updatedPosts };
        }),

      deleteComment: (blogID, commentID) =>
        set((state) => {
          const currentPosts = [...state.posts];
          const updatedPosts = currentPosts.map((cp) => {
            let post = { ...cp };
            if (post.blogID === blogID) {
              post.comments = post.comments.filter(
                (c) => c.commentID !== commentID && c.reply_to !== commentID
              );
            }
            return post;
          });
          return { posts: updatedPosts };
        }),
    })
  )
);
